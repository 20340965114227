import DataGrid, {
  Column,
  ColumnChooser,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
  SortByGroupSummaryInfo,
  StateStoring,
  Summary
} from "devextreme-react/data-grid";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { GridType } from "../../../../../types/general/enums/generalEnums";
import { NameValue } from "../../../../../types/general/generalTypes";
import { MMSResStatus } from "../../../../../types/infrastructure/enums/infrastructureEnums";
import { MmsRes } from "../../../../../types/infrastructure/infrastructureTypes";
import { SortObjectByPropName } from "../../../../../utils/arrayTools";
import { OnExporting } from "../../../../../utils/dataGridTools";
import { Enum2Array } from "../../../../../utils/enumTools";

interface IProps {
  resGridData: MmsRes[]
}

const Result = (props: IProps) => {
  const [lstStatus, setLstStatus] = useState<NameValue[]>([])
  const dataGridRef = useRef<DataGrid<any, any>>(null);

  useEffect(() => {
    setLstStatus(Enum2Array(MMSResStatus));
  }, [])

  const headerFilterData = [
    {
        text: t("enum-Calculated"),
        value: ['Status', '=', 1]
    },
    {
        text: t("enum-AADTNotAvailable"),
        value: ['Status', '=', 2]
    },
    {
        text: t("enum-PostedSpeedNotAvailable"),
        value: ['Status', '=', 4]
    },
    {
        text: t("enum-AADTNotAvailable") + ", " + t("enum-PostedSpeedNotAvailable"),
        value: ['Status', '=', 6]
    }
  ];

const headerLookup = [
    {
        name: t("enum-Calculated"),
        value: 1
    },
    {
        name: t("enum-AADTNotAvailable"),
        value: 2
    },
    {
        name: t("enum-PostedSpeedNotAvailable"),
        value: 4
    }
    , {
        name: t("enum-AADTNotAvailable") + ", " + t("enum-PostedSpeedNotAvailable"),
        value: 6
    }
  ];

  return (
    <DataGrid
      id="gridContainer"
      width={"100%"}
      ref={dataGridRef}
      dataSource={props.resGridData}
      rowAlternationEnabled={true}
      showBorders={true}
      hoverStateEnabled={true}
      remoteOperations={true}
      allowColumnReordering={true}
      allowColumnResizing={true}
      columnAutoWidth={true}
      style={{ margin: "1rem 1rem 0 1rem" }}
      onExporting={OnExporting}
    >
      <Export enabled={true} allowExportSelectedData={true} />
      <Grouping contextMenuEnabled={true} autoExpandAll={false} />
      <GroupPanel visible={true} /> {/* or "auto" */}
      <FilterPanel visible={true} />
      <FilterBuilderPopup position={"top"} />
      <StateStoring
        enabled={true}
        type="localStorage"
        storageKey={GridType.MMSDetails.toString()}
        savingTimeout={500}
      />
      <SortByGroupSummaryInfo
        summaryItem="Total Count"
        sortOrder="desc"
      />
      <Summary>
        <GroupItem
          summaryType="count"
          alignByColumn
          name="Total Count"
        />
      </Summary>
      <Paging enabled={true} defaultPageSize={100} />
      <Pager
        showPageSizeSelector={true}
        allowedPageSizes={[100, 200, 300, 400, 500]}
        showNavigationButtons={true}
        showInfo={true}
        visible={true}
      />
      <FilterRow visible={true} applyFilter="auto" />
      <HeaderFilter visible={true} />
      <SearchPanel visible={true} width={285} placeholder={t("search...")} />
      <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
        <Search enabled />
      </ColumnChooser>
      <Column dataField="locationDescription" caption={t("locationDescription")} width={600}>
        <HeaderFilter>
          <Search enabled />
        </HeaderFilter>
      </Column>
      <Column dataField="geoId" caption={t("geoId")}>
        <HeaderFilter>
          <Search enabled />
        </HeaderFilter>
      </Column>
      <Column dataField="postedSpeed" caption={t("postedSpeed")}>
        <HeaderFilter>
          <Search enabled />
        </HeaderFilter>
      </Column>
      <Column dataField="aadt" caption={t("aadt")}>
        <HeaderFilter>
          <Search enabled />
        </HeaderFilter>
      </Column>
      <Column dataField="mms" caption={t("mmsClassification")}>
        <HeaderFilter>
          <Search enabled />
        </HeaderFilter>
      </Column>
      <Column dataField="status"
          caption={t('status')}
      >
          <HeaderFilter dataSource={headerFilterData} width={350} >
              <Search enabled />
          </HeaderFilter>
          <Lookup dataSource={headerLookup} valueExpr="value" displayExpr="name" />
      </Column>
      <Column dataField="rin" caption={t("RIN")}>
        <HeaderFilter>
          <Search enabled />
        </HeaderFilter>
      </Column>
      <Column dataField="id" caption={t("id")} visible={false}>
        <HeaderFilter>
          <Search enabled />
        </HeaderFilter>
      </Column>
      <Column dataField="mmsRequestId" caption={t("mmsRequestId")} visible={false}>
        <HeaderFilter>
          <Search enabled />
        </HeaderFilter>
      </Column>
      <Column dataField="customerId" caption={t("customerId")} visible={false}>
        <HeaderFilter>
          <Search enabled />
        </HeaderFilter>
      </Column>
      <Column dataField="divisionId" caption={t("divisionId")} visible={false}>
        <HeaderFilter>
          <Search enabled />
        </HeaderFilter>
      </Column>
      <Column dataField="locationId" caption={t("locationId")} visible={false}>
        <HeaderFilter>
          <Search enabled />
        </HeaderFilter>
      </Column>
    </DataGrid>
  );
};

export default Result;