import { DayOfWeek, MessageType, Month } from "../general/enums/generalEnums";
import { Dictionary } from "../general/generalTypes";
import { VMLocation } from "../infrastructure/dto/locationdDto";
import { ArmadilloVehicleType, LocationType, SectionType } from "../infrastructure/enums/infrastructureEnums";
import { SpeedStatisticsResult, StudyBaseDTO } from "./dtos/studyDto";
import { AADTQualityFactor, TrafficCounterType, BinMapClassificationType, AADTPredictionStatus } from "./enums/importEnums";
import { AADTPredictionProjectType, AADTSourceType, AADTType, ApproachLaneNo, ApproachType, BinMapType, DayDefinitionType, FlowConditionType, MovementType, ReportingPeriodType, SignalWarrantAlgorithmType, StudyCategory, StudyType, StudyValidationLogType, VehicleClassificationType } from "./enums/trafficStudyEnums";

export interface IStudyParams {
  [key: string]: any;
  studyId: string;
  studyType: string

}

export class VehicleClassification {
  id!: string;
  in!: number;
  // customerId!: string;
  // divisionId!: string;
  trafficCounterId!: string;
  name!: string;
  order!: number;
  vehicleClassificationTypes!: VehicleClassificationType;
  armadilloVehicleType!: ArmadilloVehicleType;
  fromLength!: number;
  toLength!: number;
}

export class ReportingPeriod {
  id!: string;
  customerId!: string;
  startTime!: Date;
  endTime!: Date;
  label!: string;
  type!: ReportingPeriodType;
  studyType!: StudyType;
}

export class TrafficCounter {
  constructor() {
    this.binMaps = []
    this.selectedStudyTypes = []
    this.vehicleClassifications = []
  }
  id!: string;
  customerId!: string;
  divisionId!: string;
  name!: string;
  maxBinNo!: number;
  studyTypes!: StudyType;
  binMaps!: BinMapBase[];
  selectedStudyTypes!: number[];
  trafficCounterType!: TrafficCounterType;
  vehicleClassifications!: VehicleClassification[];
  isDisabled!: boolean

}

export interface ITrafficCounterParams {
  [key: string]: any;
  trafficCounterId: string
}

export interface IAADTPatternParams {
  [key: string]: any;
  AADTPatternId: string
}

export interface IAADTPredictionParams {
  [key: string]: any;
  AADTPredictionId: string
  ProjectType: AADTPredictionProjectType
}

export interface IAADTDetailsParams {
  [key: string]: any;
  aadtId: string
  aadtType: string
}

export class BinMapBase {
  // constructor() {
  //   this.dateFormat="mm/dd/yy"
  // }

  id!: string;
  name!: string;
  noBinInUse!: number;
  type!: BinMapType;
  studyType!: StudyType;
  bins!: any[];
  dateFormat!: string;
  incomingMovement!: MovementType;
  outgoingMovement!: MovementType;
  isDisabled!: boolean
  binMapClassificationType!: BinMapClassificationType
}

class BinMapInterim<T> extends BinMapBase {
  bins!: T[]
}

export class TmcBinMap extends BinMapInterim<TmcBin> { }

export class TmcBin {
  id!: string;
  bin!: number;
  vehicleClassificationIn!: number;
  movement!: MovementType;
}

export class SignalWarrantsSetting {
  id!: string;
  customerId!: string;
  divisionId!: string;
  name!: string;
  freeFlow1All!: number;
  restrFlow1All!: number;
  freeFlow2All!: number;
  restrFlow2All!: number;
  freeFlow1Minor!: number;
  restrFlow1Minor!: number;
  freeFlow2Minor!: number;
  restrFlow2Minor!: number;
  freeFlow1CrossingMajorStreet!: number;
  restrFlow1CrossingMajorStreet!: number;
  freeFlow2CrossingMajorStreet!: number;
  restrFlow2CrossingMajorStreet!: number;
  collisionNoWarrant5!: number;
}

export class TrafficStudyGeneralSetting {
  id!: string;
  customerId!: string;
  divisionId!: string;
  tmcDayDefinition!: DayDefinitionType;
  signalWarrantAlgorithmType!: SignalWarrantAlgorithmType
  speedDayDefinition!: DayDefinitionType;
  speedPercentiles!: number[];
  volumeChangeThresholdError!: number;
}

export class SignalWarrantConfig {
  studyBase?: StudyBaseDTO;
  sectionType!: SectionType;
  approachLaneOnMajor!: ApproachLaneNo;
  approachLaneOnMinor!: ApproachLaneNo;
  flowCondition!: FlowConditionType;
  channelizesRightTurnsApproaches!: ApproachType;
  hoursNeededForJustification!: number;
  years!: number[];
  pedestrianDelayVolume!: number;
}

export class AllWayStopWarrantsSetting {
  id!: string;
  customerId!: string;
  divisionId!: string;
  name!: string;
  urbanArterialTotalVolumeAllLegs!: number;
  urbanArterialTotalVehPedVolumeCrossingMajor!: number;
  urbanArterialTotalDelayCrossingMajor!: number;
  urbanArterialSplitOnMajor4Leg!: number;
  urbanArterialSplitOnMajor3Leg!: number;
  collectorRuralArterialTotalVolumeAllLegs!: number;
  collectorRuralArterialTotalVehPedVolumeCrossingMajor!: number;
  collectorRuralArterialTotalDelayCrossingMajor!: number;
  collectorRuralArterialSplitOnMajor4Leg!: number;
  collectorRuralArterialSplitOnMajor3Leg!: number;
  localTotalVolumeAllLegs!: number;
  localTotalVehPedVolumeCrossingMajor!: number;
  localSplitOnMajor4Leg!: number;
  localSplitOnMajor3Leg!: number;
  localCollectorRuralArterialCollisionNo!: number;
  urbanArterialCollisionNo!: number;
}

export class AADTPattern {
  constructor() {
    this.hourFactors = []
    this.dayFactors = []
    this.monthFactors = []
    this.locations = []
  }

  id!: string;
  customerId!: string;
  divisionId!: string;
  name!: string;
  isDefault!: boolean;
  hourFactors!: AADTHourOfDay[]
  dayFactors!: AADTHourOfWeek[]
  monthFactors!: AADTHourOfYear[]
  locations!: VMLocation[]
}

export class AADTHourOfDay {
  id!: string;
  isDefault!: boolean;
  year!: number;
  hour!: number;
  hourFactor!: number;
}

export class AADTHourOfWeek {
  id!: string;
  isDefault!: boolean;
  year!: number;
  dayOfWeek!: DayOfWeek;
  dayFactor!: number;
}

export class AADTHourOfYear {
  id!: string;
  isDefault!: boolean;
  year!: number;
  monthOfYear!: Month;
  monthFactor!: number;
}
export class AADTBase {
  id!: string;
  customerId!: string;
  divisionId!: string;
  aadtType!: AADTType;
  sourceType!: AADTSourceType;
  locationId!: string;
  locationDescription!: string;
  geoId!: string;
  sectionType!: SectionType;
  aadtRawDataDT!: Date;
  year!: number;
  totalVolume!: number;
}
export class IntersectionAADT extends AADTBase {
  majorVolume!: number;
  minorVolume!: number;
  northApproachAADT!: number;
  southApproachAADT!: number;
  eastApproachAADT!: number;
  westApproachAADT!: number;
}
export class RoadSegmentAADT extends AADTBase {
  segmentVolume!: number;

}

export class StudyResultBase<T> {
  periodName?: string;
  TWarningFix?: T
}

export class VolumeResult extends StudyResultBase<any> { }
export class TmcResult extends StudyResultBase<any> {
  phf?: number;
}
export class SpeedResult extends StudyResultBase<any> { }

export class StudyValidationLog {
  id!: string;
  description!: string;
  note!: string;
  logDT!: Date;
  logLevel!: MessageType;
  logType!: StudyValidationLogType

}

export class CountDuration {
  customerId!: string;
  id!: string;
  divisionId!: string;
  studyType!: StudyType;
  durations!: number[]
}


export class VolumeSummary {
  northApproachVolume!: number;
  eastApproachVolume!: number;
  westApproachVolume!: number;
  southApproachVolume!: number;
  totalVolume!: number;
}

export class SimplifiedStudy {
  studyId!: string;
  studyType!: StudyType;
  studyCategory!: StudyCategory
  geoId!: string;
  locationId!: string;
  locationType!: LocationType;
  speedLimit!: number;
  averageSpeed!: number;
  percentile85!: number;
  speedStatisticsSummary!: SpeedStatisticsResult;
  startDT!: Date;
  endDT!: Date;
  volumeSummary!: VolumeSummary;
  roadSegmentAADT!: RoadSegmentAADT;
  intersectionAADT!: IntersectionAADT;
}

export class SpeedAndCompliance {
  startDT!: Date | string;
  average!: number;
  speed85thPercentile!: number;
  postedSpeed!: number;
  northApproachVolume!: number;
  eastApproachVolume!: number;
  westApproachVolume!: number;
  southApproachVolume!: number;
  totalVolume!: number;
  compliance!: number;
}

export class AADTPredictionSettings {
  constructor() {
    this.fromYear = new Date().getFullYear() - 15
    this.toYear = new Date().getFullYear()
    this.sourceTypes = [AADTSourceType.TesHistorical, AADTSourceType.UserModifiedPermanent, AADTSourceType.UserModified, AADTSourceType.Count]
    this.excludeYears = []
    this.maxVariationAADTInPercent = 30;
    this.growthFactor = 2;
  }
  customerId!: string;
  predictionYears!: number[];
  growthFactor!: number;
  maxVariationAADTInPercent!: number;
  growthLimits!: number;
  excludeYears!: number[];
  fromYear!: number
  toYear!: number
  sourceTypes!: AADTSourceType[]

}

export class AADTPredictionProject {
  constructor() {
    this.predictionSettings = new AADTPredictionSettings();
  }
  id!: string;
  customerId!: string;
  title!: string;
  description!: string;
  projectType!: AADTPredictionProjectType
  predictionSettings!: AADTPredictionSettings;
}

export class GetProjectDetailRequest {
  projectId!: string;
  showDataForLastNYear: number = 3
}

export class AADTPredictionYearResult {
  year!: number;
  
  predictionValue!: number;
  finalValue!: number;
  currentValue!: number;

  predictionMajorValue!: number;
  currentMajorValue!: number;
  finalMajorValue!: number;
  
  predictionMinorValue!: number;
  currentMinorValue!: number;
  finalMinorValue!: number;

  currentNorthValue!: number;
  predictionNorthValue!: number;
  currentEastValue!: number;
  predictionEastValue!: number;
  currentSouthValue!: number;
  predictionSouthValue!: number;
  currentWestValue!: number;
  predictionWestValue!: number;
  currentSourceType!: AADTSourceType;
  predictedSourceType!: AADTSourceType;
  qualityFactor!: AADTQualityFactor;
  message!: string;
  status!: AADTPredictionStatus;
  isChanged!: boolean
}

export class AADTPredictionProjectDetail {
  constructor() {
    this.project = new AADTPredictionProject();
  }
  project!: AADTPredictionProject
  results!: AADTPredictionProjectDetailResult[]
}

export class AADTPredictionProjectDetailResult {
  locationId!: string;
  locationDescription!: string;
  geoId!: string;
  aadTs!: Dictionary<number, AADTPredictionYearResult>;
}

export class AADTPredictionUpdateReq {
  locationId!: string;
  customerId!: string;
  Year!: number;
  sourceType!: AADTSourceType;
  qualityFactor!: AADTQualityFactor;
  finalValue!: number;
  finalMajorValue!: number;
  finalMinorValue!: number;
  projectType!: AADTPredictionProjectType;
}

export class AADTPredictionGeneralSettingType {
  id!: string;
  customerId!: string;
  warningLevels: AADTPredictionWarningLevel[] = [];
}

export class AADTPredictionWarningLevel {
  id!: string;
  fromValue!: number;
  toValue!: number;
  growth!: number;
}

export class SetRawDataDate {
  studyId!: string;
  rawDataDate!: Date;
}