/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState, useCallback } from "react";
import { loadModules } from "esri-loader";
import { TesMapModule } from "../../../../../types/general/enums/generalEnums";
// import { LocationType } from "../../types/infrastructure/enums/infrastructureEnums";
import "./gisMap.scss";
import { LocationType } from "../../../../../types/infrastructure/enums/infrastructureEnums";
import { FieldType } from "../../../../../types/field/enums/fieldEnums";
import { useTranslation } from 'react-i18next';
import LoadPanel from "devextreme-react/load-panel";
import { StudyType } from "../../../../../types/trafficStudy/enums/trafficStudyEnums";
import { render } from "@testing-library/react";

// hooks allow us to create a map component as a function
export function GisMap({
    initDataGis,
    tesModule,
    collisionToMapData,
    collisionToMapReq,
    trafficToMapData,
    trafficToMapReq,
    go2Intersection,
    go2RoadSegment,
    getLocationId,
    hasFullHeight,
    dataButtonTrigger,
    setIsRightSideOpen,
    locationToZoom,
    mapTest
}) {
    // create a ref to element to be used as the map's container
    const mapEl = useRef(null);
    const { t } = useTranslation();

    const _view = useRef(null);
    const _map = useRef(null);
    const _legend = useRef(null);

    const _selectionIsEnable = (tesModule === TesMapModule.Collision || tesModule === TesMapModule.Infrastructure || tesModule === TesMapModule.AreaDetails
        || tesModule === TesMapModule.Sign || tesModule === TesMapModule.Support);

    const _moveMapIsEnable = (tesModule === TesMapModule.CollisionDetails || tesModule === TesMapModule.InfrastructureDetails);
    const _intersectionLayer = useRef(null);
    const _roadSegmentLayer = useRef(null);
    const _sketchViewModel = useRef(null);
    const _polygonGraphicsLayer = useRef(null);
    const [showLoading, setShowLoading] = useState(false);

    const [viewIsLoaded, setViewIsLoaded] = useState(false);
    // const [extentIsFull, setExtentIsFull] = useState(false);


    const [callForRemoveAllHighLight, setCallForRemoveAllHighLight] = useState(0);
    const [selectedArea, setSelectedArea] = useState({});
    const [selectedData, setSelectedData] = useState([]);


    const [lstLocalSelectedRoadSegment, setLstLocalSelectedRoadSegment] = useState([]);
    const [lstLocalSelectedIntersection, setLstLocalSelectedIntersection] = useState([]);

    var selectedHighlighted = null;
    var roadSegmentUId = "";
    var intersectionUId = "";

    // use a side effect to create the map after react has rendered the DOM
    useEffect(
        () => {
            // define the view here so it can be referenced in the clean up function
            // first lazy-load the esri classes
            loadModules(
                [
                    "esri/views/MapView",
                    "esri/layers/FeatureLayer",
                    "esri/Map",
                    "esri/widgets/Sketch/SketchViewModel",
                    "esri/layers/GraphicsLayer",
                    "esri/geometry/support/webMercatorUtils",
                    "esri/core/reactiveUtils",
                    "esri/widgets/LayerList",
                    "esri/widgets/Expand",
                    "esri/widgets/Print",
                    'esri/PopupTemplate'
                ],
                {
                    css: true,
                }
            ).then(
                ([
                    MapView,
                    FeatureLayer,
                    Map,
                    SketchViewModel,
                    GraphicsLayer,
                    webMercatorUtils,
                    reactiveUtils,
                    LayerList,
                    Expand,
                    Print,
                    PopupTemplate
                ]) => {
                    if (initDataGis === null || _view.current !== null) return;

                    //Load the intersection layer
                    var intersectionsLayer = new FeatureLayer({
                        title: "Intersection",
                        outFields: [initDataGis.intersectionGeoIdName],
                        apiKey: initDataGis.arcGisApiKey,
                        url: initDataGis.intersectionFileAddress,
                        copyright: "TNS",
                        minScale: 0, // Always visible
                        maxScale: 0, // Always visible,
                        popupTemplate: {
                            actions: getIntersectionPopupAction(),
                            content: [
                                {
                                    type: "fields",
                                    fieldInfos: [
                                        {
                                            fieldName: initDataGis.intersectionGeoIdName,
                                            label: "Geo ID",
                                        },
                                    ],
                                },
                            ],
                        },
                        renderer: {
                            type: "simple",
                            symbol: {
                                type: "simple-marker",
                                color: "#FFFF00",
                                size: 5,
                                outline: {
                                    color: [0, 0, 0],
                                    width: 1,
                                }
                            }
                        }
                    });

                    _intersectionLayer.current = intersectionsLayer;
                    intersectionUId = intersectionsLayer.uid;

                    // setIntersectionLayer(intersectionLayer);
                    //Load the road segment layer
                    var roadSegmentLayer = new FeatureLayer({
                        url: initDataGis.midblockFileAddress,
                        title: "Road Segment",
                        apiKey: initDataGis.arcGisApiKey,
                        minScale: 0, // Always visible
                        maxScale: 0, // Always visible
                        outFields: [initDataGis.midblockGeoIdName],
                        popupTemplate: {
                            lastEditInfoEnabled: false,
                            actions: getRoadSegmentPopupAction(),
                            content: [
                                {
                                    type: "fields",
                                    fieldInfos: [
                                        {
                                            fieldName: initDataGis.midblockGeoIdName,
                                            label: "Geo ID",
                                        },
                                    ],
                                },
                            ],
                        },
                        renderer: {
                            type: "simple",
                            symbol: {
                                type: 'simple-line', // Use a simple fill symbol
                                color: [0, 0, 0], // Set the color to black (RGB values)
                                size: 1
                                // outline: {
                                //   color: [0, 0, 0], // Set the outline color to black (RGB values)
                                //   width: 1 // Set the outline width
                                // }
                            }
                        }
                    });
                    _roadSegmentLayer.current = roadSegmentLayer;
                    roadSegmentUId = roadSegmentLayer.uid;
                    //Setup Graphic Layer fro Drawing
                    if (_selectionIsEnable) {
                        var polygonGraphicsLayer = new GraphicsLayer({
                            title: "Selected Area",
                        });
                        _polygonGraphicsLayer.current = polygonGraphicsLayer;
                    }
                    //Load customer Layers
                    let lstLayers = [];
                    initDataGis.gisLayers.forEach((layer) => {
                        lstLayers.push(
                            new FeatureLayer({
                                url: layer.url,
                                title: layer.name,
                                // popupTemplate: {
                                //     lastEditInfoEnabled: false,
                                //     content: [
                                //         {
                                //             type: "fields",
                                //             fieldInfos: [
                                //                 {
                                //                     fieldName: initDataGis.intersectionGeoIdName,
                                //                     label: "GeoID",
                                //                 },
                                //             ],
                                //         },
                                //     ],
                                // },

                            })
                        );
                    });

                    //Load map and layers
                    var map = new Map({
                        basemap: "osm",
                        layers: [intersectionsLayer, roadSegmentLayer, ...lstLayers],
                    });
                    _map.current = map;
                    if (_selectionIsEnable) {
                        map.add(polygonGraphicsLayer);
                    }
                    // and we show that map in a container
                    var view = new MapView({
                        map: map,
                        zoom: 12,
                        center: [initDataGis.latitude, initDataGis.longitude],
                        // use the ref as a container
                        container: mapEl.current,
                        // Disable mouse-wheel and single-touch map navigation.
                        // navigation: {
                        //     mouseWheelZoomEnabled: false,
                        //     browserTouchPanEnabled: false
                        // }
                    });


                    view.when(() => {
                        console.log("MapView loaded successfully");

                        Promise.all([
                            intersectionsLayer.queryExtent(),
                            roadSegmentLayer.queryExtent()
                        ]).then((extents) => {
                            const combinedExtent = extents.reduce((acc, extent) => {
                                return acc ? acc.union(extent.extent) : extent.extent;
                            }, null);

                            if (combinedExtent) {
                                view.goTo(combinedExtent.expand(1.1)); // Expand to add some padding
                            }
                        }).catch((error) => {
                            console.error("Error zooming to layers: ", error);
                        });
                    }).catch((error) => {
                        console.error("Error loading MapView: ", error);
                    });


                    view.popup.dockEnabled = true;

                    if (_moveMapIsEnable) {
                        view.ui.add("go-to-location", "top-left");

                        view.ui.add("go-to-coordinate", "top-left");
                    }

                    if (_selectionIsEnable) {
                        // add the select by polygon button the view
                        view.ui.add("select-by-polygon", "top-left");
                        const selectButton = document.getElementById("select-by-polygon");
                        // click event for the button
                        selectButton.addEventListener("click", function () {
                            view.popup.close();
                            // ready to draw a polygon
                            _sketchViewModel.current.create("polygon");
                        });
                    }

                    if (_selectionIsEnable) {

                        view.ui.add("clear-polygon", "top-left");
                        const removeButton = document.getElementById("clear-polygon");

                        // click event for clear the button
                        removeButton.addEventListener("click", function () {
                            clearUpSelection();
                        });

                        // create a new sketch view model set its layer
                        _sketchViewModel.current = new SketchViewModel({
                            view: view,
                            layer: _polygonGraphicsLayer.current,
                            pointSymbol: {
                                type: "simple-marker",
                                color: [255, 255, 255, 0],
                                size: "1px",
                                outline: {
                                    color: "gray",
                                    width: 0,
                                },
                            },
                        });
                    }




                    view.popup.watch("selectedFeature", (graphic) => {
                        if (graphic) {
                            //roadSegment
                            // if (graphic.geometry?.paths !== undefined) {
                            //     getLocationId({
                            //         customerId: localStorage.getItem('selectedCustomerId'),
                            //         locationType: LocationType.Midblock,
                            //         geoId: graphic.attributes[initDataGis.midblockGeoIdName]
                            //     })
                            // } else
                            // //intersection
                            // {
                            //     getLocationId({
                            //         customerId: localStorage.getItem('selectedCustomerId'),
                            //         locationType: LocationType.Intersection,
                            //         geoId: graphic.attributes[initDataGis.intersectionGeoIdName]
                            //     })
                            // }

                            if (graphic.layer?.uid.toString().toLowerCase().trim() === roadSegmentUId.toString().toLowerCase().trim()) {
                                getLocationId({
                                    customerId: localStorage.getItem('selectedCustomerId'),
                                    locationType: LocationType.Midblock,
                                    geoId: graphic.attributes[initDataGis.midblockGeoIdName]
                                })
                            }
                            if (graphic.layer?.uid.toString().toLowerCase().trim() === intersectionUId.toString().toLowerCase().trim()) {
                                getLocationId({
                                    customerId: localStorage.getItem('selectedCustomerId'),
                                    locationType: LocationType.Intersection,
                                    geoId: graphic.attributes[initDataGis.intersectionGeoIdName]
                                })
                            }

                        }
                    });



                    if (tesModule === TesMapModule.CollisionDetails || tesModule === TesMapModule.StudyDetails) {
                        view.on("double-click", function (event) {
                            view.popup.close();
                            view.graphics.removeAll();

                            //   //  console.log("MAP DATA" + JSON.stringify(event.mapPoint.x))
                            //   handleLocationChangeDirectly({
                            //     latitude: event.mapPoint.latitude,
                            //     longitude: event.mapPoint.longitude,
                            //     xCoord: event.mapPoint.x,
                            //     yCoord: event.mapPoint.y,
                            //   });
                        });
                    }

                    view.on("click", function (event) {
                        //query for roadSegment
                        // let query = roadSegmentLayer.createQuery();
                        // query.geometry = view.toMap(event);  // the point location of the pointer
                        // query.distance = 2;
                        // query.units = "miles";
                        // query.spatialRelationship = "intersects";  // this is the default
                        // query.returnGeometry = true;
                        // query.outFields = [initDataGis.midblockGeoIdName];
                        setIsRightSideOpen(true)
                    })

                    //Actions for Graphic Popups
                    view.when(function () {
                        var popup = view.popup;
                        popup.viewModel.on("trigger-action", function (event) {
                            if (event.action.id === "new-collision-intersection") {
                                // var attributes = popup.viewModel.selectedFeature.attributes;
                                //addCollisionFromMap(attributes[initDataGis.intersectionGeoIdName], 1);
                            }

                            if (event.action.id === "new-collision-midblock") {
                                //  let attributes = popup.viewModel.selectedFeature.attributes;
                                //addCollisionFromMap(attributes[initDataGis.midblockGeoIdName], 2);
                            }
                            if (event.action.id === "go-to-collision") {
                                var content = popup.viewModel.content;
                                // go2Collision(
                                //   content[0].fieldInfos.find((x) => x.fieldName === "id").label
                                // );
                            }
                            if (event.action.id === "go-to-midblock") {
                                let attributes = popup.viewModel.selectedFeature.attributes;
                                go2RoadSegment(attributes[initDataGis.midblockGeoIdName]);
                            }

                            if (event.action.id === "open-google-maps") {
                                openInGoogleMap(popup.viewModel.selectedFeature)
                            }
                            if (event.action.id === "street-view") {
                                openStreetView(popup.viewModel.selectedFeature)
                            }
                            if (event.action.id === "go-to-intersection") {
                                let attributes = popup.viewModel.selectedFeature.attributes;
                                go2Intersection(attributes[initDataGis.intersectionGeoIdName]);
                            }

                            if (event.action.id === "show-all-data-of-geoId") {
                                let attributes = popup.viewModel.selectedFeature.attributes;
                                //Intersection
                                if (popup.viewModel.selectedFeature.geometry.paths === undefined) {
                                    //filterByGeoId([{ geoId: attributes[initDataGis.intersectionGeoIdName], locationType: LocationType.Intersection }]);
                                } else {
                                    //filterByGeoId([{ geoId: attributes[initDataGis.intersectionGeoIdName], locationType: LocationType.Midblock }]);
                                }
                            }
                            if (event.action.id === "go-to-signSupport") {
                                let content = popup.viewModel.content;
                                // go2SignSupport(
                                //   content[0].fieldInfos.find((x) => x.fieldName === "id").label
                                // );
                            }
                            if (event.action.id === "select-intersection") {

                                let attributes = popup.viewModel.selectedFeature.attributes;
                                // selectLocation(
                                //   attributes[initDataGis.intersectionGeoIdName],
                                //   LocationType.Intersection,
                                //   {
                                //     latitude: selectedLatitude,
                                //     longitude: selectedLongitude,
                                //     xCoord: selectedXCoord,
                                //     yCoord: selectedYCoord,
                                //   }
                                // );
                                //Remove All Highlight
                                if (selectedHighlighted !== null) {
                                    selectedHighlighted.remove();
                                }
                                setCallForRemoveAllHighLight(getRandomInt(11, 30))
                                view?.whenLayerView(_intersectionLayer.current).then(function (layerView) {
                                    reactiveUtils
                                        .whenOnce(() => !layerView.updating)
                                        .then(() => {
                                            selectedHighlighted = layerView.highlight(popup.viewModel.selectedFeature);
                                        })
                                })
                                setLstLocalSelectedIntersection([attributes[initDataGis.intersectionGeoIdName]])
                                setLstLocalSelectedRoadSegment([])
                                view.popup.close();
                            }
                            if (event.action.id === "select-roadSegment") {

                                let attributes = popup.viewModel.selectedFeature.attributes;
                                // selectLocation(
                                //   attributes[initDataGis.midblockGeoIdName],
                                //   LocationType.Midblock,
                                //   {
                                //     latitude: selectedLatitude,
                                //     longitude: selectedLongitude,
                                //     xCoord: selectedXCoord,
                                //     yCoord: selectedYCoord,
                                //   }
                                // );
                                //Remove All Highlight
                                if (selectedHighlighted !== null) {
                                    selectedHighlighted.remove();
                                }
                                setCallForRemoveAllHighLight(getRandomInt(11, 30))
                                view?.whenLayerView(_roadSegmentLayer.current).then(function (layerView) {
                                    reactiveUtils
                                        .whenOnce(() => !layerView.updating)
                                        .then(() => {
                                            selectedHighlighted = layerView.highlight(popup.viewModel.selectedFeature);
                                        })
                                })
                                setLstLocalSelectedRoadSegment([attributes[initDataGis.midblockGeoIdName]])
                                setLstLocalSelectedIntersection([])
                                view.popup.close();
                            }
                        });
                    });


                    view.when(() => {
                        //Layer Management tools

                        const layerList = new Expand({
                            content: new LayerList({
                                view: view
                            }),
                            view: view,
                            expanded: false
                        });





                        //Export tools

                        const printTools = new Expand({
                            content: new Print({
                                view: view,
                                // specify your own print service
                                printServiceUrl:
                                    "https://utility.arcgisonline.com/arcgis/rest/services/Utilities/PrintingTools/GPServer/Export%20Web%20Map%20Task",
                            }),
                            view: view,
                            expanded: false
                        });


                        // Add widget to the top right corner of the view
                        view.ui.add(layerList, "top-right");
                        view.ui.add(printTools, "top-right");
                    });

                    view.when(disableZooming);
                    _view.current = view;

                    if (_selectionIsEnable) {
                        _sketchViewModel.current.on(
                            "create",
                            function (event) {
                                if (event.state === "complete") {
                                    // this polygon will be used to query features that intersect it
                                    polygonGraphicsLayer.remove(event.graphic);
                                    selectFeatures(event.graphic.geometry);
                                }
                            },
                            []
                        );
                    }
                    setViewIsLoaded(true);




                    function getGraphics(response) {
                        // the topmost graphic from the click location
                        // and display select attribute values from the
                        // graphic to the user
                        if (response.results[0]) {
                            var graphic = response.results[0].graphic;
                            var attributes = graphic.attributes;
                            if (tesModule === TesMapModule.Collision || tesModule === TesMapModule.Infrastructure) {
                                if (attributes.id) {
                                    setTimeout(() => {
                                        view.popup.close();
                                    }, 100);
                                    setTimeout(() => {
                                        view.popup.open({
                                            // open a popup to show some of the results
                                            location: response.results[0].mapPoint,
                                            title: "Collision Data",
                                            actions: [
                                                {
                                                    id: "go-to-collision",
                                                    image:
                                                        "https://staticfile.tes.ca/gisMap/car-collision.png",
                                                    title: "Show Collision Detail",
                                                },
                                            ],
                                            content: [
                                                {
                                                    type: "fields",
                                                    fieldInfos: [
                                                        {
                                                            fieldName: "id",
                                                            label: attributes.id,
                                                        },
                                                    ],
                                                },
                                            ],
                                        });
                                    }, 1000);
                                }
                            }
                            if (
                                tesModule === TesMapModule.Sign ||
                                tesModule === TesMapModule.Support
                            ) {
                                if (attributes.id) {
                                    setTimeout(() => {
                                        view.popup.close();
                                    }, 100);
                                    setTimeout(() => {
                                        view.popup.open({
                                            // open a popup to show some of the results
                                            location: response.results[0].mapPoint,
                                            title: "Sign/Support Data",
                                            actions: [
                                                {
                                                    id: "go-to-signSupport",
                                                    image: "https://staticfile.tes.ca/gisMap/sign.png",
                                                    title:
                                                        tesModule === TesMapModule.Support
                                                            ? "Show Support Details"
                                                            : tesModule === TesMapModule.Sign
                                                                ? "Show Sign Details"
                                                                : "",
                                                },
                                            ],
                                            content: [
                                                {
                                                    type: "fields",
                                                    fieldInfos: [
                                                        {
                                                            fieldName: "id",
                                                            label: attributes.id,
                                                        },
                                                    ],
                                                },
                                            ],
                                        });
                                    }, 1000);
                                }
                            }
                        }
                    }



                    //Functions
                    /****************************************************
                     * Selects features from the csv layer that intersect
                     * a polygon that user drew using sketch view model
                     ****************************************************/
                    function selectFeatures(geometry) {
                        findMinMaxSelectedAreas(geometry.rings[0]);
                        // create a query and set its geometry parameter to the
                        // polygon that was drawn on the view
                        const query = {
                            geometry: geometry,
                            outFields: ["*"],
                            returnGeometry: true,
                        };
                        const finalData = [];
                        // query graphics from the layer view. Geometry set for the query
                        // can be polygon for point features and only intersecting geometries are returned

                        //Intersection Layer Query
                        view
                            ?.whenLayerView(_intersectionLayer.current)
                            .then(function (layerView) {
                                reactiveUtils
                                    .whenOnce(() => !layerView.updating)
                                    .then(() => {
                                        layerView
                                            .queryFeatures(query)
                                            .then(function (results) {
                                                const graphics = results.features;
                                                if (graphics.length > 0 && finalData.length === 0) {
                                                    // zoom to the extent of the polygon with factor 2
                                                    view
                                                        .goTo(geometry.extent.expand(2))
                                                        .catch(function (error) {
                                                            if (error.name !== "AbortError") {
                                                                console.error(error);
                                                            }
                                                        });
                                                }
                                                if (results.features.length === 0) {
                                                    layerView.featureEffect = null;
                                                } else {
                                                    layerView.featureEffect = {
                                                        filter: {
                                                            geometry: query.geometry,
                                                            spatialRelationship: "intersects",
                                                            distance: query.distance,
                                                            units: query.units,
                                                        },
                                                        excludedEffect:
                                                            "blur(5px) grayscale(90%) opacity(40%)",
                                                        includedEffect: " contrast(200%) saturate(200%)",
                                                    };
                                                }
                                                // get the attributes to add to selected Data
                                                const data = graphics.map(function (feature, i) {
                                                    return (
                                                        Object.keys(feature.attributes)
                                                            .filter(function (key) {
                                                                return (
                                                                    [initDataGis.intersectionGeoIdName].indexOf(
                                                                        key
                                                                    ) !== -1
                                                                );
                                                            })
                                                            // need to create key value pairs from the feature
                                                            // attributes so that info can added in selected data
                                                            .reduce(function (obj, key) {
                                                                obj[key] = feature.attributes[key];
                                                                return obj;
                                                            }, {})
                                                    );
                                                });
                                                finalData.push(...data.map(x => ({ geoId: x[initDataGis.intersectionGeoIdName], locationType: LocationType.Intersection })));

                                            })
                                            .catch();
                                    });
                            });

                        //Road Segment Layer Query
                        view
                            ?.whenLayerView(_roadSegmentLayer.current)
                            .then(function (layerView) {
                                reactiveUtils
                                    .whenOnce(() => !layerView.updating)
                                    .then(() => {
                                        layerView
                                            .queryFeatures(query)
                                            .then(function (results) {
                                                const graphics = results.features;
                                                if (graphics.length > 0 && finalData.length === 0) {
                                                    // zoom to the extent of the polygon with factor 2
                                                    view
                                                        .goTo(geometry.extent.expand(2))
                                                        .catch(function (error) {
                                                            if (error.name !== "AbortError") {
                                                                console.error(error);
                                                            }
                                                        });
                                                }
                                                if (results.features.length === 0) {
                                                    layerView.featureEffect = {
                                                        includedEffect:
                                                            "blur(5px) grayscale(90%) opacity(40%)",
                                                    };
                                                } else {
                                                    layerView.featureEffect = {
                                                        filter: {
                                                            geometry: query.geometry,
                                                            spatialRelationship: "intersects",
                                                            distance: query.distance,
                                                            units: query.units,
                                                        },
                                                        excludedEffect:
                                                            "blur(2px) saturate(200%) opacity(40%)",
                                                    };
                                                }
                                                // get the attributes to add to selected Data
                                                const data = graphics.map(function (feature, i) {
                                                    return (
                                                        Object.keys(feature.attributes)
                                                            .filter(function (key) {
                                                                return (
                                                                    [initDataGis.midblockGeoIdName].indexOf(
                                                                        key
                                                                    ) !== -1
                                                                );
                                                            })
                                                            // need to create key value pairs from the feature
                                                            // attributes so that info can added in selected data
                                                            .reduce(function (obj, key) {
                                                                obj[key] = feature.attributes[key];
                                                                return obj;
                                                            }, {})
                                                    );
                                                });
                                                finalData.push(...data.map(x => ({ geoId: x[initDataGis.midblockGeoIdName], locationType: LocationType.Midblock })));
                                                setSelectedData(finalData);
                                            })
                                            .catch();
                                    });
                            });
                    }

                    function findMinMaxSelectedAreas(lstPoints) {
                        lstPoints = lstPoints.map((x) => {
                            return webMercatorUtils.xyToLngLat(x[0], x[1]);
                        });
                        const lstX = lstPoints.flatMap((x) => x[0]);
                        const lstY = lstPoints.flatMap((x) => x[1]);
                        var xMax = Math.max.apply(null, lstX);
                        var xMin = Math.min.apply(null, lstX);
                        var yMax = Math.max.apply(null, lstY);
                        var yMin = Math.min.apply(null, lstY);
                        setSelectedArea({
                            longitudeMax: xMax,
                            longitudeMin: xMin,
                            latitudeMax: yMax,
                            latitudeMin: yMin,
                        });
                    }

                    function clearUpSelection() {
                        view.popup.close();
                        setSelectedData([])
                        view
                            ?.whenLayerView(_intersectionLayer.current)
                            .then(function (layerView) {
                                reactiveUtils
                                    .whenOnce(() => !layerView.updating)
                                    .then(() => {
                                        layerView.featureEffect = null;
                                    });
                            });
                        view
                            ?.whenLayerView(_roadSegmentLayer.current)
                            .then(function (layerView) {
                                reactiveUtils
                                    .whenOnce(() => !layerView.updating)
                                    .then(() => {
                                        layerView.featureEffect = null;
                                    });
                            });
                    }


                }
            );
            return () => {
                //  clean up the map view
                // if (!!_view.current) {
                //   _view.current.destroy();
                //   _view.current = null;
                // }
            };
        },
        // only re-load the map if the Gis data has changed
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [initDataGis]
    );



    useEffect(() => {
        loadModules(["esri/core/reactiveUtils", "esri/layers/FeatureLayer", "esri/layers/support/AggregateField", "esri/Graphic", "esri/geometry/Point", "esri/symbols/SimpleMarkerSymbol", "esri/renderers/UniqueValueRenderer",
            "esri/geometry/support/webMercatorUtils", "esri/widgets/Legend"], {
            css: true,
        }).then(([reactiveUtils, FeatureLayer, AggregateField, Graphic, Point, SimpleMarkerSymbol, UniqueValueRenderer, webMercatorUtils, Legend]) => {
            if (!viewIsLoaded) return;
            try {
                // Check if the function should test the layer

                const roadSegmentLayer = _roadSegmentLayer.current;
                const intersectionLayer = _intersectionLayer.current;
                if (!roadSegmentLayer) {
                    throw new Error("Road segment layer is not initialized.");
                }
                if (!intersectionLayer) {
                    throw new Error("Intersection layer is not initialized.");
                }
                const roadSegmentQuery = {
                    outFields: ["*"],
                    where: "1=1", // Query to retrieve all features
                    returnGeometry: true,
                };
                const intersectionQuery = {
                    outFields: ["*"],
                    where: "1=1", // Query to retrieve all intersections
                    returnGeometry: true,
                };

                intersectionLayer.queryFeatures(intersectionQuery).then(intersectionResults => {
                    const intersectionGraphics = intersectionResults.features;
                    const firstTenIntersections = intersectionGraphics.slice(0, 10); // Get the first 10 intersections

                    // Log the attributes (fields and values) of each intersection
                    firstTenIntersections.forEach((feature, index) => {
                        console.log(`Intersection ${index + 1} attributes:`, feature.attributes);
                    });
                }).catch(error => {
                    console.error("Error querying intersections:", error);
                });

                // roadSegmentLayer.queryFeatures(roadSegmentQuery).then(roadSegmentResults => {
                //     const graphics = roadSegmentResults.features;
                //     graphics.forEach(element => {
                //         if (element.geometry.paths.length === 0) {
                //             throw new Error("Road segment graphic's lack geometry paths.");
                //         }
                //     });


                //     // Layer has valid geometries
                //     console.log("All good");
                // }).catch(error => {
                //     console.error("Error testing layer:", error);
                // });


                roadSegmentLayer.queryFeatures(roadSegmentQuery).then(roadSegmentResults => {
                    const graphics = roadSegmentResults.features;
                    const firstTenRoadSegments = graphics.slice(0, 10); // Get the first 10 road segment records
                    firstTenRoadSegments.forEach((feature, index) => {
                        console.log(`Intersection ${index + 1} attributes:`, feature.attributes);
                    });

                    graphics.forEach(element => {
                        if (element.geometry.paths.length === 0) {
                            throw new Error("Road segment graphic's lack geometry paths.");
                        }
                    });
                    // Layer has valid geometries
                    console.log("All good");
                }).catch(error => {
                    console.error("Error testing layer:", error);
                });

            } catch (error) {
                console.error("Error testing layer:", error);
            }
        });
    }, [mapTest]);





    //#region  Collision
    //Show Collision Data
    useEffect(async () => {

        // define the view here so it can be referenced in the clean up function
        // first lazy-load the esri classes
        loadModules(
            [
                "esri/layers/FeatureLayer",
                "esri/Graphic",
            ],
            {
                css: true,
            }
        ).then(
            ([
                FeatureLayer,
                Graphic,

            ]) => {

                if (collisionToMapData == null) return;

                //Show Base on Coordinate
                if (collisionToMapData.geoJsonData !== null && collisionToMapData.geoJsonData !== undefined && collisionToMapData.geoJsonData.features?.length > 0 && collisionToMapReq.showClusterData === true) {
                    setShowLoading(true)
                    showCollisionBaseOnCoordinate(collisionToMapData.geoJsonData)
                } else {
                    var layer = _map.current.layers.find(x => x.id === "ClusterOfCollisionCoordination")
                    if (layer !== null && layer !== undefined) {
                        _map.current.remove(layer);
                    }
                }

                //Show Data Base on Location
                if (collisionToMapData.collisionLocations !== null && collisionToMapData.collisionLocations !== undefined && collisionToMapData.collisionLocations?.length > 0 && collisionToMapReq.showCollisionLocations === true) {
                    setShowLoading(true)
                    showCollisionBaseOnLocation(collisionToMapData.collisionLocations)
                } else {
                    var layer = _map.current.layers.find(x => x.id === "ClusterOfCollisionLocatio")
                    if (layer !== null && layer !== undefined) {
                        _map.current.remove(layer);
                    }
                }
            })






        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collisionToMapData]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const showCollisionBaseOnCoordinate = useCallback((collisionGeoJsonData) => {
        // define the view here so it can be referenced in the clean up function
        loadModules(["esri/layers/FeatureLayer", "esri/Graphic"], {
            css: true,
        }).then(([FeatureLayer, Graphic]) => {


            const clusterConfig = {
                type: "cluster",
                clusterRadius: "100px",
                popupTemplate: {
                    title: "Collision summary",
                    content: [
                        {
                            type: "text",
                            text: "{cluster_count} collision occurred in this area"
                        }
                    ]
                },
                clusterMinSize: "24px",
                clusterMaxSize: "60px",
                labelingInfo: [
                    {
                        deconflictionStrategy: "none",
                        labelExpressionInfo: {
                            expression: "Text($feature.cluster_count, '#,###')"
                        },
                        symbol: {
                            type: "text",
                            color: "#424242",
                            font: {
                                weight: "bold",
                                family: "Noto Sans",
                                size: "12px"
                            }
                        },
                        labelPlacement: "center-center"
                    }
                ]
            };

            var graphics = collisionGeoJsonData.features.map(function (place) {
                return new Graphic({
                    attributes: {
                        ObjectId: place.id,
                    },
                    geometry: {
                        longitude: place.geometry.coordinates[0],
                        latitude: place.geometry.coordinates[1],
                        type: "point"
                    }
                });
            });

            var clusterLayer = new FeatureLayer({
                id: "ClusterOfCollisionCoordination",
                source: graphics,
                title: "Cluster of Collision Coordinations",
                featureReduction: clusterConfig,
                objectIdField: "ObjectId",
                renderer: {
                    type: "simple",
                    field: "cluster_count",
                    legendOptions: {
                        title: "cluster Data"
                    },
                    symbol: {
                        type: "simple-marker",
                        size: 4,
                        color: "#f0b70d",
                        outline: {
                            color: "rgba(42, 42, 42, 0.5)",
                            width: 5
                        }
                    }
                }

            });
            _map.current.add(clusterLayer);
            setShowLoading(false)
        });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const showCollisionBaseOnLocation = useCallback((lstCollisionLocations) => {
        // define the view here so it can be referenced in the clean up function
        loadModules(["esri/core/reactiveUtils", "esri/layers/FeatureLayer", "esri/layers/support/AggregateField", "esri/Graphic", "esri/geometry/Point", "esri/symbols/SimpleMarkerSymbol", "esri/geometry/support/webMercatorUtils"], {
            css: true,
        }).then(([reactiveUtils, FeatureLayer, AggregateField, Graphic, Point, SimpleMarkerSymbol, webMercatorUtils]) => {

            if (!viewIsLoaded || lstCollisionLocations.length === 0) return;


            var lstGraphics = []

            const intersectionLayer = _intersectionLayer.current;
            const view = _view.current;
            //It's important to query run on all features
            // intersectionLayer.load().then(() => {
            //     // Set the view extent to the data extent
            //     view.extent = intersectionLayer.fullExtent;
            //     setExtentIsFull(true);
            // });
            var strIntersectionQuery = initDataGis.intersectionGeoIdName + " In (";
            if (lstCollisionLocations) {
                lstCollisionLocations.filter(x => x.locationType === LocationType.Intersection).forEach((s) => {
                    strIntersectionQuery +=
                        initDataGis.intersectionGeoIdType === FieldType.String
                            ? "'" + s.geoId + "',"
                            : s.geoId + ",";
                });
                strIntersectionQuery = strIntersectionQuery.substring(
                    0,
                    strIntersectionQuery.length - 1
                );
                strIntersectionQuery += ")"
            }
            if (strIntersectionQuery === "") {
                return;
            }
            const intersectionQuery = {
                outFields: ["*"],
                where: strIntersectionQuery,
                returnGeometry: true,
            };


            // queries when view and layer has been loaded
            view?.whenLayerView(intersectionLayer).then(function (intersectionLayerView) {
                reactiveUtils
                    .whenOnce(() => !intersectionLayerView.updating)
                    .then(() => {
                        intersectionLayerView
                            .queryFeatures(intersectionQuery)
                            .then(function (intersectionResults) {
                                const graphics = intersectionResults.features;

                                for (let index = 0; index < graphics.length; index++) {
                                    graphics[index].attributes["totalCollisionCount"] = lstCollisionLocations.find(x => x.geoId == graphics[index].attributes[initDataGis.intersectionGeoIdName]).total
                                    graphics[index].attributes["injuryCollisionCount"] = lstCollisionLocations.find(x => x.geoId == graphics[index].attributes[initDataGis.intersectionGeoIdName]).injury
                                    graphics[index].attributes["fatalCollisionCount"] = lstCollisionLocations.find(x => x.geoId == graphics[index].attributes[initDataGis.intersectionGeoIdName]).fatal;
                                    graphics[index].attributes["pdoCollisionCount"] = lstCollisionLocations.find(x => x.geoId == graphics[index].attributes[initDataGis.intersectionGeoIdName]).pdo;
                                    graphics[index].attributes[initDataGis.midblockGeoIdName] = "";
                                }
                                lstGraphics = [...lstGraphics, ...graphics]

                                const roadsegmentLayer = _roadSegmentLayer.current;

                                //It's important to query run on all features
                                // roadsegmentLayer.load().then(() => {
                                //     // Set the view extent to the data extent
                                //     view.extent = roadsegmentLayer.fullExtent;
                                //     setExtentIsFull(true);
                                // });

                                var strRoadSegmentQuery = initDataGis.midblockGeoIdName + " In (";
                                if (lstCollisionLocations) {
                                    lstCollisionLocations.filter(x => x.locationType === LocationType.Midblock).forEach((s) => {
                                        strRoadSegmentQuery +=
                                            initDataGis.midblockGeoIdType === FieldType.String
                                                ? "'" + s.geoId + "',"
                                                : s.geoId + ",";
                                    });
                                    strRoadSegmentQuery = strRoadSegmentQuery.substring(
                                        0,
                                        strRoadSegmentQuery.length - 1
                                    );
                                    strRoadSegmentQuery += ")"
                                }
                                if (strRoadSegmentQuery === "") {
                                    return;
                                }
                                const roadSegmentQuery = {
                                    outFields: ["*"],
                                    where: strRoadSegmentQuery,
                                    returnGeometry: true,
                                };


                                // queries when view and layer has been loaded
                                view?.whenLayerView(roadsegmentLayer).then(function (RoadSegmentLayerView) {
                                    reactiveUtils
                                        .whenOnce(() => !RoadSegmentLayerView.updating)
                                        .then(() => {
                                            RoadSegmentLayerView
                                                .queryFeatures(roadSegmentQuery)
                                                .then(function (RoadSegmentResults) {
                                                    var graphics = RoadSegmentResults.features;

                                                    var lstNewRoadSegmentGraphics = graphics.filter(x => x.geometry.paths.length > 0).map(function (place) {

                                                        var paths = place.geometry.paths[0];
                                                        const res = getAveragePaths(paths)
                                                        var [lng, lat] = webMercatorUtils.xyToLngLat(res[0], res[1]);
                                                        var webMercatorCoords = webMercatorUtils.lngLatToXY(lng, lat);

                                                        return new Graphic({
                                                            attributes: {
                                                                FID: 0,
                                                                InterID: "",
                                                                totalCollisionCount: lstCollisionLocations.find(x => x.geoId == place.attributes[initDataGis.midblockGeoIdName]).total,
                                                                injuryCollisionCount: lstCollisionLocations.find(x => x.geoId == place.attributes[initDataGis.midblockGeoIdName]).injury,
                                                                fatalCollisionCount: lstCollisionLocations.find(x => x.geoId == place.attributes[initDataGis.midblockGeoIdName]).fatal,
                                                                pdoCollisionCount: lstCollisionLocations.find(x => x.geoId == place.attributes[initDataGis.midblockGeoIdName]).pdo,
                                                                [initDataGis.midblockGeoIdName]: place.attributes[initDataGis.midblockGeoIdName]
                                                            },
                                                            geometry: {
                                                                type: 'point',
                                                                x: webMercatorCoords[0],
                                                                y: webMercatorCoords[1],
                                                                spatialReference: { wkid: place.geometry?.spatialReference?.wkid }
                                                            }
                                                        });

                                                    });
                                                    lstGraphics = [...lstGraphics, ...lstNewRoadSegmentGraphics]

                                                    const includedColor = "#de2c30";
                                                    const excludedColor = "#ffdaa6";


                                                    const clusterConfig = {
                                                        type: "cluster",

                                                        fields: [
                                                            new AggregateField({
                                                                name: "aggregateCount",
                                                                statisticType: "count"
                                                            }),

                                                            new AggregateField({
                                                                name: "SUM_FATAL",
                                                                onStatisticField: "fatalCollisionCount",
                                                                statisticType: "sum"
                                                            }),
                                                            new AggregateField({
                                                                name: "SUM_INJURY",
                                                                onStatisticField: "injuryCollisionCount",
                                                                statisticType: "sum"
                                                            }),
                                                            new AggregateField({
                                                                name: "SUM_PDO",
                                                                onStatisticField: "pdoCollisionCount",
                                                                statisticType: "sum"
                                                            }),
                                                            new AggregateField({
                                                                name: "SUM_TOTAL",
                                                                onStatisticField: "totalCollisionCount",
                                                                statisticType: "sum"
                                                            })
                                                        ],
                                                        renderer: {
                                                            type: "class-breaks",
                                                            field: "SUM_FATAL",
                                                            legendOptions: {
                                                                title: "cluster Data"
                                                            },
                                                            classBreakInfos: [
                                                                {
                                                                    minValue: 0,
                                                                    maxValue: 0,
                                                                    label: "No fatalities",
                                                                    symbol: {
                                                                        type: "simple-marker",
                                                                        color: excludedColor,
                                                                        outline: null,
                                                                        outline: {
                                                                            color: "rgba(153, 31, 23, 0.3)",
                                                                            width: 0.3
                                                                        }
                                                                    }
                                                                },
                                                                {
                                                                    minValue: 1,
                                                                    maxValue: 99999999999,
                                                                    label: "Includes fatalities",
                                                                    symbol: {
                                                                        type: "simple-marker",
                                                                        color: includedColor,
                                                                        outline: {
                                                                            color: "rgba(153, 31, 23, 0.3)",
                                                                            width: 0.3
                                                                        }
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        clusterRadius: "100px",
                                                        popupTemplate: {
                                                            title: "Collision summary",
                                                            content: [
                                                                {
                                                                    type: "text",
                                                                    text: "{SUM_TOTAL} collision occurred in this area"
                                                                },
                                                                {
                                                                    type: "fields",
                                                                    fieldInfos: [
                                                                        {
                                                                            fieldName: "SUM_TOTAL",
                                                                            label: "Total collisions",
                                                                            format: {
                                                                                places: 0,
                                                                                digitSeparator: true
                                                                            }
                                                                        },
                                                                        {
                                                                            fieldName: "SUM_FATAL",
                                                                            label: "Total Fatal"
                                                                        },
                                                                        {
                                                                            fieldName: "SUM_INJURY",
                                                                            label: "Total Injury"
                                                                        },
                                                                        {
                                                                            fieldName: "SUM_PDO",
                                                                            label: "Total PDO"
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        clusterMinSize: "24px",
                                                        clusterMaxSize: "60px",
                                                        labelingInfo: [
                                                            {
                                                                deconflictionStrategy: "none",
                                                                labelExpressionInfo: {
                                                                    expression: `
                                                                    var label = Text($feature.aggregateCount, '#,###');
                                                                    var fatalities = $feature.SUM_FATAL;
                                                                    if(fatalities > 0){
                                                                      label += \`\n(\${fatalities})\`
                                                                    }
                                                                    return label;
                                                                  `
                                                                },
                                                                symbol: {
                                                                    type: "text",
                                                                    color: "white",
                                                                    font: {
                                                                        weight: "bold",
                                                                        family: "Noto Sans",
                                                                        size: "12px"
                                                                    },
                                                                    haloColor: includedColor,
                                                                    haloSize: 1
                                                                },
                                                                labelPlacement: "center-center"
                                                            }
                                                        ]
                                                    };

                                                    var clusterLayer = new FeatureLayer({
                                                        id: "ClusterOfCollisionLocatio",
                                                        source: lstGraphics,
                                                        title: "Cluster of Collision Locations",
                                                        featureReduction: clusterConfig,
                                                        objectIdField: "ObjectId",
                                                        labelingInfo: [
                                                            {
                                                                deconflictionStrategy: "none",
                                                                labelExpressionInfo: {
                                                                    expression: "Text($feature.totalCollisionCount, '#,###')"
                                                                },
                                                                symbol: {
                                                                    type: "text",
                                                                    color: "white",
                                                                    font: {
                                                                        weight: "bold",
                                                                        family: "Noto Sans",
                                                                        size: "12px"
                                                                    },
                                                                    haloColor: includedColor,
                                                                    haloSize: 1
                                                                },
                                                                labelPlacement: "center-center"
                                                            }
                                                        ],
                                                        fields: [
                                                            {
                                                                alias: "pdoCollisionCount",
                                                                defaultValue: null,
                                                                editable: true,
                                                                length: -1,
                                                                name: "pdoCollisionCount",
                                                                nullable: true,
                                                                type: "integer"
                                                            },
                                                            {
                                                                alias: "totalCollisionCount",
                                                                defaultValue: null,
                                                                editable: true,
                                                                length: -1,
                                                                name: "totalCollisionCount",
                                                                nullable: true,
                                                                type: "integer"
                                                            },
                                                            {
                                                                alias: "injuryCollisionCount",
                                                                defaultValue: null,
                                                                editable: true,
                                                                length: -1,
                                                                name: "injuryCollisionCount",
                                                                nullable: true,
                                                                type: "integer"
                                                            },
                                                            {
                                                                alias: "fatalCollisionCount",
                                                                defaultValue: null,
                                                                editable: true,
                                                                length: -1,
                                                                name: "fatalCollisionCount",
                                                                nullable: true,
                                                                type: "integer"
                                                            },
                                                        ],
                                                        // popupTemplates can still be viewed on
                                                        // individual features
                                                        popupTemplate: {
                                                            title: "Crash location",
                                                            content: [
                                                                {
                                                                    type: "fields",
                                                                    fieldInfos: [

                                                                        {
                                                                            fieldName: "totalCollisionCount",
                                                                            label: "Total"
                                                                        },
                                                                        {
                                                                            fieldName: "INJURY",
                                                                            label: "Injuries"
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        renderer: {
                                                            type: "simple",
                                                            field: "INJURY",
                                                            symbol: {
                                                                type: "simple-marker",
                                                                size: 4,
                                                                color: "#f0b70d",
                                                                outline: {
                                                                    color: "rgba(42, 42, 42, 0.5)",
                                                                    width: 5
                                                                }
                                                            }
                                                        }

                                                    });
                                                    _map.current.add(clusterLayer);

                                                    setShowLoading(false)
                                                })
                                                .catch(x => {
                                                    setShowLoading(false)
                                                });
                                        });
                                });





                            })
                            .catch(x => {
                                setShowLoading(false)
                            });
                    });
            });




        });
    });
    //#endregion


    //#region  Traffic
    //Show Traffic Data
    useEffect(async () => {
        if (trafficToMapData == null) return;
        if (trafficToMapData.trafficLocations !== null && trafficToMapData.trafficLocations !== undefined && trafficToMapData.trafficLocations?.length > 0 && trafficToMapReq.showRoadSegmentAADT) {
            removeLayer("RoadSegmentAADTLayer")
            if (_legend.current != null)
                _legend.current.destroy()
            showTrafficRoadSegmentAADT(trafficToMapData.trafficLocations.filter(x => x.roadSegmentAADT != 0))
        } else {
            removeLayer("RoadSegmentAADTLayer")
            if (_legend.current != null)
                _legend.current.destroy()
        }

        if (trafficToMapData.trafficLocations !== null && trafficToMapData.trafficLocations !== undefined && trafficToMapData.trafficLocations?.length > 0 && trafficToMapReq.showIntersectionAADT) {
            removeLayer("IntersectionAADTLayer")
            if (_legend.current != null)
                _legend.current.destroy()
            showTrafficIntersectionAADTs(trafficToMapData.trafficLocations.filter(x => x.intersectionAADT != 0))
        } else {
            removeLayer("IntersectionAADTLayer")
            if (_legend.current != null)
                _legend.current.destroy()

        }
        //color codes
        if (trafficToMapData.trafficLocations !== null && trafficToMapData.trafficLocations !== undefined && trafficToMapData.trafficLocations?.length > 0 && trafficToMapReq.showPostedSpeedByColor) {
            removeLayer("PostedSpeedByColor")
            if (_legend.current != null)
                _legend.current.destroy()
            showTrafficPostedSpeedByColor(trafficToMapData.trafficLocations.filter(x => x.postedSpeed != 0))
        } else {
            removeLayer("PostedSpeedByColor")
            if (_legend.current != null)
                _legend.current.destroy()

        }
        if (trafficToMapData.trafficLocations !== null && trafficToMapData.trafficLocations !== undefined && trafficToMapData.trafficLocations?.length > 0 && trafficToMapReq.showAverageSpeedByColor) {
            removeLayer("AverageSpeedByColor")
            if (_legend.current != null)
                _legend.current.destroy()

            showTrafficAverageSpeedByColor(trafficToMapData.trafficLocations.filter(x => x.averageSpeed != 0))
        } else {
            removeLayer("AverageSpeedByColor")
            if (_legend.current != null)
                _legend.current.destroy()

        }
        if (trafficToMapData.trafficLocations !== null && trafficToMapData.trafficLocations !== undefined && trafficToMapData.trafficLocations?.length > 0 && trafficToMapReq.showCompliance) {
            removeLayer("ComplianceLayer")
            if (_legend.current != null)
                _legend.current.destroy()

            showTrafficCompliance(trafficToMapData.trafficLocations.filter(x => x.compliance != 0))
        } else {
            removeLayer("ComplianceLayer")
            if (_legend.current != null)
                _legend.current.destroy()

        }
        // to delete layers
        if (trafficToMapReq.showPostedSpeedByColor === false) {
            removeLayer("PostedSpeedByColor")
            if (_legend.current != null)
                _legend.current.destroy()
        }
        if (trafficToMapReq.showAverageSpeedByColor === false) {
            removeLayer("AverageSpeedByColor")
            if (_legend.current != null)
                _legend.current.destroy()
        }
        if (trafficToMapReq.showIntersectionAADT === false) {
            removeLayer("IntersectionAADTLayer")
            if (_legend.current != null)
                _legend.current.destroy()
        }
        if (trafficToMapReq.showRoadSegmentAADT === false) {
            removeLayer("RoadSegmentAADTLayer")
            if (_legend.current != null)
                _legend.current.destroy()
        }
        if (trafficToMapReq.showCompliance === false) {
            removeLayer("ComplianceLayer")
            if (_legend.current != null)
                _legend.current.destroy()
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trafficToMapData]);


    useEffect(async () => {
        if (trafficToMapData == null) return;
        if (trafficToMapData.trafficLocations !== null && trafficToMapData.trafficLocations !== undefined && trafficToMapData.trafficLocations?.length > 0 && trafficToMapReq.showAverageSpeed) {
            removeLayer("AverageSpeedLayer")
            showTrafficAverageSpeed(trafficToMapData.trafficLocations)
        } else {
            removeLayer("AverageSpeedLayer")
        }
        if (trafficToMapReq.showAverageSpeed === false) {
            removeLayer("AverageSpeedLayer")
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trafficToMapReq.showAverageSpeed, trafficToMapData]);

    useEffect(async () => {
        if (trafficToMapData == null) return;
        //symbols
        if (trafficToMapData.trafficLocations !== null && trafficToMapData.trafficLocations !== undefined && trafficToMapData.trafficLocations?.length > 0 && trafficToMapReq.showPostedSpeed) {
            removeLayer("PostedSpeedLayer")
            showTrafficPostedSpeed(trafficToMapData.trafficLocations)
        } else {
            removeLayer("PostedSpeedLayer")
        }

        if (trafficToMapReq.showPostedSpeed === false) {
            removeLayer("PostedSpeedLayer")
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trafficToMapReq.showPostedSpeed, trafficToMapData]);

    useEffect(async () => {
        if (trafficToMapData == null) return;
        //symbols
        if (trafficToMapData.studies !== null && trafficToMapData.studies !== undefined && trafficToMapData.studies?.length > 0 && trafficToMapReq.showLatestSpeedStudy) {
            removeLayer("SpeedStudyLayer")
            showLatestSpeedStudy(trafficToMapData.studies.filter(x => x.studyType == StudyType.Speed))
        } else {
            removeLayer("SpeedStudyLayer")
        }

        if (trafficToMapReq.showLatestSpeedStudy === false) {
            removeLayer("SpeedStudyLayer")
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trafficToMapReq.showLatestSpeedStudy, trafficToMapData?.studies]);


    useEffect(async () => {
        if (trafficToMapData == null) return;
        //symbols
        if (trafficToMapData.studies !== null && trafficToMapData.studies !== undefined && trafficToMapData.studies?.length > 0 && trafficToMapReq.showLatestVolumeStudy) {
            removeLayer("VolumeStudyLayer")
            showLatestVolumeStudy(trafficToMapData.studies.filter(x => x.studyType == StudyType.Volume))
        } else {
            removeLayer("VolumeStudyLayer")
        }

        if (trafficToMapReq.showLatestVolumeStudy === false) {
            removeLayer("VolumeStudyLayer")
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trafficToMapReq.showLatestVolumeStudy, trafficToMapData]);

    useEffect(async () => {
        if (trafficToMapData == null) return;
        //symbols
        if (trafficToMapData.studies !== null && trafficToMapData.studies !== undefined && trafficToMapData.studies?.length > 0 && trafficToMapReq.showLatestTMCStudy) {
            removeLayer("TMCStudyLayer")
            showLatestTMCStudy(trafficToMapData.studies.filter(x => x.studyType === StudyType.TMC))
        } else {
            removeLayer("TMCStudyLayer")
        }

        if (trafficToMapReq.showLatestTMCStudy === false) {
            removeLayer("TMCStudyLayer")
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trafficToMapReq.showLatestTMCStudy, trafficToMapData]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    // const showTrafficPostedSpeedOriginal = useCallback((lstStudyLocations) => {
    //     // define the view here so it can be referenced in the clean up function
    //     loadModules(["esri/core/reactiveUtils", "esri/layers/FeatureLayer", "esri/layers/support/AggregateField", "esri/Graphic", "esri/geometry/Point", "esri/symbols/SimpleMarkerSymbol", "esri/renderers/UniqueValueRenderer",
    //         "esri/geometry/support/webMercatorUtils", "esri/renderers/ClassBreaksRenderer", "esri/widgets/Legend", "esri/widgets/Expand",], {
    //         css: true,
    //     }).then(([reactiveUtils, FeatureLayer, AggregateField, Graphic, Point, SimpleMarkerSymbol, UniqueValueRenderer, webMercatorUtils, ClassBreaksRenderer, Legend, Expand,]) => {

    //         if (!viewIsLoaded || lstStudyLocations.length === 0) return;


    //         var lstGraphics = []

    //         const view = _view.current;
    //         //It's important to query run on all features
    //         // intersectionLayer.load().then(() => {
    //         //     // Set the view extent to the data extent
    //         //     view.extent = intersectionLayer.fullExtent;
    //         //     setExtentIsFull(true);
    //         // });



    //         // queries when view and layer has been loaded

    //         const roadsegmentLayer = _roadSegmentLayer.current;



    //         var strRoadSegmentQuery = initDataGis.midblockGeoIdName + " In (";
    //         if (lstStudyLocations) {
    //             lstStudyLocations.filter(x => x.locationType === LocationType.Midblock && x.postedSpeed > 0).forEach((s) => {
    //                 strRoadSegmentQuery +=
    //                     initDataGis.midblockGeoIdType === FieldType.String
    //                         ? "'" + s.geoId + "',"
    //                         : s.geoId + ",";
    //             });
    //             strRoadSegmentQuery = strRoadSegmentQuery.substring(
    //                 0,
    //                 strRoadSegmentQuery.length - 1
    //             );
    //             strRoadSegmentQuery += ")"
    //         }
    //         if (strRoadSegmentQuery === "") {
    //             return;
    //         }
    //         const roadSegmentQuery = {
    //             outFields: ["*"],
    //             where: strRoadSegmentQuery,
    //             returnGeometry: true,
    //         };


    //         // queries when view and layer has been loaded
    //         roadsegmentLayer.queryFeatures(roadSegmentQuery).then(function (RoadSegmentResults) {
    //             var graphics = RoadSegmentResults.features;

    //             var lstNewRoadSegmentGraphics = graphics.filter(x => x.geometry.paths.length > 0).map(function (place) {
    //                 var paths = place.geometry.paths[0];
    //                 const res = getAveragePaths(paths)
    //                 var lineCenter = new Point(res[0], res[1], _map.current.spatialReference);
    //                 var lt = webMercatorUtils.xyToLngLat(lineCenter.x, lineCenter.y)

    //                 return new Graphic({
    //                     attributes: {
    //                         [initDataGis.midblockGeoIdName]: place.attributes[initDataGis.midblockGeoIdName].toString(),
    //                         postedSpeed: lstStudyLocations.find(x => x.geoId == place.attributes[initDataGis.midblockGeoIdName]).postedSpeed,
    //                     },
    //                     geometry: {
    //                         type: 'point',
    //                         latitude: lt[1],
    //                         longitude: lt[0]
    //                     }
    //                 });

    //             });
    //             lstGraphics = [...lstGraphics, ...lstNewRoadSegmentGraphics]

    //             if (trafficToMapReq.showPostedSpeed === true) {
    //                 var renderer = new UniqueValueRenderer({
    //                     field: "postedSpeed",
    //                     uniqueValueInfos: [
    //                         {
    //                             value: "30",
    //                             symbol: {
    //                                 type: "picture-marker",
    //                                 url: "http://staticfile.tes.ca/gisMap/speed/30.png",
    //                                 width: "25px",
    //                                 height: "25px"
    //                             }
    //                         },
    //                         {
    //                             value: "40",
    //                             symbol: {
    //                                 type: "picture-marker",
    //                                 url: "http://staticfile.tes.ca/gisMap/speed/40.png",
    //                                 width: "25px",
    //                                 height: "25px"
    //                             }
    //                         },
    //                         {
    //                             value: "50",
    //                             symbol: {
    //                                 type: "picture-marker",
    //                                 url: "http://staticfile.tes.ca/gisMap/speed/50.png",
    //                                 width: "25px",
    //                                 height: "25px"
    //                             }
    //                         },
    //                         {
    //                             value: "60",
    //                             symbol: {
    //                                 type: "picture-marker",
    //                                 url: "http://staticfile.tes.ca/gisMap/speed/60.png",
    //                                 width: "25px",
    //                                 height: "25px"
    //                             }
    //                         },
    //                         {
    //                             value: "70",
    //                             symbol: {
    //                                 type: "picture-marker",
    //                                 url: "http://staticfile.tes.ca/gisMap/speed/70.png",
    //                                 width: "25px",
    //                                 height: "25px"
    //                             }
    //                         },
    //                         {
    //                             value: "80",
    //                             symbol: {
    //                                 type: "picture-marker",
    //                                 url: "http://staticfile.tes.ca/gisMap/speed/80.png",
    //                                 width: "25px",
    //                                 height: "25px"
    //                             }
    //                         },
    //                         {
    //                             value: "90",
    //                             symbol: {
    //                                 type: "picture-marker",
    //                                 url: "http://staticfile.tes.ca/gisMap/speed/90.png",
    //                                 width: "25px",
    //                                 height: "25px"
    //                             }
    //                         },
    //                         {
    //                             value: "100",
    //                             symbol: {
    //                                 type: "picture-marker",
    //                                 url: "http://staticfile.tes.ca/gisMap/speed/100.png",
    //                                 width: "25px",
    //                                 height: "25px"
    //                             }
    //                         },
    //                         {
    //                             value: "110",
    //                             symbol: {
    //                                 type: "picture-marker",
    //                                 url: "http://staticfile.tes.ca/gisMap/speed/110.png",
    //                                 width: "25px",
    //                                 height: "25px"
    //                             }
    //                         },
    //                         {
    //                             value: "120",
    //                             symbol: {
    //                                 type: "picture-marker",
    //                                 url: "http://staticfile.tes.ca/gisMap/speed/120.png",
    //                                 width: "25px",
    //                                 height: "25px"
    //                             }
    //                         },
    //                     ]
    //                 }
    //                 );

    //                 var postedSpeedLayer = new FeatureLayer({
    //                     id: "PostedSpeedLayer",
    //                     source: lstGraphics,
    //                     title: "Posted Speed",
    //                     objectIdField: "ObjectId",
    //                     outFields: ["*"],
    //                     fields: [
    //                         {
    //                             alias: "Posted Speed",
    //                             defaultValue: null,
    //                             editable: true,
    //                             length: -1,
    //                             name: "postedSpeed",
    //                             nullable: true,
    //                             type: "integer"
    //                         },
    //                         {
    //                             alias: initDataGis.midblockGeoIdName,
    //                             defaultValue: null,
    //                             editable: true,
    //                             length: -1,
    //                             name: initDataGis.midblockGeoIdName,
    //                             nullable: false,
    //                             type: "string"
    //                         },

    //                     ],
    //                     // popupTemplates can still be viewed on
    //                     // individual features
    //                     popupTemplate: {
    //                         title: "Posted Speed",
    //                         content: [
    //                             {
    //                                 type: "fields",
    //                                 fieldInfos: [
    //                                     {
    //                                         fieldName: initDataGis.midblockGeoIdName,
    //                                         label: "Geo ID",

    //                                     },
    //                                     {
    //                                         fieldName: "postedSpeed",
    //                                         label: "Posted Speed",

    //                                     },

    //                                 ]
    //                             }
    //                         ]
    //                     },
    //                     renderer: renderer
    //                 });



    //                 const expand = new Expand({
    //                     content: new Legend({
    //                         view: view,
    //                         style: "card", // other styles include 'classic',
    //                         layerInfos: [
    //                             {
    //                                 layer: postedSpeedLayer
    //                             }
    //                         ]
    //                     }),
    //                     view: view,
    //                     expanded: true
    //                 });
    //                 _map.current.add(expand)
    //                 _map.current.add(postedSpeedLayer);
    //             }


    //             setShowLoading(false)
    //         })
    //             .catch(x => {
    //                 setShowLoading(false)
    //                 console.log(x)
    //             });
    //     })


    // });

    const showTrafficPostedSpeedByColor = useCallback((lstStudyLocations) => {

        // define the view here so it can be referenced in the clean up function
        loadModules(["esri/core/reactiveUtils", "esri/layers/FeatureLayer", "esri/layers/support/AggregateField", "esri/Graphic", "esri/geometry/Point", "esri/symbols/SimpleMarkerSymbol", "esri/renderers/UniqueValueRenderer",
            "esri/geometry/support/webMercatorUtils", "esri/widgets/Legend", "esri/widgets/Expand", "esri/renderers/ClassBreaksRenderer"], {
            css: true,
        }).then(([reactiveUtils, FeatureLayer, AggregateField, Graphic, Point, SimpleMarkerSymbol, UniqueValueRenderer, webMercatorUtils, Legend, Expand, ClassBreaksRenderer]) => {

            if (!viewIsLoaded || lstStudyLocations.length === 0) return;
            var lstGraphics = []
            const roadsegmentLayer = _roadSegmentLayer.current;
            const view = _view.current;

            // roadsegmentLayer.load().then(() => {
            //     // Set the view extent to the data extent
            //     view.extent = roadsegmentLayer.fullExtent;
            //     setExtentIsFull(true);
            // });

            var strRoadSegmentQuery = initDataGis.midblockGeoIdName + " In (";
            if (lstStudyLocations) {
                lstStudyLocations.filter(x => x.locationType === LocationType.Midblock).forEach((s) => {
                    strRoadSegmentQuery +=
                        initDataGis.midblockGeoIdType === FieldType.String
                            ? "'" + s.geoId + "',"
                            : s.geoId + ",";
                });
                strRoadSegmentQuery = strRoadSegmentQuery.substring(
                    0,
                    strRoadSegmentQuery.length - 1
                );
                strRoadSegmentQuery += ")"
            }
            if (strRoadSegmentQuery === "") {
                return;
            }
            const roadSegmentQuery = {
                outFields: ["*"],
                where: strRoadSegmentQuery,
                returnGeometry: true,
            };


            // queries when view and layer has been loaded
            roadsegmentLayer.queryFeatures(roadSegmentQuery).then(function (RoadSegmentResults) {
                var graphics = RoadSegmentResults.features;
                if (strRoadSegmentQuery !== "") {
                    for (let index = 0; index < graphics.length; index++) {
                        graphics[index].attributes["postedSpeed"] = lstStudyLocations.find(x => x.geoId == graphics[index].attributes[initDataGis.midblockGeoIdName])?.postedSpeed
                    }
                    lstGraphics = [...lstGraphics, ...graphics]
                }

                if (trafficToMapReq.showPostedSpeedByColor === true) {
                    //create the class based renderer
                    // var rendererByColor = new ClassBreaksRenderer({
                    //     field: "postedSpeed",
                    //     classBreakInfos: [
                    //         {
                    //             minValue: 0,
                    //             maxValue: 40,
                    //             symbol: {
                    //                 type: "simple-line",
                    //                 color: "#FFFF00",
                    //                 width: "15px",
                    //             },
                    //             label: "0-40"
                    //         },
                    //         {
                    //             minValue: 41,
                    //             maxValue: 50,
                    //             symbol: {
                    //                 type: "simple-line",
                    //                 color: "#00D40E",
                    //                 width: "6px",
                    //             },
                    //             label: "40-50"
                    //         },

                    //     ]
                    // });
                    //create the layer, assign the renderer to it
                    var postedSpeedLayerByColor = new FeatureLayer({
                        id: "PostedSpeedByColor",
                        source: lstGraphics,
                        objectIdField: "ObjectId",
                        outFields: ["*"],
                        fields: [
                            {
                                alias: "Posted Speed By Color",
                                defaultValue: null,
                                editable: true,
                                length: -1,
                                name: "postedSpeed",
                                nullable: true,
                                type: "integer"
                            },
                            {
                                alias: initDataGis.midblockGeoIdName,
                                defaultValue: null,
                                editable: true,
                                length: -1,
                                name: initDataGis.midblockGeoIdName,
                                nullable: false,
                                type: "string"
                            },
                        ],
                        renderer: {
                            type: "class-breaks", // autocasts as new ClassBreaksRenderer()
                            field: "postedSpeed",
                            defaultSymbol: {
                                type: "simple-line",
                                color: "black",
                                width: "5px",
                            },
                            defaultLabel: "no data",
                            classBreakInfos: [
                                {
                                    minValue: 1,
                                    maxValue: 30,
                                    symbol: {
                                        type: "simple-line",
                                        color: "#ffff00",
                                        width: "8px",

                                    },
                                    label: "0-30"
                                },
                                {
                                    minValue: 31,
                                    maxValue: 40,
                                    symbol: {
                                        type: "simple-line",
                                        color: "#ffda00",
                                        width: "6px",
                                    },
                                    label: "30-40"
                                },
                                {
                                    minValue: 41,
                                    maxValue: 50,
                                    symbol: {
                                        type: "simple-line",
                                        color: "#ffb500",
                                        width: "6px",
                                    },
                                    label: "40-50"
                                },
                                {
                                    minValue: 51,
                                    maxValue: 60,
                                    symbol: {
                                        type: "simple-line",
                                        color: "#ff9125",
                                        width: "6px",

                                    },
                                    label: "50-60"
                                },
                                {
                                    minValue: 61,
                                    maxValue: 70,
                                    symbol: {
                                        type: "simple-line",
                                        color: "#ff6e39",
                                        width: "6px",
                                    },
                                    label: "60-70"
                                },
                                {
                                    minValue: 71,
                                    maxValue: 80,
                                    symbol: {
                                        type: "simple-line",
                                        color: "#f04d48",
                                        width: "6px",
                                    },
                                    label: "70-80"
                                },
                                {
                                    minValue: 81,
                                    maxValue: 90,
                                    symbol: {
                                        type: "simple-line",
                                        color: "#d62f54",
                                        width: "6px",
                                    },
                                    label: "80-90"
                                },
                                {
                                    minValue: 91,
                                    maxValue: 100,
                                    symbol: {
                                        type: "simple-line",
                                        color: "#b7145b",
                                        width: "6px",
                                    },
                                    label: "90-100"
                                },
                                {
                                    minValue: 101,
                                    maxValue: 110,
                                    symbol: {
                                        type: "simple-line",
                                        color: "#93035e",
                                        width: "6px",
                                    },
                                    label: "100-110"
                                },
                                {
                                    minValue: 111,
                                    maxValue: 120,
                                    symbol: {
                                        type: "simple-line",
                                        color: "#6c045c",
                                        width: "6px",
                                    },
                                    label: "110-120"
                                },
                            ]
                        },

                        // popupTemplates can still be viewed on
                        // individual features
                        popupTemplate: {
                            title: "Posted Speed",
                            content: [
                                {
                                    type: "fields",
                                    fieldInfos: [
                                        {
                                            fieldName: initDataGis.midblockGeoIdName,
                                            label: "Geo ID",

                                        },
                                        {
                                            fieldName: "postedSpeed",
                                            label: "Posted Speed",
                                        },

                                    ]
                                }
                            ]
                        },

                    });

                    _map.current.add(postedSpeedLayerByColor);
                }


                const legend = new Expand({
                    content: new Legend({
                        view: view,
                        style: "card", // other styles include 'classic',
                        layerInfos: [
                            {
                                layer: postedSpeedLayerByColor
                            }
                        ]
                    }),
                    expanded: true
                });

                view.ui.add(legend, "bottom-left");
                _legend.current = legend;

                setShowLoading(false)
            })
                .catch(x => {
                    setShowLoading(false)
                    console.log(x)
                });

        })
            .catch(x => {
                setShowLoading(false)
                console.log(x)
            });
    });

    const showTrafficAverageSpeedByColor = useCallback((lstStudyLocations) => {

        // define the view here so it can be referenced in the clean up function
        loadModules(["esri/core/reactiveUtils", "esri/layers/FeatureLayer", "esri/layers/support/AggregateField", "esri/Graphic", "esri/geometry/Point", "esri/symbols/SimpleMarkerSymbol", "esri/renderers/UniqueValueRenderer",
            "esri/geometry/support/webMercatorUtils", "esri/widgets/Legend", "esri/widgets/Expand", "esri/renderers/ClassBreaksRenderer"], {
            css: true,
        }).then(([reactiveUtils, FeatureLayer, AggregateField, Graphic, Point, SimpleMarkerSymbol, UniqueValueRenderer, webMercatorUtils, Legend, Expand, ClassBreaksRenderer]) => {

            if (!viewIsLoaded || lstStudyLocations.length === 0) return;
            var lstGraphics = []
            const roadsegmentLayer = _roadSegmentLayer.current;
            const view = _view.current;

            // roadsegmentLayer.load().then(() => {
            //     // Set the view extent to the data extent
            //     view.extent = roadsegmentLayer.fullExtent;
            //     setExtentIsFull(true);
            // });

            var strRoadSegmentQuery = initDataGis.midblockGeoIdName + " In (";
            if (lstStudyLocations) {
                lstStudyLocations.filter(x => x.locationType === LocationType.Midblock).forEach((s) => {
                    strRoadSegmentQuery +=
                        initDataGis.midblockGeoIdType === FieldType.String
                            ? "'" + s.geoId + "',"
                            : s.geoId + ",";
                });
                strRoadSegmentQuery = strRoadSegmentQuery.substring(
                    0,
                    strRoadSegmentQuery.length - 1
                );
                strRoadSegmentQuery += ")"
            }
            if (strRoadSegmentQuery === "") {
                return;
            }
            const roadSegmentQuery = {
                outFields: ["*"],
                where: strRoadSegmentQuery,
                returnGeometry: true,
            };


            // queries when view and layer has been loaded
            roadsegmentLayer.queryFeatures(roadSegmentQuery).then(function (RoadSegmentResults) {
                var graphics = RoadSegmentResults.features;
                if (strRoadSegmentQuery !== "") {
                    for (let index = 0; index < graphics.length; index++) {
                        graphics[index].attributes["averageSpeed"] = lstStudyLocations.find(x => x.geoId == graphics[index].attributes[initDataGis.midblockGeoIdName])?.averageSpeed
                    }
                    lstGraphics = [...lstGraphics, ...graphics]
                }

                if (trafficToMapReq.showAverageSpeedByColor === true) {
                    //create the renderer
                    //create the layer, assign the renderer to it
                    var averageSpeedLayerByColor = new FeatureLayer({
                        id: "AverageSpeedByColor",
                        source: lstGraphics,
                        objectIdField: "ObjectId",
                        outFields: ["*"],
                        fields: [
                            {
                                alias: "Average Speed By Color",
                                defaultValue: null,
                                editable: true,
                                length: -1,
                                name: "averageSpeed",
                                nullable: true,
                                type: "double"
                            },
                            {
                                alias: initDataGis.midblockGeoIdName,
                                defaultValue: null,
                                editable: true,
                                length: -1,
                                name: initDataGis.midblockGeoIdName,
                                nullable: false,
                                type: "string"
                            },
                        ],
                        renderer: {
                            type: "class-breaks", // autocasts as new ClassBreaksRenderer()
                            field: "averageSpeed",
                            defaultSymbol: {
                                type: "simple-line",
                                color: "black",
                                width: "5px",
                            },
                            defaultLabel: "no data",
                            classBreakInfos: [
                                {
                                    minValue: 1,
                                    maxValue: 30,
                                    symbol: {
                                        type: "simple-line",
                                        color: "#ffff00",
                                        width: "8px",
                                    },
                                    label: "0-30"
                                },
                                {
                                    minValue: 31,
                                    maxValue: 40,
                                    symbol: {
                                        type: "simple-line",
                                        color: "#ffda00",
                                        width: "6px",
                                    },
                                    label: "30-40"
                                },
                                {
                                    minValue: 41,
                                    maxValue: 50,
                                    symbol: {
                                        type: "simple-line",
                                        color: "#ffb500",
                                        width: "6px",
                                    },
                                    label: "40-50"
                                },
                                {
                                    minValue: 51,
                                    maxValue: 60,
                                    symbol: {
                                        type: "simple-line",
                                        color: "#ff9125",
                                        width: "6px",
                                    },
                                    label: "50-60"
                                },
                                {
                                    minValue: 61,
                                    maxValue: 70,
                                    symbol: {
                                        type: "simple-line",
                                        color: "#ff6e39",
                                        width: "6px",
                                    },
                                    label: "60-70"
                                },
                                {
                                    minValue: 71,
                                    maxValue: 80,
                                    symbol: {
                                        type: "simple-line",
                                        color: "#f04d48",
                                        width: "6px",
                                    },
                                    label: "70-80"
                                },
                                {
                                    minValue: 81,
                                    maxValue: 90,
                                    symbol: {
                                        type: "simple-line",
                                        color: "#d62f54",
                                        width: "6px",
                                    },
                                    label: "80-90"
                                },
                                {
                                    minValue: 91,
                                    maxValue: 100,
                                    symbol: {
                                        type: "simple-line",
                                        color: "#b7145b",
                                        width: "6px",
                                    },
                                    label: "90-100"
                                },
                                {
                                    minValue: 101,
                                    maxValue: 110,
                                    symbol: {
                                        type: "simple-line",
                                        color: "#93035e",
                                        width: "6px",
                                    },
                                    label: "100-110"
                                },
                                {
                                    minValue: 111,
                                    maxValue: 120,
                                    symbol: {
                                        type: "simple-line",
                                        color: "#6c045c",
                                        width: "6px",
                                    },
                                    label: "110-120"
                                },
                            ]
                        },

                        // popupTemplates can still be viewed on
                        // individual features
                        popupTemplate: {
                            title: "Average Speed",
                            content: [
                                {
                                    type: "fields",
                                    fieldInfos: [
                                        {
                                            fieldName: initDataGis.midblockGeoIdName,
                                            label: "Geo ID",

                                        },
                                        {
                                            fieldName: "averageSpeed",
                                            label: "Average Speed",
                                        },

                                    ]
                                }
                            ]
                        },


                    });

                    const legend = new Expand({
                        content: new Legend({
                            view: view,
                            style: "card", // other styles include 'classic',
                            layerInfos: [
                                {
                                    layer: averageSpeedLayerByColor
                                }
                            ]
                        }),
                        view: view,
                        expanded: true
                    });

                    view.ui.add(legend, "bottom-left");
                    _legend.current = legend;
                    _map.current.add(averageSpeedLayerByColor);
                }
                setShowLoading(false)
            })
                .catch(x => {
                    setShowLoading(false)
                    console.log(x)
                });

        })
            .catch(x => {
                setShowLoading(false)
                console.log(x)
            });
    });

    const showTrafficCompliance = useCallback((lstStudyLocations) => {

        // define the view here so it can be referenced in the clean up function
        loadModules(["esri/core/reactiveUtils", "esri/layers/FeatureLayer", "esri/layers/support/AggregateField", "esri/Graphic", "esri/geometry/Point", "esri/symbols/SimpleMarkerSymbol", "esri/renderers/UniqueValueRenderer",
            "esri/geometry/support/webMercatorUtils", "esri/widgets/Legend", "esri/widgets/Expand", "esri/renderers/ClassBreaksRenderer"], {
            css: true,
        }).then(([reactiveUtils, FeatureLayer, AggregateField, Graphic, Point, SimpleMarkerSymbol, UniqueValueRenderer, webMercatorUtils, Legend, Expand, ClassBreaksRenderer]) => {

            if (!viewIsLoaded || lstStudyLocations.length === 0) return;
            var lstGraphics = []
            const roadsegmentLayer = _roadSegmentLayer.current;
            const view = _view.current;

            // roadsegmentLayer.load().then(() => {
            //     // Set the view extent to the data extent
            //     view.extent = roadsegmentLayer.fullExtent;
            //     setExtentIsFull(true);
            // });

            var strRoadSegmentQuery = initDataGis.midblockGeoIdName + " In (";
            if (lstStudyLocations) {
                lstStudyLocations.filter(x => x.locationType === LocationType.Midblock).forEach((s) => {
                    strRoadSegmentQuery +=
                        initDataGis.midblockGeoIdType === FieldType.String
                            ? "'" + s.geoId + "',"
                            : s.geoId + ",";
                });
                strRoadSegmentQuery = strRoadSegmentQuery.substring(
                    0,
                    strRoadSegmentQuery.length - 1
                );
                strRoadSegmentQuery += ")"
            }
            if (strRoadSegmentQuery === "") {
                return;
            }
            const roadSegmentQuery = {
                outFields: ["*"],
                where: strRoadSegmentQuery,
                returnGeometry: true,
            };


            // queries when view and layer has been loaded
            roadsegmentLayer.queryFeatures(roadSegmentQuery).then(function (RoadSegmentResults) {
                var graphics = RoadSegmentResults.features;
                if (strRoadSegmentQuery !== "") {
                    for (let index = 0; index < graphics.length; index++) {
                        graphics[index].attributes["compliance"] = lstStudyLocations.find(x => x.geoId == graphics[index].attributes[initDataGis.midblockGeoIdName])?.compliance
                    }
                    lstGraphics = [...lstGraphics, ...graphics]
                }

                if (trafficToMapReq.showCompliance === true) {
                    //create the renderer
                    //create the layer, assign the renderer to it
                    var complianceLayer = new FeatureLayer({
                        id: "ComplianceLayer",
                        source: lstGraphics,
                        objectIdField: "ObjectId",
                        title: "Compliance",
                        outFields: ["*"],
                        fields: [
                            {
                                alias: "Compliance",
                                defaultValue: null,
                                editable: true,
                                length: -1,
                                name: "compliance",
                                nullable: true,
                                type: "double"
                            },
                            {
                                alias: initDataGis.midblockGeoIdName,
                                defaultValue: null,
                                editable: true,
                                length: -1,
                                name: initDataGis.midblockGeoIdName,
                                nullable: false,
                                type: "string"
                            },
                        ],
                        renderer: {
                            type: "class-breaks", // autocasts as new ClassBreaksRenderer()
                            field: "compliance",
                            defaultSymbol: {
                                type: "simple-line",
                                color: "black",
                                width: "5px",
                            },
                            defaultLabel: "no data",
                            classBreakInfos: [
                                {
                                    minValue: 0,
                                    maxValue: 50,
                                    symbol: {
                                        type: "simple-line",
                                        color: "#8B4000",
                                        width: "6px",
                                    },
                                    label: "< 50%"
                                },
                                {
                                    minValue: 50,
                                    maxValue: 60,
                                    symbol: {
                                        type: "simple-line",
                                        color: "#ff0000",
                                        width: "6px",
                                    },
                                    label: "50% - 60%"
                                },
                                {
                                    minValue: 60,
                                    maxValue: 70,
                                    symbol: {
                                        type: "simple-line",
                                        color: "#FFA500",
                                        width: "6px",
                                    },
                                    label: "60% - 70%"
                                },
                                {
                                    minValue: 70,
                                    maxValue: 80,
                                    symbol: {
                                        type: "simple-line",
                                        color: "#F6BE00",
                                        width: "6px",
                                    },
                                    label: "70% - 80%"
                                },
                                {
                                    minValue: 80,
                                    maxValue: 90,
                                    symbol: {
                                        type: "simple-line",
                                        color: "#FFFF00",
                                        width: "6px",
                                    },
                                    label: "80% - 90%"
                                },
                                {
                                    minValue: 90,
                                    maxValue: 100,
                                    symbol: {
                                        type: "simple-line",
                                        color: "#00FF00",
                                        width: "6px",
                                    },
                                    label: "90% - 100%"
                                },

                            ]
                        },

                        // popupTemplates can still be viewed on
                        // individual features
                        popupTemplate: {
                            title: "Compliance",
                            content: [
                                {

                                    type: "fields",
                                    fieldInfos: [
                                        {
                                            fieldName: initDataGis.midblockGeoIdName,
                                            label: "Geo ID",

                                        },
                                        {
                                            fieldName: "compliance",
                                            label: "Compliance",
                                        },

                                    ]
                                }
                            ]
                        },


                    });

                    const legend = new Expand({
                        content: new Legend({
                            view: view,
                            style: "card", // other styles include 'classic',
                            layerInfos: [
                                {
                                    layer: complianceLayer
                                }
                            ]
                        }),
                        view: view,
                        expanded: true
                    });
                    _legend.current = legend;
                    view.ui.add(legend, "bottom-left");
                    _map.current.add(complianceLayer);
                }
                setShowLoading(false)
            })
                .catch(x => {
                    setShowLoading(false)
                    console.log(x)
                });

        })
            .catch(x => {
                setShowLoading(false)
                console.log(x)
            });
    });

    const showTrafficAverageSpeed = useCallback((lstStudyLocations) => {
        // define the view here so it can be referenced in the clean up function
        loadModules(["esri/core/reactiveUtils", "esri/layers/FeatureLayer", "esri/layers/support/AggregateField", "esri/Graphic", "esri/geometry/Point", "esri/symbols/SimpleMarkerSymbol", "esri/renderers/UniqueValueRenderer",
            "esri/geometry/support/webMercatorUtils", "esri/widgets/Legend"], {
            css: true,
        }).then(([reactiveUtils, FeatureLayer, AggregateField, Graphic, Point, SimpleMarkerSymbol, UniqueValueRenderer, webMercatorUtils, Legend]) => {

            if (!viewIsLoaded || lstStudyLocations.length === 0) return;
            var lstGraphics = []
            const roadsegmentLayer = _roadSegmentLayer.current;

            // roadsegmentLayer.load().then(() => {
            //     // Set the view extent to the data extent
            //     view.extent = roadsegmentLayer.fullExtent;
            //     setExtentIsFull(true);
            // });

            var strRoadSegmentQuery = initDataGis.midblockGeoIdName + " In (";
            if (lstStudyLocations) {
                lstStudyLocations.filter(x => x.locationType === LocationType.Midblock && x.averageSpeed > 0).forEach((s) => {
                    strRoadSegmentQuery +=
                        initDataGis.midblockGeoIdType === FieldType.String
                            ? "'" + s.geoId + "',"
                            : s.geoId + ",";
                });
                strRoadSegmentQuery = strRoadSegmentQuery.substring(
                    0,
                    strRoadSegmentQuery.length - 1
                );
                strRoadSegmentQuery += ")"
            }
            if (strRoadSegmentQuery === "") {
                return;
            }
            const roadSegmentQuery = {
                outFields: ["*"],
                where: strRoadSegmentQuery,
                returnGeometry: true,
            };


            // queries when view and layer has been loaded
            roadsegmentLayer.queryFeatures(roadSegmentQuery).then(function (RoadSegmentResults) {
                var graphics = RoadSegmentResults.features;
                var lstNewRoadSegmentGraphics = graphics.filter(x => x.geometry.paths.length > 0).map(function (place) {
                    var paths = place.geometry.paths[0];
                    const res = getAveragePaths(paths)
                    var [lng, lat] = webMercatorUtils.xyToLngLat(res[0], res[1]);
                    var webMercatorCoords = webMercatorUtils.lngLatToXY(lng, lat);
                    return new Graphic({
                        attributes: {
                            averageSpeed: lstStudyLocations.find(x => x.geoId == place.attributes[initDataGis.midblockGeoIdName]).averageSpeed,
                        },
                        geometry: {
                            type: 'point',
                            x: webMercatorCoords[0],
                            y: webMercatorCoords[1],
                            spatialReference: { wkid: place.geometry?.spatialReference?.wkid }
                        }
                    });

                });

                lstGraphics = [...lstGraphics, ...lstNewRoadSegmentGraphics]

                if (trafficToMapReq.showAverageSpeed === true) {

                    const averageSpeed = "Round($feature.averageSpeed) + ' Km/h';";
                    const averageSpeedClass = {
                        labelExpressionInfo: {
                            expression: averageSpeed
                        },
                        labelPlacement: "above-center",
                    };
                    averageSpeedClass.symbol = createTextSymbol("#424242");

                    var averageSpeedLayer = new FeatureLayer({
                        id: "AverageSpeedLayer",
                        source: lstGraphics,
                        objectIdField: "ObjectId",
                        title: "Average Speed",
                        outFields: ["*"],
                        fields: [
                            {
                                alias: "Average Speed",
                                defaultValue: null,
                                editable: true,
                                length: -1,
                                name: "averageSpeed",
                                nullable: true,
                                type: "double"
                            },


                        ],
                        labelingInfo: [
                            averageSpeedClass
                        ],
                        renderer: {
                            type: "simple",  // autocasts as new SimpleRenderer()
                            symbol: {
                                type: "simple-marker",  // autocasts as new SimpleMarkerSymbol()
                                size: 0,
                            }
                        },

                        // popupTemplates can still be viewed on
                        // individual features
                        popupTemplate: {
                            title: "Average Speed",
                            content: [
                                {
                                    type: "fields",
                                    fieldInfos: [
                                        {
                                            fieldName: initDataGis.midblockGeoIdName,
                                            label: "Geo ID",

                                        },
                                        {
                                            fieldName: "averageSpeed",
                                            label: "Average Speed"
                                        },

                                    ]
                                }
                            ]
                        },

                    });
                    _map.current.add(averageSpeedLayer);
                }
                setShowLoading(false)
            })

        })
            .catch(x => {
                setShowLoading(false)
                console.log(x)
            });
    });

    const showTrafficRoadSegmentAADT = useCallback((lstStudyLocations) => {
        // define the view here so it can be referenced in the clean up function
        loadModules(["esri/core/reactiveUtils", "esri/layers/FeatureLayer", "esri/layers/support/AggregateField", "esri/Graphic", "esri/geometry/Point", "esri/symbols/SimpleMarkerSymbol", "esri/renderers/UniqueValueRenderer",
            "esri/geometry/support/webMercatorUtils", "esri/widgets/Legend", "esri/widgets/Expand", "esri/renderers/ClassBreaksRenderer"], {
            css: true,
        }).then(([reactiveUtils, FeatureLayer, AggregateField, Graphic, Point, SimpleMarkerSymbol, UniqueValueRenderer, webMercatorUtils, Legend, Expand, ClassBreaksRenderer]) => {

            if (!viewIsLoaded || lstStudyLocations.length === 0) return;
            var lstGraphics = []
            const roadsegmentLayer = _roadSegmentLayer.current;
            const view = _view.current;

            // roadsegmentLayer.load().then(() => {
            //     // Set the view extent to the data extent
            //     view.extent = roadsegmentLayer.fullExtent;
            //     setExtentIsFull(true);
            // });

            var strRoadSegmentQuery = initDataGis.midblockGeoIdName + " In (";
            if (lstStudyLocations) {
                lstStudyLocations.filter(x => x.locationType === LocationType.Midblock).forEach((s) => {
                    strRoadSegmentQuery +=
                        initDataGis.midblockGeoIdType === FieldType.String
                            ? "'" + s.geoId + "',"
                            : s.geoId + ",";
                });
                strRoadSegmentQuery = strRoadSegmentQuery.substring(
                    0,
                    strRoadSegmentQuery.length - 1
                );
                strRoadSegmentQuery += ")"
                console.log("strRoadSegmentQuery " + strRoadSegmentQuery)
            }
            if (strRoadSegmentQuery === "") {
                return;
            }
            const roadSegmentQuery = {
                outFields: ["*"],
                where: strRoadSegmentQuery,
                returnGeometry: true,
            };

            // queries when view and layer has been loaded
            roadsegmentLayer.queryFeatures(roadSegmentQuery).then(function (RoadSegmentResults) {
                var graphics = RoadSegmentResults.features;
                if (strRoadSegmentQuery !== "") {
                    for (let index = 0; index < graphics.length; index++) {
                        graphics[index].attributes["roadSegmentAADT"] = lstStudyLocations.find(x => x.geoId == graphics[index].attributes[initDataGis.midblockGeoIdName])?.roadSegmentAADT
                    }
                    lstGraphics = [...lstGraphics, ...graphics]
                }
                if (trafficToMapReq.showRoadSegmentAADT === true) {
                    var roadSegmentAADTLayer = new FeatureLayer({
                        id: "RoadSegmentAADTLayer",
                        source: lstGraphics,
                        editable: true,
                        objectIdField: "ObjectId",
                        outFields: ["*"],
                        fields: [
                            {
                                alias: "RoadSegment AADT",
                                defaultValue: null,
                                editable: true,
                                length: -1,
                                name: "roadSegmentAADT",
                                nullable: true,
                                type: "integer"
                            },
                            {
                                alias: initDataGis.midblockGeoIdName,
                                defaultValue: null,
                                editable: true,
                                length: -1,
                                name: initDataGis.midblockGeoIdName,
                                nullable: false,
                                type: "string"
                            },
                        ],
                        // popupTemplates can still be viewed on
                        // individual features
                        popupTemplate: {
                            title: "Road Segment AADT",
                            content: [
                                {
                                    type: "fields",
                                    fieldInfos: [

                                        {
                                            fieldName: initDataGis.midblockGeoIdName,
                                            label: "Geo ID"
                                        },
                                        {
                                            fieldName: "roadSegmentAADT",
                                            label: "Total"
                                        }

                                    ]
                                }
                            ]
                        },
                        renderer: {
                            type: "class-breaks", // autocasts as new ClassBreaksRenderer()
                            field: "roadSegmentAADT",
                            // defaultSymbol: {
                            //     type: "simple-line",
                            //     color: "black",
                            //     width: "5px",
                            // },
                            // defaultLabel: "no data",
                            classBreakInfos: [
                                {
                                    minValue: 1,
                                    maxValue: 1000,
                                    symbol: {
                                        type: "simple-line",
                                        color: "#00ff00",
                                        width: "4px",
                                    },
                                    label: "> 0"
                                },
                                {
                                    minValue: 1001,
                                    maxValue: 5000,
                                    symbol: {
                                        type: "simple-line",
                                        color: "#FFFF00",
                                        width: "6px",
                                    },
                                    label: "> 1000"
                                },
                                {
                                    minValue: 5001,
                                    maxValue: 10000,
                                    symbol: {
                                        type: "simple-line",
                                        color: "#ee8200",
                                        width: "10px",
                                    },
                                    label: "> 5000"
                                },
                                {
                                    minValue: 10001,
                                    maxValue: 20000,
                                    symbol: {
                                        type: "simple-line",
                                        color: "#ff0000",
                                        width: "13px",
                                    },
                                    label: "> 10000"
                                },
                                {
                                    minValue: 20001,
                                    maxValue: 500000,
                                    symbol: {
                                        type: "simple-line",
                                        color: "#8B4000",
                                        width: "13px",
                                    },
                                    label: "> 20000"
                                },
                            ]
                        }

                    });



                    const legend = new Expand({
                        content: new Legend({
                            view: view,
                            style: "card", // other styles include 'classic',
                            layerInfos: [
                                {
                                    layer: roadSegmentAADTLayer
                                }
                            ]
                        }),
                        view: view,
                        expanded: true
                    });

                    view.ui.add(legend, "bottom-left");
                    _map.current.add(roadSegmentAADTLayer);
                    _legend.current = legend;


                }

                setShowLoading(false)
            })
                .catch(x => {
                    setShowLoading(false)
                    console.log(x)
                });

        })
            .catch(x => {
                setShowLoading(false)
                console.log(x)
            });
    });


    const showTrafficIntersectionAADTs = useCallback((lstStudyLocations) => {
        // define the view here so it can be referenced in the clean up function
        loadModules(["esri/core/reactiveUtils", "esri/layers/FeatureLayer", "esri/layers/support/AggregateField", "esri/Graphic", "esri/geometry/Point", "esri/symbols/SimpleMarkerSymbol", "esri/renderers/UniqueValueRenderer",
            "esri/geometry/support/webMercatorUtils", "esri/widgets/Legend", "esri/widgets/Expand", "esri/renderers/ClassBreaksRenderer"], {
            css: true,
        }).then(([reactiveUtils, FeatureLayer, AggregateField, Graphic, Point, SimpleMarkerSymbol, UniqueValueRenderer, webMercatorUtils, Legend, Expand, ClassBreaksRenderer]) => {


            if (!viewIsLoaded || lstStudyLocations.length === 0) return;
            const view = _view.current;


            var lstGraphics = []
            const intersectionLayer = _intersectionLayer.current;

            var strIntersectionQuery = initDataGis.intersectionGeoIdName + " In (";
            if (lstStudyLocations) {
                lstStudyLocations.filter(x => x.locationType === LocationType.Intersection).forEach((s) => {
                    strIntersectionQuery +=
                        initDataGis.intersectionGeoIdType === FieldType.String
                            ? "'" + s.geoId + "',"
                            : s.geoId + ",";
                });
                strIntersectionQuery = strIntersectionQuery.substring(
                    0,
                    strIntersectionQuery.length - 1
                );
                strIntersectionQuery += ")"
            }
            if (strIntersectionQuery === "") {
                return;
            }
            const intersectionQuery = {
                outFields: ["*"],
                where: strIntersectionQuery,
                returnGeometry: true,
            };


            // queries when view and layer has been loaded
            intersectionLayer.queryFeatures(intersectionQuery).then(function (IntersectionResults) {
                var graphics = IntersectionResults.features;
                if (strIntersectionQuery !== "") {
                    for (let index = 0; index < graphics.length; index++) {
                        graphics[index].attributes["intersectionAADT"] = lstStudyLocations.find(x => x.geoId == graphics[index].attributes[initDataGis.intersectionGeoIdName])?.intersectionAADT
                        graphics[index].attributes["location"] = lstStudyLocations.find(x => x.geoId == graphics[index].attributes[initDataGis.intersectionGeoIdName])?.Location
                    }
                    lstGraphics = [...lstGraphics, ...graphics]
                }
                if (trafficToMapReq.showIntersectionAADT === true) {
                    var intersectionAADTLayer = new FeatureLayer({
                        id: "IntersectionAADTLayer",
                        source: lstGraphics,
                        objectIdField: "ObjectId",
                        editable: true,
                        outFields: ["*"],
                        fields: [
                            {
                                defaultValue: null,
                                editable: true,
                                length: -1,
                                alias: "Intersection AADT",
                                name: "intersectionAADT",
                                nullable: false,
                                type: "integer"
                            },

                            {
                                alias: initDataGis.intersectionGeoIdName,
                                defaultValue: null,
                                editable: true,
                                length: -1,
                                name: initDataGis.intersectionGeoIdName,
                                nullable: false,
                                type: "string"
                            },


                            // {
                            //     alias: "location",
                            //     defaultValue: null,
                            //     editable: true,
                            //     length: -1,
                            //     name: "location",
                            //     nullable: false,
                            //     type: "string"
                            // },

                        ],
                        // popupTemplates can still be viewed on
                        // individual features
                        popupTemplate: {
                            title: "Intersection AADT",
                            content: [
                                {
                                    type: "fields",
                                    fieldInfos: [
                                        {
                                            fieldName: initDataGis.intersectionGeoIdName,
                                            label: "Geo ID",
                                        },
                                        {
                                            fieldName: "intersectionAADT",
                                            label: "Total"
                                        }

                                    ]
                                }
                            ]
                        },
                        renderer: {
                            type: "class-breaks",
                            field: "intersectionAADT",
                            // defaultSymbol: {
                            //     type: "simple-line",
                            //     color: "black",
                            //     size: "5px",
                            // },
                            // defaultLabel: "no data",
                            classBreakInfos: [
                                {
                                    minValue: 1,
                                    maxValue: 1000,
                                    symbol: {
                                        type: "simple-marker",
                                        color: "#00ff00",
                                        width: "4px",
                                    },
                                    label: "> 0"
                                },
                                {
                                    minValue: 1001,
                                    maxValue: 5000,
                                    symbol: {
                                        type: "simple-marker",
                                        color: "#FFFF00",
                                        width: "6px",
                                    },
                                    label: "> 1000"
                                },
                                {
                                    minValue: 5001,
                                    maxValue: 10000,
                                    symbol: {
                                        type: "simple-marker",
                                        color: "#ee8200",
                                        width: "10px",
                                    },
                                    label: "> 5000"
                                },
                                {
                                    minValue: 10001,
                                    maxValue: 20000,
                                    symbol: {
                                        type: "simple-marker",
                                        color: "#ff0000",
                                        width: "13px",
                                    },
                                    label: "> 10000"
                                },
                                {
                                    minValue: 20001,
                                    maxValue: 500000,
                                    symbol: {
                                        type: "simple-marker",
                                        color: "#8B4000",
                                        width: "13px",
                                    },
                                    label: "> 20000"
                                },
                            ],
                            // view: _view
                        }

                    });


                    const legend = new Expand({
                        content: new Legend({
                            view: view,
                            style: "card", // other styles include 'classic',
                            layerInfos: [
                                {
                                    layer: intersectionAADTLayer
                                }
                            ]
                        }),
                        view: view,
                        expanded: true
                    });

                    view.ui.add(legend, "bottom-left");
                    _map.current.add(intersectionAADTLayer);
                    _legend.current = legend;
                }


                setShowLoading(false)
            })
                .catch(x => {
                    setShowLoading(false)
                    console.log(x)
                });

        })
            .catch(x => {
                setShowLoading(false)
                console.log(x)
            });
    });

    const showLatestSpeedStudy = useCallback((lstStudyLocations) => {
        // define the view here so it can be referenced in the clean up function
        loadModules(["esri/core/reactiveUtils", "esri/layers/FeatureLayer", "esri/layers/support/AggregateField", "esri/Graphic", "esri/geometry/Point", "esri/symbols/SimpleMarkerSymbol", "esri/renderers/UniqueValueRenderer",
            "esri/geometry/support/webMercatorUtils", "esri/widgets/Legend"], {
            css: true,
        }).then(([reactiveUtils, FeatureLayer, AggregateField, Graphic, Point, SimpleMarkerSymbol, UniqueValueRenderer, webMercatorUtils, Legend]) => {

            console.log("AAAA")

            if (!viewIsLoaded || lstStudyLocations.length === 0) return;
            var lstGraphics = []
            const roadsegmentLayer = _roadSegmentLayer.current;

            // roadsegmentLayer.load().then(() => {
            //     // Set the view extent to the data extent
            //     view.extent = roadsegmentLayer.fullExtent;
            //     setExtentIsFull(true);
            // });
            var strRoadSegmentQuery = initDataGis.midblockGeoIdName + " In (";
            if (lstStudyLocations) {
                lstStudyLocations.filter(x => x.locationType === LocationType.Midblock).forEach((s) => {
                    strRoadSegmentQuery +=
                        initDataGis.midblockGeoIdType === FieldType.String
                            ? "'" + s.geoId + "',"
                            : s.geoId + ",";
                });
                strRoadSegmentQuery = strRoadSegmentQuery.substring(
                    0,
                    strRoadSegmentQuery.length - 1
                );
                strRoadSegmentQuery += ")"
            }
            if (strRoadSegmentQuery === "") {
                return;
            }
            const roadSegmentQuery = {
                outFields: ["*"],
                where: strRoadSegmentQuery,
                returnGeometry: true,
            };
            // queries when view and layer has been loaded
            roadsegmentLayer.queryFeatures(roadSegmentQuery).then(function (RoadSegmentResults) {
                var graphics = RoadSegmentResults.features;
                var lstNewRoadSegmentGraphics = graphics.filter(x => x.geometry.paths.length > 0).map(function (place) {
                    var paths = place.geometry.paths[0];
                    const res = getAveragePaths(paths)

                    // var lineCenter = new Point(res[0], res[1], {wkid: 26917});
                    // var lt = webMercatorUtils.xyToLngLat(lineCenter.x, lineCenter.y)
                    var [lng, lat] = webMercatorUtils.xyToLngLat(res[0], res[1]);
                    var webMercatorCoords = webMercatorUtils.lngLatToXY(lng, lat);
                    return new Graphic({
                        attributes: {
                            [initDataGis.midblockGeoIdName]: place.attributes[initDataGis.midblockGeoIdName].toString(),
                            northApproachVolume: lstStudyLocations.find(x => x.geoId == place.attributes[initDataGis.midblockGeoIdName])?.volumeSummary["northApproachVolume"],
                            southApproachVolume: lstStudyLocations.find(x => x.geoId == place.attributes[initDataGis.midblockGeoIdName])?.volumeSummary["southApproachVolume"],
                            westApproachVolume: lstStudyLocations.find(x => x.geoId == place.attributes[initDataGis.midblockGeoIdName])?.volumeSummary["westApproachVolume"],
                            eastApproachVolume: lstStudyLocations.find(x => x.geoId == place.attributes[initDataGis.midblockGeoIdName])?.volumeSummary["eastApproachVolume"],
                            totalVolume: lstStudyLocations.find(x => x.geoId == place.attributes[initDataGis.midblockGeoIdName])?.volumeSummary["totalVolume"],
                            studyType: lstStudyLocations.find(x => x.geoId == place.attributes[initDataGis.midblockGeoIdName])?.studyType
                        },
                        geometry: {
                            type: 'point',
                            x: webMercatorCoords[0],
                            y: webMercatorCoords[1],
                            spatialReference: { wkid: place.geometry?.spatialReference?.wkid }
                        }
                    });

                });
                lstGraphics = [...lstGraphics, ...lstNewRoadSegmentGraphics]
                if (trafficToMapReq.showLatestSpeedStudy === true) {
                    var renderer = new UniqueValueRenderer({
                        field: "studyType",
                        uniqueValueInfos: [
                            {
                                value: StudyType.Speed,
                                symbol: {
                                    type: "picture-marker",
                                    url: "http://staticfile.tes.ca/gisMap/latestStudies/speed.png",
                                    width: "25px",
                                    height: "25px"
                                }
                            }
                        ]
                    }
                    );

                    var speedStudyLayer = new FeatureLayer({
                        id: "SpeedStudyLayer",
                        source: lstGraphics,
                        title: "Speed Study",
                        objectIdField: "ObjectId",
                        outFields: ["*"],
                        fields: [
                            {
                                alias: initDataGis.midblockGeoIdName,
                                defaultValue: null,
                                editable: true,
                                length: -1,
                                name: initDataGis.midblockGeoIdName,
                                nullable: false,
                                type: "string"
                            },
                            {
                                alias: "study Type",
                                defaultValue: null,
                                editable: true,
                                length: -1,
                                name: "studyType",
                                nullable: true,
                                type: "integer",
                            },
                            {
                                alias: "North Approach Volume",
                                defaultValue: null,
                                editable: true,
                                length: -1,
                                name: "northApproachVolume",
                                nullable: true,
                                type: "integer"
                            },
                            {
                                alias: "South Approach Volume",
                                defaultValue: null,
                                editable: true,
                                length: -1,
                                name: "southApproachVolume",
                                nullable: true,
                                type: "integer"
                            },
                            {
                                alias: "East Approach Volume",
                                defaultValue: null,
                                editable: true,
                                length: -1,
                                name: "eastApproachVolume",
                                nullable: true,
                                type: "integer"
                            },
                            {
                                alias: "West Approach Volume",
                                defaultValue: null,
                                editable: true,
                                length: -1,
                                name: "westApproachVolume",
                                nullable: true,
                                type: "integer"
                            },
                            {
                                alias: "Total Volume",
                                defaultValue: null,
                                editable: true,
                                length: -1,
                                name: "totalVolume",
                                nullable: true,
                                type: "integer"
                            },

                        ],
                        renderer: renderer,
                        // popupTemplates can still be viewed on
                        // individual features
                        popupTemplate: {
                            title: "Latest Speed Study",
                            content: [
                                {
                                    type: "fields",
                                    fieldInfos: [
                                        {
                                            fieldName: initDataGis.midblockGeoIdName,
                                            label: "Geo ID",

                                        },
                                        // {
                                        //     fieldName: "studyType",
                                        //     label: "Study Type",
                                        // },
                                        {
                                            fieldName: "northApproachVolume",
                                            label: "North Approach Volume",
                                        },
                                        {
                                            fieldName: "southApproachVolume",
                                            label: "South Approach Volume",
                                        },
                                        {
                                            fieldName: "eastApproachVolume",
                                            label: "East Approach Volume",
                                        },
                                        {
                                            fieldName: "westApproachVolume",
                                            label: "West Approach Volume",
                                        },
                                        {
                                            fieldName: "totalVolume",
                                            label: "Total Volume",
                                        },
                                    ]
                                }
                            ]
                        },
                    });
                    console.log("BBB")
                    _map.current.add(speedStudyLayer);
                }

                setShowLoading(false)
            })

        })
            .catch(x => {
                setShowLoading(false)
                console.log(x)
            });
    });

    const showLatestVolumeStudy = useCallback((lstStudyLocations) => {
        // define the view here so it can be referenced in the clean up function
        loadModules(["esri/core/reactiveUtils", "esri/layers/FeatureLayer", "esri/layers/support/AggregateField", "esri/Graphic", "esri/geometry/Point", "esri/symbols/SimpleMarkerSymbol", "esri/renderers/UniqueValueRenderer",
            "esri/geometry/support/webMercatorUtils", "esri/widgets/Legend"], {
            css: true,
        }).then(([reactiveUtils, FeatureLayer, AggregateField, Graphic, Point, SimpleMarkerSymbol, UniqueValueRenderer, webMercatorUtils, Legend]) => {

            if (!viewIsLoaded || lstStudyLocations.length === 0) return;
            var lstGraphics = []
            const roadsegmentLayer = _roadSegmentLayer.current;

            // roadsegmentLayer.load().then(() => {
            //     // Set the view extent to the data extent
            //     view.extent = roadsegmentLayer.fullExtent;
            //     setExtentIsFull(true);
            // });

            var strRoadSegmentQuery = initDataGis.midblockGeoIdName + " In (";
            if (lstStudyLocations) {
                lstStudyLocations.filter(x => x.locationType === LocationType.Midblock).forEach((s) => {
                    strRoadSegmentQuery +=
                        initDataGis.midblockGeoIdType === FieldType.String
                            ? "'" + s.geoId + "',"
                            : s.geoId + ",";
                });
                strRoadSegmentQuery = strRoadSegmentQuery.substring(
                    0,
                    strRoadSegmentQuery.length - 1
                );
                strRoadSegmentQuery += ")"
            }
            if (strRoadSegmentQuery === "") {
                return;
            }
            const roadSegmentQuery = {
                outFields: ["*"],
                where: strRoadSegmentQuery,
                returnGeometry: true,
            };

            // queries when view and layer has been loaded
            roadsegmentLayer.queryFeatures(roadSegmentQuery).then(function (RoadSegmentResults) {
                var graphics = RoadSegmentResults.features;
                var lstNewRoadSegmentGraphics = graphics.filter(x => x.geometry.paths.length > 0).map(function (place) {
                    var paths = place.geometry.paths[0];
                    const res = getAveragePaths(paths)
                    var [lng, lat] = webMercatorUtils.xyToLngLat(res[0], res[1]);
                    var webMercatorCoords = webMercatorUtils.lngLatToXY(lng, lat);
                    return new Graphic({
                        attributes: {
                            [initDataGis.midblockGeoIdName]: place.attributes[initDataGis.midblockGeoIdName].toString(),
                            northApproachVolume: lstStudyLocations.find(x => x.geoId == place.attributes[initDataGis.midblockGeoIdName])?.volumeSummary["northApproachVolume"],
                            southApproachVolume: lstStudyLocations.find(x => x.geoId == place.attributes[initDataGis.midblockGeoIdName])?.volumeSummary["southApproachVolume"],
                            westApproachVolume: lstStudyLocations.find(x => x.geoId == place.attributes[initDataGis.midblockGeoIdName])?.volumeSummary["westApproachVolume"],
                            eastApproachVolume: lstStudyLocations.find(x => x.geoId == place.attributes[initDataGis.midblockGeoIdName])?.volumeSummary["eastApproachVolume"],
                            totalVolume: lstStudyLocations.find(x => x.geoId == place.attributes[initDataGis.midblockGeoIdName])?.volumeSummary["totalVolume"],
                            studyType: lstStudyLocations.find(x => x.geoId == place.attributes[initDataGis.midblockGeoIdName])?.studyType
                        },
                        geometry: {
                            type: 'point',
                            x: webMercatorCoords[0],
                            y: webMercatorCoords[1],
                            spatialReference: { wkid: place.geometry?.spatialReference?.wkid }
                        }
                    });

                });

                lstGraphics = [...lstGraphics, ...lstNewRoadSegmentGraphics]


                if (trafficToMapReq.showLatestVolumeStudy === true) {
                    var volumeRenderer = new UniqueValueRenderer({
                        field: "studyType",
                        uniqueValueInfos: [
                            {
                                value: StudyType.Volume,
                                symbol: {
                                    type: "picture-marker",
                                    url: "http://staticfile.tes.ca/gisMap/latestStudies/car.png",
                                    width: "30px",
                                    height: "30px"
                                }
                            }
                        ]
                    }
                    );

                    var volumeStudyLayer = new FeatureLayer({
                        id: "VolumeStudyLayer",
                        source: lstGraphics,
                        title: "Volume Study",
                        objectIdField: "ObjectId",
                        outFields: ["*"],
                        fields: [
                            {
                                alias: initDataGis.midblockGeoIdName,
                                defaultValue: null,
                                editable: true,
                                length: -1,
                                name: initDataGis.midblockGeoIdName,
                                nullable: false,
                                type: "string"
                            },
                            {
                                alias: "study Type",
                                defaultValue: null,
                                editable: true,
                                length: -1,
                                name: "studyType",
                                nullable: true,
                                type: "integer"
                            },
                            {
                                alias: "North Approach Volume",
                                defaultValue: null,
                                editable: true,
                                length: -1,
                                name: "northApproachVolume",
                                nullable: true,
                                type: "integer"
                            },
                            {
                                alias: "South Approach Volume",
                                defaultValue: null,
                                editable: true,
                                length: -1,
                                name: "southApproachVolume",
                                nullable: true,
                                type: "integer"
                            },
                            {
                                alias: "East Approach Volume",
                                defaultValue: null,
                                editable: true,
                                length: -1,
                                name: "eastApproachVolume",
                                nullable: true,
                                type: "integer"
                            },
                            {
                                alias: "West Approach Volume",
                                defaultValue: null,
                                editable: true,
                                length: -1,
                                name: "westApproachVolume",
                                nullable: true,
                                type: "integer"
                            },
                            {
                                alias: "Total Volume",
                                defaultValue: null,
                                editable: true,
                                length: -1,
                                name: "totalVolume",
                                nullable: true,
                                type: "integer"
                            },

                        ],
                        // popupTemplates can still be viewed on
                        // individual features
                        popupTemplate: {
                            title: "Latest Volume Study",
                            content: [
                                {
                                    type: "fields",
                                    fieldInfos: [
                                        {
                                            fieldName: initDataGis.midblockGeoIdName,
                                            label: "Geo ID",

                                        },
                                        // {
                                        //     fieldName: "studyType",
                                        //     label: "Study Type"
                                        // },
                                        {
                                            fieldName: "northApproachVolume",
                                            label: "North Approach Volume",
                                        },
                                        {
                                            fieldName: "southApproachVolume",
                                            label: "South Approach Volume",
                                        },
                                        {
                                            fieldName: "eastApproachVolume",
                                            label: "East Approach Volume",
                                        },
                                        {
                                            fieldName: "westApproachVolume",
                                            label: "West Approach Volume",
                                        },
                                        {
                                            fieldName: "totalVolume",
                                            label: "Total Volume",
                                        },
                                    ]
                                }
                            ]
                        },
                        renderer: volumeRenderer,

                    });
                    _map.current.add(volumeStudyLayer);
                }
                setShowLoading(false)
            })

        })
            .catch(x => {
                setShowLoading(false)
                console.log(x)
            });
    });

    const showLatestTMCStudy = useCallback((lstStudyLocations) => {
        // define the view here so it can be referenced in the clean up function
        loadModules(["esri/core/reactiveUtils", "esri/layers/FeatureLayer", "esri/layers/support/AggregateField", "esri/Graphic", "esri/geometry/Point", "esri/symbols/SimpleMarkerSymbol", "esri/renderers/UniqueValueRenderer",
            "esri/geometry/support/webMercatorUtils", "esri/widgets/Legend", "esri/widgets/Expand"], {
            css: true,
        }).then(([reactiveUtils, FeatureLayer, AggregateField, Graphic, Point, SimpleMarkerSymbol, UniqueValueRenderer, webMercatorUtils, Legend, Expand]) => {

            if (!viewIsLoaded || lstStudyLocations.length === 0) return;


            var lstGraphics = []

            const intersectionLayer = _intersectionLayer.current;

            var strIntersectionQuery = initDataGis.intersectionGeoIdName + " In (";
            if (lstStudyLocations) {
                lstStudyLocations.filter(x => x.locationType === LocationType.Intersection).forEach((s) => {
                    strIntersectionQuery +=
                        initDataGis.intersectionGeoIdType === FieldType.String
                            ? "'" + s.geoId + "',"
                            : s.geoId + ",";
                });
                strIntersectionQuery = strIntersectionQuery.substring(
                    0,
                    strIntersectionQuery.length - 1
                );
                strIntersectionQuery += ")"
            }
            if (strIntersectionQuery === "") {
                return;
            }
            const intersectionQuery = {
                outFields: ["*"],
                where: strIntersectionQuery,
                returnGeometry: true,
            };

            // queries when view and layer has been loaded
            intersectionLayer.queryFeatures(intersectionQuery).then(function (IntersectionResults) {
                var graphics = IntersectionResults.features;
                if (strIntersectionQuery !== "") {
                    for (let index = 0; index < graphics.length; index++) {
                        // graphics[index].attributes["intersectionAADT"] = lstStudyLocations.find(x => x.geoId == graphics[index].attributes[initDataGis.intersectionGeoIdName])?.intersectionAADT
                        graphics[index].attributes[initDataGis.intersectionGeoIdName] = graphics[index].attributes[initDataGis.intersectionGeoIdName].toString()
                        graphics[index].attributes["northApproachVolume"] = lstStudyLocations.find(x => x.geoId == graphics[index].attributes[initDataGis.intersectionGeoIdName])?.volumeSummary["northApproachVolume"]
                        graphics[index].attributes["southApproachVolume"] = lstStudyLocations.find(x => x.geoId == graphics[index].attributes[initDataGis.intersectionGeoIdName])?.volumeSummary["southApproachVolume"]
                        graphics[index].attributes["westApproachVolume"] = lstStudyLocations.find(x => x.geoId == graphics[index].attributes[initDataGis.intersectionGeoIdName])?.volumeSummary["westApproachVolume"]
                        graphics[index].attributes["eastApproachVolume"] = lstStudyLocations.find(x => x.geoId == graphics[index].attributes[initDataGis.intersectionGeoIdName])?.volumeSummary["eastApproachVolume"]
                        graphics[index].attributes["totalVolume"] = lstStudyLocations.find(x => x.geoId == graphics[index].attributes[initDataGis.intersectionGeoIdName])?.volumeSummary["totalVolume"]
                        graphics[index].attributes["studyType"] = lstStudyLocations.find(x => x.geoId == graphics[index].attributes[initDataGis.intersectionGeoIdName])?.studyType
                    }
                    lstGraphics = [...lstGraphics, ...graphics]
                }


                if (trafficToMapReq.showLatestTMCStudy === true) {
                    var TMCrenderer = new UniqueValueRenderer({
                        field: "studyType",
                        uniqueValueInfos: [
                            {
                                value: StudyType.TMC,
                                symbol: {
                                    type: "picture-marker",
                                    url: "http://staticfile.tes.ca/gisMap/latestStudies/tmc2.png",
                                    width: "20px",
                                    height: "20px"
                                }
                            }
                        ]
                    }
                    );

                    var tmcStudyLayer = new FeatureLayer({
                        id: "TMCStudyLayer",
                        source: lstGraphics,
                        title: "TMC Study",
                        objectIdField: "ObjectId",
                        outFields: ["*"],
                        fields: [
                            {
                                alias: initDataGis.intersectionGeoIdName,
                                defaultValue: null,
                                editable: true,
                                length: -1,
                                name: initDataGis.intersectionGeoIdName,
                                nullable: false,
                                type: "string"
                            },
                            {
                                alias: " ",
                                defaultValue: null,
                                editable: true,
                                length: -1,
                                name: "studyType",
                                nullable: true,
                                type: "integer"
                            },
                            {
                                alias: "North Approach Volume",
                                defaultValue: null,
                                editable: true,
                                length: -1,
                                name: "northApproachVolume",
                                nullable: true,
                                type: "integer"
                            },
                            {
                                alias: "South Approach Volume",
                                defaultValue: null,
                                editable: true,
                                length: -1,
                                name: "southApproachVolume",
                                nullable: true,
                                type: "integer"
                            },
                            {
                                alias: "East Approach Volume",
                                defaultValue: null,
                                editable: true,
                                length: -1,
                                name: "eastApproachVolume",
                                nullable: true,
                                type: "integer"
                            },
                            {
                                alias: "West Approach Volume",
                                defaultValue: null,
                                editable: true,
                                length: -1,
                                name: "westApproachVolume",
                                nullable: true,
                                type: "integer"
                            },
                            {
                                alias: "Total Volume",
                                defaultValue: null,
                                editable: true,
                                length: -1,
                                name: "totalVolume",
                                nullable: true,
                                type: "integer"
                            },

                        ],
                        // popupTemplates can still be viewed on
                        // individual features
                        popupTemplate: {
                            title: "Latest TMC Study",
                            content: [
                                {
                                    type: "fields",
                                    fieldInfos: [
                                        {
                                            fieldName: initDataGis.intersectionGeoIdName,
                                            label: "Geo ID",

                                        },
                                        // {
                                        //     fieldName: "studyType",
                                        //     label: "Study Type",
                                        // },
                                        {
                                            fieldName: "northApproachVolume",
                                            label: "North Approach Volume",
                                        },
                                        {
                                            fieldName: "southApproachVolume",
                                            label: "South Approach Volume",
                                        },
                                        {
                                            fieldName: "eastApproachVolume",
                                            label: "East Approach Volume",
                                        },
                                        {
                                            fieldName: "westApproachVolume",
                                            label: "West Approach Volume",
                                        },
                                        {
                                            fieldName: "totalVolume",
                                            label: "Total Volume",
                                        },
                                    ]
                                }
                            ]
                        },
                        renderer: TMCrenderer,

                    });

                    _map.current.add(tmcStudyLayer);
                }


                setShowLoading(false)
            })
                .catch(x => {
                    setShowLoading(false)
                    console.log(x)
                });





        })
            .catch(x => {
                setShowLoading(false)
                console.log(x)
            });
    });
    const showTrafficPostedSpeed = useCallback((lstStudyLocations) => {
        // define the view here so it can be referenced in the clean up function
        loadModules(["esri/core/reactiveUtils", "esri/layers/FeatureLayer", "esri/layers/support/AggregateField", "esri/Graphic", "esri/geometry/Point", "esri/symbols/SimpleMarkerSymbol", "esri/renderers/UniqueValueRenderer",
            "esri/geometry/support/webMercatorUtils", "esri/widgets/Legend"], {
            css: true,
        }).then(([reactiveUtils, FeatureLayer, AggregateField, Graphic, Point, SimpleMarkerSymbol, UniqueValueRenderer, webMercatorUtils, Legend]) => {
            if (!viewIsLoaded || lstStudyLocations.length === 0) return;
            var lstGraphics = []
            const roadsegmentLayer = _roadSegmentLayer.current;

            // roadsegmentLayer.load().then(() => {
            //     // Set the view extent to the data extent
            //     view.extent = roadsegmentLayer.fullExtent;
            //     setExtentIsFull(true);
            // });
            var strRoadSegmentQuery = initDataGis.midblockGeoIdName + " In (";
            if (lstStudyLocations) {
                lstStudyLocations.filter(x => x.locationType === LocationType.Midblock && x.postedSpeed > 0).forEach((s) => {
                    strRoadSegmentQuery +=
                        initDataGis.midblockGeoIdType === FieldType.String
                            ? "'" + s.geoId + "',"
                            : s.geoId + ",";
                });
                strRoadSegmentQuery = strRoadSegmentQuery.substring(
                    0,
                    strRoadSegmentQuery.length - 1
                );
                strRoadSegmentQuery += ")"
            }
            if (strRoadSegmentQuery === "") {
                return;
            }
            const roadSegmentQuery = {
                outFields: ["*"],
                where: strRoadSegmentQuery,
                returnGeometry: true,
            };
            // queries when view and layer has been loaded
            roadsegmentLayer.queryFeatures(roadSegmentQuery).then(function (RoadSegmentResults) {
                var graphics = RoadSegmentResults.features;
                var lstNewRoadSegmentGraphics = graphics.filter(x => x.geometry.paths.length > 0).map(function (place) {
                    var paths = place.geometry.paths[0];
                    const res = getAveragePaths(paths)
                    var [lng, lat] = webMercatorUtils.xyToLngLat(res[0], res[1]);
                    var webMercatorCoords = webMercatorUtils.lngLatToXY(lng, lat);
                    return new Graphic({
                        attributes: {
                            [initDataGis.midblockGeoIdName]: place.attributes[initDataGis.midblockGeoIdName].toString(),
                            postedSpeed: lstStudyLocations.find(x => x.geoId == place.attributes[initDataGis.midblockGeoIdName])?.postedSpeed
                        },
                        geometry: {
                            type: 'point',
                            x: webMercatorCoords[0],
                            y: webMercatorCoords[1],
                            spatialReference: { wkid: place.geometry?.spatialReference?.wkid }
                        }
                    });

                });
                lstGraphics = [...lstGraphics, ...lstNewRoadSegmentGraphics]
                console.log(lstGraphics[10])

                if (trafficToMapReq.showPostedSpeed === true) {
                    var renderer = new UniqueValueRenderer({
                        field: "postedSpeed",
                        uniqueValueInfos: [
                            {
                                value: "30",
                                symbol: {
                                    type: "picture-marker",
                                    url: "http://staticfile.tes.ca/gisMap/speed/30.png",
                                    width: "25px",
                                    height: "25px"
                                }
                            },
                            {
                                value: "40",
                                symbol: {
                                    type: "picture-marker",
                                    url: "http://staticfile.tes.ca/gisMap/speed/40.png",
                                    width: "25px",
                                    height: "25px"
                                }
                            },
                            {
                                value: "50",
                                symbol: {
                                    type: "picture-marker",
                                    url: "http://staticfile.tes.ca/gisMap/speed/50.png",
                                    width: "25px",
                                    height: "25px"
                                }
                            },
                            {
                                value: "60",
                                symbol: {
                                    type: "picture-marker",
                                    url: "http://staticfile.tes.ca/gisMap/speed/60.png",
                                    width: "25px",
                                    height: "25px"
                                }
                            },
                            {
                                value: "70",
                                symbol: {
                                    type: "picture-marker",
                                    url: "http://staticfile.tes.ca/gisMap/speed/70.png",
                                    width: "25px",
                                    height: "25px"
                                }
                            },
                            {
                                value: "80",
                                symbol: {
                                    type: "picture-marker",
                                    url: "http://staticfile.tes.ca/gisMap/speed/80.png",
                                    width: "25px",
                                    height: "25px"
                                }
                            },
                            {
                                value: "90",
                                symbol: {
                                    type: "picture-marker",
                                    url: "http://staticfile.tes.ca/gisMap/speed/90.png",
                                    width: "25px",
                                    height: "25px"
                                }
                            },
                            {
                                value: "100",
                                symbol: {
                                    type: "picture-marker",
                                    url: "http://staticfile.tes.ca/gisMap/speed/100.png",
                                    width: "25px",
                                    height: "25px"
                                }
                            },
                            {
                                value: "110",
                                symbol: {
                                    type: "picture-marker",
                                    url: "http://staticfile.tes.ca/gisMap/speed/110.png",
                                    width: "25px",
                                    height: "25px"
                                }
                            },
                            {
                                value: "120",
                                symbol: {
                                    type: "picture-marker",
                                    url: "http://staticfile.tes.ca/gisMap/speed/120.png",
                                    width: "25px",
                                    height: "25px"
                                }
                            },
                        ]
                    }
                    );

                    var postedSpeedLayer = new FeatureLayer({
                        id: "PostedSpeedLayer",
                        source: lstGraphics,
                        title: "Posted Speed",
                        objectIdField: "ObjectId",
                        outFields: ["*"],
                        fields: [
                            {
                                alias: "Posted Speed",
                                defaultValue: null,
                                editable: true,
                                length: -1,
                                name: "postedSpeed",
                                nullable: true,
                                type: "integer"
                            },
                            {
                                alias: initDataGis.midblockGeoIdName,
                                defaultValue: null,
                                editable: true,
                                length: -1,
                                name: initDataGis.midblockGeoIdName,
                                nullable: false,
                                type: "string"
                            },

                        ],
                        // popupTemplates can still be viewed on
                        // individual features
                        popupTemplate: {
                            title: "Posted Speed",
                            content: [
                                {
                                    type: "fields",
                                    fieldInfos: [
                                        {
                                            fieldName: initDataGis.midblockGeoIdName,
                                            label: "Geo ID",

                                        },
                                        {
                                            fieldName: "postedSpeed",
                                            label: "Posted Speed",

                                        },

                                    ]
                                }
                            ]
                        },
                        renderer: renderer
                    });



                    _map.current.add(postedSpeedLayer);
                }


                setShowLoading(false)
            })

        })
            .catch(x => {
                setShowLoading(false)
                console.log(x)
            });
    });

    //#endregion

    function createTextSymbol(color) {
        return {
            type: "text", // autocasts as new TextSymbol()
            font: {
                size: 12,
                weight: "bold"
            },
            color: "#FFCCCB",
            haloColor: "red",
            haloSize: 1
        };
    }

    function getIntersectionPopupAction() {
        var lstIntersectionsActions = [];
        if (tesModule === TesMapModule.Collision) {
            lstIntersectionsActions = [
                {
                    id: "show-all-data-of-geoId",
                    image: "https://staticfile.tes.ca/gisMap/intersectionType.png",
                    title: "Show all collisions",
                },
                {
                    id: "new-collision-intersection",
                    image: "https://staticfile.tes.ca/gisMap/plus.png",
                    title: "Add new collision",
                },
            ];
        }

        if (
            tesModule === TesMapModule.CollisionDetails ||
            tesModule === TesMapModule.StudyDetails
        ) {
            lstIntersectionsActions = [
                {
                    id: "select-intersection",
                    image: "https://staticfile.tes.ca/gisMap/select.png",
                    title: "Select This Infrastructure",
                },
            ];
        }

        if (
            tesModule === TesMapModule.CollisionOverviewDashboard
        ) {
            lstIntersectionsActions = [
                {
                    id: "go-to-intersection",
                    image: "https://staticfile.tes.ca/gisMap/intersectionType.png",
                    title: "Show intersection details",
                },
            ];
        }

        if (
            tesModule === TesMapModule.Infrastructure ||
            tesModule === TesMapModule.InfrastructureDetails
        ) {
            lstIntersectionsActions = [
                {
                    id: "go-to-intersection",
                    image: "https://staticfile.tes.ca/gisMap/intersectionType.png",
                    title: "Show intersection details",
                }, {
                    id: "open-google-maps",
                    image: "https://staticfile.tes.ca/gisMap/googleMaps.png",
                    title: "Google Maps",
                },
                {
                    id: "street-view",
                    image: "https://staticfile.tes.ca/gisMap/streetView.png",
                    title: "Street View",
                },
            ];
        }

        if (tesModule === TesMapModule.Sign || tesModule === TesMapModule.Support) {
            lstIntersectionsActions = [
                {
                    id: "show-all-data-of-geoId",
                    image: "https://staticfile.tes.ca/gisMap/intersectionType.png",
                    title:
                        tesModule === TesMapModule.Support
                            ? t("showSupports")
                            : tesModule === TesMapModule.Sign
                                ? t("showSigns")
                                : "",
                },
            ];
        }
        return lstIntersectionsActions;
    }

    function getRoadSegmentPopupAction() {
        var lstRoadSegmentActions = [];
        if (
            tesModule === TesMapModule.Collision ||
            tesModule === TesMapModule.Infrastructure
        ) {
            lstRoadSegmentActions = [
                {
                    id: "show-all-data-of-geoId",
                    image: "https://staticfile.tes.ca/gisMap/midBlock.png",
                    title: "Show all collisions",
                },
                {
                    id: "new-collision-midblock",
                    image: "https://staticfile.tes.ca/gisMap/plus.png",
                    title: "Add new collision",
                },

            ];
        }

        if (
            tesModule === TesMapModule.CollisionDetails ||
            tesModule === TesMapModule.StudyDetails
        ) {
            lstRoadSegmentActions = [
                {
                    id: "select-roadSegment",
                    image: "https://staticfile.tes.ca/gisMap/select.png",
                    title: "Select This Infrastructure",
                },
            ];
        }
        if (
            tesModule === TesMapModule.CollisionOverviewDashboard
        ) {
            lstRoadSegmentActions = [
                {
                    id: "go-to-midblock",
                    image: "https://staticfile.tes.ca/gisMap/midBlock.png",
                    title: "Show Road Segment details",
                }

            ];
        }

        if (
            tesModule === TesMapModule.Infrastructure ||
            tesModule === TesMapModule.InfrastructureDetails
        ) {
            lstRoadSegmentActions = [
                {
                    id: "go-to-midblock",
                    image: "https://staticfile.tes.ca/gisMap/midBlock.png",
                    title: "Road Segment Details",
                },
                {
                    id: "open-google-maps",
                    image: "https://staticfile.tes.ca/gisMap/googleMaps.png",
                    title: "Google Maps",
                },
                {
                    id: "street-view",
                    image: "https://staticfile.tes.ca/gisMap/streetView.png",
                    title: "Street View",
                },
            ];
        }

        if (tesModule === TesMapModule.Sign || tesModule === TesMapModule.Support) {
            lstRoadSegmentActions = [
                {
                    id: "show-all-data-of-geoId",
                    image: "https://staticfile.tes.ca/gisMap/midBlock.png",
                    title:
                        tesModule === TesMapModule.Support
                            ? t("showSupports")
                            : tesModule === TesMapModule.Sign
                                ? t("showSigns")
                                : "",
                },
            ];
        }
        return lstRoadSegmentActions;
    }

    function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
    }

    function disableZooming(view) {
        view.popup.dockEnabled = true;

        // Removes the zoom action on the popup
        view.popup.actions = [];

        // stops propagation of default behavior when an event fires
        function stopEvtPropagation(event) {
            event.stopPropagation();
        }

        // exlude the zoom widget from the default UI
        //view.ui.components = ["attribution"];

        // disable mouse wheel scroll zooming on the view
        var mouseWheelEvt = view.on("mouse-wheel", stopEvtPropagation);

        // disable zooming via double-click on the view
        view.on("double-click", stopEvtPropagation);

        // disable zooming out via double-click + Control on the view
        view.on("double-click", ["Control"], stopEvtPropagation);

        // disables pinch-zoom and panning on the view
        //view.on("drag", stopEvtPropagation);

        // disable the view's zoom box to prevent the Shift + drag
        // and Shift + Control + drag zoom gestures.
        // view.on("drag", ["Shift"], stopEvtPropagation);
        // view.on("drag", ["Shift", "Control"], stopEvtPropagation);

        // prevents zooming with the + and - keys
        view.on("key-down", function (event) {
            var prohibitedKeys = ["+", "-", "Shift", "_", "="];
            var keyPressed = event.key;
            if (prohibitedKeys.indexOf(keyPressed) !== -1) {
                event.stopPropagation();
            }
            if (event.key === 'Control') {
                if (mouseWheelEvt) {
                    mouseWheelEvt.remove();
                    mouseWheelEvt = null;
                }
            } else {
                if (!mouseWheelEvt) {
                    mouseWheelEvt = view.on("mouse-wheel", stopEvtPropagation);
                }
            }
        });

        view.on("key-up", function (event) {
            if (!mouseWheelEvt) {
                mouseWheelEvt = view.on("mouse-wheel", stopEvtPropagation);
            }
        });

        return view;
    }

    function removeLayer(layerId) {
        var layer = _map.current.layers?.find(x => x.id === layerId)
        if (layer !== null && layer !== undefined && _map.current !== null) {
            _map.current.remove(layer);
        }
    }

    function layerExist(layerId) {
        var layer = _map.current.layers?.find(x => x.id === layerId)
        if (layer != null) {
            return true
        } else {
            return false
        }
    }

    function webMercatorToLatLng(x, y) {
        const originShift = 2 * Math.PI * 6378137 / 2.0;
        const lng = (x / originShift) * 180.0;
        let lat = (y / originShift) * 180.0;

        lat = 180 / Math.PI * (2 * Math.atan(Math.exp(lat * Math.PI / 180.0)) - Math.PI / 2.0);
        return [lat, lng];
    }

    function openInGoogleMap(selectedFeature) {
        var point = [0, 0]
        var coordinate = [0, 0]
        if (selectedFeature.geometry?.paths) {
            point = getAveragePaths(selectedFeature.geometry?.paths[0])
            coordinate = webMercatorToLatLng(point[0], point[1]);
        } else {
            coordinate = [selectedFeature.geometry?.latitude, selectedFeature.geometry?.longitude]
        }
        window.open(`https://www.google.com/maps?q=${coordinate[0]},${coordinate[1]}`, "_blank")
    }

    function openStreetView(selectedFeature) {
        var point = [0, 0]
        var coordinate = [0, 0]
        if (selectedFeature.geometry?.paths) {
            point = getAveragePaths(selectedFeature.geometry?.paths[0])
            coordinate = webMercatorToLatLng(point[0], point[1]);
        } else {
            coordinate = [selectedFeature.geometry?.latitude, selectedFeature.geometry?.longitude]
        }
        window.open(`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${coordinate[0]},${coordinate[1]}`, "_blank");
    }

    function getAveragePaths(paths) {
        var sumX = 0;
        var sumY = 0;
        for (let i = 0; i < paths.length; i++) {
            sumX = sumX + (paths[i])[0]
            sumY = sumY + (paths[i])[1]
        }
        const res = [sumX / paths.length, sumY / paths.length]
        return res;
    }

    return (
        <div>
            <div ref={mapEl} className={hasFullHeight ? "full-Height" : ""}>
                {_selectionIsEnable === true &&
                    <div
                        id="select-by-polygon"
                        className="esri-widget esri-widget--button esri-widget esri-interactive"
                        title="Select features by polygon"
                    >
                        <span className="esri-icon-checkbox-unchecked"></span>
                    </div>
                }
                {_selectionIsEnable === true &&

                    <div
                        id="clear-polygon"
                        className="esri-widget esri-widget--button esri-widget esri-interactive"
                        title="Clear Selection"
                    >
                        <span className="esri-icon-close-circled"></span>
                    </div>
                }
                <div id="info" className="esri-widget"></div>
            </div>
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                visible={showLoading}
                showIndicator={true}
                shading={true}
                showPane={true}
            />
        </div>
    );
}
