//#region imports
import React, { useEffect, useRef, useState, useCallback } from "react";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  ColumnChooser,
  Selection,
  Pager,
  Paging,
  Grouping,
  GroupPanel,
  FilterBuilderPopup,
  FilterPanel,
  StateStoring,
  Export,
  SortByGroupSummaryInfo,
  Summary,
  GroupItem,
  Search,
} from "devextreme-react/data-grid";
import { Button } from "devextreme-react/button";
import { TesGet } from "../../../../utils/rest";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./geoCodeGroups.scss";
import notify from "devextreme/ui/notify";
import { useAuth } from "../../../../contexts/auth";
import Permission from "../../../../components/permission/permision";
import { CollisionPermissions } from "../../../../constants/Permissions";
import { CollisionApiUrl } from "../../../../environment/routeSettings";
import { OnExporting } from "../../../../utils/dataGridTools";
import { GeoCodeGroupDTO } from "../../../../types/collision/dto/collisionDtos";
import { ContextMenu, Lookup } from "devextreme-react";
import { RightClickMenu } from "../../../../utils/rightClickMenu";
import getTitle from "../../../../components/title/getTitle";
import { SortObjectByPropName } from "../../../../utils/arrayTools";
import { useClientSetting } from "../../../../contexts/clientSetting";
//#endregion

const GeoCodeGroups = () => {
  //#region consts
  const { activeLoading } = useAuth();
  const [initData, setInitData] = useState<GeoCodeGroupDTO[]>([]);
  const history = useNavigate();
  const dataGridRef = useRef<DataGrid<any, any>>(null);
  const { t } = useTranslation();
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const items = [
    { text: t('openInNewTab'), icon: "fa-solid fa-up-right-from-square" },
    // { text: t('other')}
  ];
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";
  const { customerDivisions } = useClientSetting();
  //#endregion consts

  //#region functions
  const title = getTitle('/collision/geoCodeGroup/geoCodeGroups', '');
  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitData();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const navigateToDetails = useCallback(() => {
    history("/collision/geoCodeGroup/geoCodeGroupDetails/AddNew");
  }, [history]);


  async function getInitData() {
    setInitData(await TesGet(
      CollisionApiUrl() +
      "/api/XMLGeoCode/GetXMLGeoCodeGroups/" +
      localStorage.getItem("selectedCustomerId"),
      true
    )
    );
  }

  function onRowClicked(e: any) {
    const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;
    if (e.data.id !== undefined) {

      if (isCtrlKeyPressed) {
        window.open(`/collision/geoCodeGroup/geoCodeGroupDetails/${e.data.id}`, "_blank");
      } else {
        history(`/collision/geoCodeGroup/geoCodeGroupDetails/${e.data.id}`);
      }
    }
  }

  //#endregion functions
  return (
    <Permission
      allowed={[CollisionPermissions.Collision_GeoCode]}
      hasFeedBackElement={true}
    >
      <div className={compactViewModel ? "compactStyle" : ""}>
        <React.Fragment>
          <ContextMenu
            dataSource={items}
            width={100}
            target=".dx-data-row"
            onItemClick={e => RightClickMenu(e, selectedRowData.row.key.id, "/collision/geoCodeGroup/geoCodeGroupDetails/")}
          />
          <h2 className={"content-block"}>{t("geoCodeGroups")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <div className="row">
                <div className="rightColumn">
   
                    <Button
                      onClick={navigateToDetails}
                      icon="fa-solid fa-circle-plus"
                      text={t("geoCodeGroup")}
                    />
                </div>
              </div>
              <DataGrid
                id="gridContainer"
                ref={dataGridRef}
                dataSource={initData}
                rowAlternationEnabled={true}
                showBorders={true}
                onRowClick={onRowClicked}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                onExporting={OnExporting}
                onContextMenuPreparing={e => { setSelectedRowData(e) }}
                style={{ margin: "0 1rem" }}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                {/* <StateStoring
                  enabled={true}
                  type="localStorage"
                  storageKey={GridType.Collision.toString()}
                  savingTimeout={500}
                /> */}
                <SortByGroupSummaryInfo
                  summaryItem="Total Count"
                  sortOrder="desc"
                />
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                {/* <Selection
                mode="multiple"
                selectAllMode={"allPages"}
                showCheckBoxesMode={"always"}
                deferred={true}
                    // @ts-ignore
                    maxFilterLengthInRequest={10000}
              /> */}
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                {/* <SearchPanel visible={true} width={285} placeholder={t("search...")} /> */}
                <ColumnChooser width={350} height={400} enabled={true} mode="dragAndDrop" sortOrder="asc">
                  <Search enabled />
                </ColumnChooser>
                <Column dataField="title" caption={t("title")} visible={true}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="DivisionId"
                  allowSorting={true}
                  visible={false}
                  caption={t("division")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(customerDivisions!, "name")}
                    valueExpr="id"
                    displayExpr="name"
                  />
                </Column>
              </DataGrid>
            </div>
          </div>
        </React.Fragment>
      </div>
    </Permission>

  );
};
export default GeoCodeGroups;
