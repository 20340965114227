import React, { useCallback, useEffect, useRef, useState } from 'react';
import DataGrid, {
    Column, FilterRow, HeaderFilter, SearchPanel, ColumnChooser, Selection,
    Pager, Paging, Export, Editing, Search,
} from 'devextreme-react/data-grid';

import { TextBox } from 'devextreme-react/text-box';
import { SelectBox, Tabs } from 'devextreme-react';

import RadioGroup from 'devextreme-react/radio-group';
import { Switch } from 'devextreme-react/switch';

import { Button } from 'devextreme-react/button';
import { RequestErrorHandling, TesGet, TesPost } from '../../../utils/rest';
import {  ResponseCode } from '../../../types/general/enums/generalEnums';
import { ValidationGroup } from 'devextreme-react/validation-group';
import { IdentityGeneralSetting, SignOnMethod } from '../../../types/identity/identityTypes';
import design from './users.module.scss';
import { useTranslation } from "react-i18next";
import notify from 'devextreme/ui/notify';
import { Form, SimpleItem } from 'devextreme-react/form';
import {
    Validator,
    RequiredRule,
} from 'devextreme-react/validator';
import { VMAddUser, VMAddUserData } from '../../../types/identity/dto/identityDTO';
import { DivisionSystem, DivisionsPermissionTypes, PasswordMode } from '../../../types/identity/enums/identityEnums';
import { MakeId, MakeInt, MakeSpecialChar } from '../../../utils/stringTools';
import { INameId, RequestResponseResult } from '../../../types/general/generalTypes';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/auth';
import Permission from '../../../components/permission/permision';
import { CustomerPermissions, UserPermissions } from '../../../constants/Permissions';
import { AuthApiUrl } from '../../../environment/routeSettings';
import { OnExporting } from '../../../utils/dataGridTools';
import { custom } from 'devextreme/ui/dialog';
import { SortObjectByPropName } from '../../../utils/arrayTools';
import { useScreenSize } from '../../../utils/media-query';
import { tabTitles, withoutDivisionTabTitles } from './data';

const AddUser = () => {

    const [initData, setInitData] = useState({});
    const [initDataClient, setInitDataClient] = useState<INameId[]>();
    const [initDataDivisionRole, setInitDataDivisionRole] = useState<INameId[]>();
    const [lstUserDivisionRole, setLstUserDivisionRole] = useState<VMAddUserData>();
    const [initDataIdentityGeneralSetting, setInitDataIdentityGeneralSetting] = useState<IdentityGeneralSetting>();
    const [passMode, setPassMode] = useState<'email' | 'password' | 'search' | 'tel' | 'text' | 'url'>('password');
    const [selectedData, setSelectedData] = useState<Partial<VMAddUser>>({...new VMAddUser(), passwordMode: PasswordMode.SetPasswordManually});
    const validationGroupRef = useRef<ValidationGroup>(null);
    const dataGridRoleRef = useRef<DataGrid<any, any>>(null);
    const dataGridDivisionRef = useRef<DataGrid<any, any>>(null);
    const [currentClientSetting, setCurrentClientSetting] = useState<IdentityGeneralSetting>(new IdentityGeneralSetting());
    const history = useNavigate();
    const { activeLoading } = useAuth();
    const { t } = useTranslation();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [dataChanged, setDataChanged] = useState<boolean>(false);
    const screenSize = useScreenSize();
    const [keyIconLeftMargin,setKeyIconLeftMargin] = useState<string>("-4.5rem")
    const [copyIconLeftMargin,setCopyIconLeftMargin] = useState<string>("-8rem")
    const [ClientFieldLeftMargin,setClientFiledLeftMargin] = useState<string>("-9.5rem")
    const [emailAsUsername, setEmailAsUsername] = useState<boolean>(true)

    console.log(selectedData.singleDivisionId)

    useEffect(() => {
    async function fetchMyAPI() {
        try {
            if (activeLoading) activeLoading(true);
            await getInitData();
            await getInitDataClient();
            await getInitDataIdentityGeneralSetting();
            setSelectedIndex(0)
            await getInitDataDivisionRole();
            if (activeLoading) activeLoading(false);
        } catch (ex) {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred") + ex, "error", 2500);
        }
    }

    if (screenSize.isXLarge) {
        setKeyIconLeftMargin("-6.5rem");
        setCopyIconLeftMargin("-12rem")
        setClientFiledLeftMargin("-15rem");
    }

    if (screenSize.is2xLarge) {
        setKeyIconLeftMargin("-16.5rem");
        setCopyIconLeftMargin("-32rem")
        setClientFiledLeftMargin("-45rem");
    }

    if (initDataClient?.length === 1) {
        onValueChanged("defaultCustomerId", initDataClient[0].id);
    }

    getClientSetting(localStorage.getItem("selectedCustomerId") as string);
    setSelectedData({
        ...selectedData,
        passwordMode: PasswordMode.SetPasswordManually,
        signOnMethod: initDataIdentityGeneralSetting?.defaultSignOnMethod ? initDataIdentityGeneralSetting?.defaultSignOnMethod : SignOnMethod.TesIdentity
    });

    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);



function setDefaultClientFromLocalStorage() {
    if (initDataClient && initDataClient.length > 0) {
        const localStorageCustomerId = localStorage.getItem("selectedCustomerId");

        const matchingClient = initDataClient.find(client => client.id === localStorageCustomerId);

        if (matchingClient) {
            onValueChanged("defaultCustomerId", matchingClient.id);
        }
    }
};

useEffect(() => {
    setDefaultClientFromLocalStorage();
}, [initDataClient]);


    //Get InitData
    async function getInitData() {
        setInitData(await TesGet(AuthApiUrl() + "/api/user/finduser", true));
    }

    async function getInitDataClient() {
        const res = await TesGet(AuthApiUrl() + "/api/CustomerXUsers/ClientsOfUser", true);
        setInitDataClient(res);
        console.log(res)
    }

    async function getInitDataIdentityGeneralSetting() {
        setInitDataIdentityGeneralSetting(await TesGet(AuthApiUrl() + "/api/GeneralSettings/" + localStorage.getItem('selectedCustomerId'), true));
    }
    async function getInitDataDivisionRole() {
        let res: INameId[] = new Array<INameId>();
        for (var n in DivisionsPermissionTypes) {
            if (typeof DivisionsPermissionTypes[n] === 'number') {
                res.push({ name: n, id: DivisionsPermissionTypes[n] });
            }
        }
        setInitDataDivisionRole(res);

    }
    async function getUserRolesDivisions(customerId: string) {
        try {
            if (activeLoading) activeLoading(true);
            setLstUserDivisionRole(await TesGet(AuthApiUrl() + "/api/User/GetAddUserData/" + customerId, true));
            if (activeLoading) activeLoading(false);
        } catch (ex) {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred") + ex, "error", 2500);
        }
    }
    function getDivisionRoles(customerId: string) {
        getUserRolesDivisions(customerId);
    }


    async function onValueChanged(key: string, value: any) {
        if (key === "defaultCustomerId" && value !== null) {
            setCurrentClientSetting(await TesGet(AuthApiUrl() + "/api/GeneralSettings/" + value, true))
            getDivisionRoles(value);
        }
        setSelectedData({ ...selectedData, [key]: value });
        setDataChanged(true)
    }

    async function onSaveAndAddNew(addNew: boolean) {
        var validationRes = validationGroupRef.current?.instance.validate();
        selectedData.userName = !emailAsUsername ? selectedData.userName : selectedData.email;
        selectedData.password = (selectedData.passwordMode === PasswordMode.SendUsernamePasswordByEmail || selectedData.passwordMode === PasswordMode.SetPasswordManually)
            ? selectedData.password
            : MakeId(10) + MakeSpecialChar(1) + MakeInt(5);
            selectedData.listGroupIds = dataGridRoleRef.current?.instance.getSelectedRowKeys() ?? [];
    
        selectedData.emailUsernamePassword = selectedData.passwordMode === PasswordMode.SendUsernamePasswordByEmail;
        selectedData.emailSetPassword = selectedData.passwordMode === PasswordMode.SendSetPasswordByEmail;
    
        if (selectedData.signOnMethod === SignOnMethod.TesIdentity && selectedData.passwordMode === undefined) {
            notify(t('choosePass'), 'warning', 5000);
            return;
        }
    
        const emailRegex = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
        if (selectedData.signOnMethod === SignOnMethod.TesIdentity && !(emailRegex.test(selectedData?.email ?? ""))) {
            notify(t("invalidEmailFormat"), "error", 2500);
            return;
        }
    
        const regex = new RegExp((initDataIdentityGeneralSetting && initDataIdentityGeneralSetting?.passwordExpression)
            ? initDataIdentityGeneralSetting?.passwordExpression
            : '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$');
        if (selectedData.signOnMethod === SignOnMethod.TesIdentity && !(regex.test(selectedData?.password ?? ""))) {
            notify(t("passwordMustBeAtLeast8CharactersWithUppercaseLowercaseNumberAndSpecialCharacter"), "error", 2500);
            return;
        }
    
        if (selectedData.listGroupIds.length === 0) {
            notify(t("selectingRoleIsMandatory"), "error", 2500);
            return;
        }
    
        if (validationRes?.isValid) {
            try {
                if (activeLoading) activeLoading(true);
                var res = await TesPost(AuthApiUrl() + "/api/User/RegisterNew", selectedData, true) as RequestResponseResult<Location>;
                if (res.responseCode === ResponseCode.OK) {
                    await getInitData();
                    notify(t("dataSuccessfullyAdded"), "success", 2500);
                } else if (res.responseCode === ResponseCode.PasswordIsNotComplex) {
                    notify(t("passwordIsNotComplex"), "error", 2500);
                } else if (res.responseCode === ResponseCode.UserNameExist) {
                    notify(t("userNameExist"), "error", 2500);
                } else if (res.responseCode === ResponseCode.EmailExist) {
                    notify(t("emailExist"), "error", 2500);
                } else {
                    await RequestErrorHandling(res);;
                }
                    if (addNew) {
                    setSelectedData({
                        ...new VMAddUser(),
                        passwordMode: PasswordMode.SetPasswordManually,
                        signOnMethod: initDataIdentityGeneralSetting?.defaultSignOnMethod,
                        defaultCustomerId: undefined, // Reset the client dropdown selection
                    });
                    dataGridRoleRef.current?.instance.deselectAll();
                    validationGroupRef.current?.instance.reset();
                    await getClientSetting(localStorage.getItem("selectedCustomerId") as string)
                    setSelectedData(prevState => ({
                        ...prevState,
                        defaultCustomerId: selectedData.defaultCustomerId, // Set back the original client value
                        email: "",
                        password: ""
                    }));
                } else {
                    history(-1);
                }
                    setDataChanged(false);
                if (activeLoading) activeLoading(false);
            } catch (ex) {
                // Handle error
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred"), "error", 2500);
            }
        }
    }
    

    async function getClientSetting(customerId: string) {
        try {
            if (activeLoading) activeLoading(true);
    
            // Ensure that clientSetting is defined before proceeding
            const fetchedClientSetting = await TesGet(AuthApiUrl() + "/api/GeneralSettings/" + customerId, true);
            if (!fetchedClientSetting) {
                console.log(new Error("Client setting not available"))
            }
    
            // Now check if clientSetting?.defaultSignOnMethod is undefined
            if (fetchedClientSetting.defaultSignOnMethod === undefined) {
                setSelectedData({ ...selectedData, signOnMethod: SignOnMethod.TesIdentity,singleDivisionId: localStorage.getItem('defaultDivisionId')!, });
            } else {
                setSelectedData({ ...selectedData, signOnMethod: fetchedClientSetting.defaultSignOnMethod,singleDivisionId: localStorage.getItem('defaultDivisionId')!, });
            }
    
            if (activeLoading) activeLoading(false);
        } catch (ex) {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred") + ex, "error", 2500);
        }
    }    

    function onTabsSelectionChanged(args: any) {
        if (args.name === "selectedIndex") {
          setSelectedIndex(args.value);
        }
    }

    function goBackPermission() {
        if (dataChanged === true) {
          //dialog to show if you want to save the changed the data or discard it.
          let myDialog = custom({
            title: t("warning"),
            messageHtml: t("unsavedDataWarningText"),
            buttons: [
              {
                text: t("yes"),
                onClick: (e) => {
                  try {
                    if (dataGridRoleRef.current?.instance.getSelectedRowKeys().length !== 0) {
                        onSaveAndAddNew(false)
                        notify(t("dataSuccessfullyUpdated"), "success", 5000);
                    } else {
                        notify(t("selectingRoleIsMandatory"), "error", 5000);
                    }
                  } catch {
                    if (dataGridRoleRef.current?.instance.getSelectedRowKeys().length === 0) {
                        notify(t("someErrorOccurred"), "error", 2500) 
                    }
                  }
                  return { buttonText: e.component.option("text") };
                },
              },
              {
                text: t("no"),
                onClick: (e) => {
                  history(-1);
                  return { buttonText: e.component.option("text") };
                },
              },
              {
                text: t("cancel"),
                onClick: (e) => {
                  setDataChanged(false);
                  return { buttonText: e.component.option("text") };
                },
              },
            ],
          });
          myDialog.show();
        }else {
          history(-1);
        }
      }

      const copyToClipboard = (text: string) => {
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
      };

  return (
    <Permission
    allowed={[
        UserPermissions.User_D,
        UserPermissions.User_E,
        CustomerPermissions.Customer_D_User,
        CustomerPermissions.Customer_E_User,
        CustomerPermissions.Customer_D_Group,
        CustomerPermissions.Customer_A_Group,
        CustomerPermissions.Customer_D_Division,
    ]}
    hasFeedBackElement={true}
    >
        <React.Fragment>
        <div className={"content-block"}>
        <div className={"dx-card"}>
          <div className="row" style={{ marginTop: 15 }}>
            <div className="leftColumn">
              <Button
                onClick={goBackPermission}
                icon="fa-solid fa-arrow-left"
                hint={t("goBack")}
              />
            </div>
            <div className="rightColumn">
                <Button
                    onClick={() => onSaveAndAddNew(false)}
                    text={t("save")}
                    icon="fa-solid fa-floppy-disk"
                    className='tes-modal-btn-add'
                />
                <Button
                    onClick={() => onSaveAndAddNew(true)}
                    text={t("saveAndAddNew")}
                    icon="fa-solid fa-rotate"
                    className='tes-modal-btn-add'
                    style={{marginLeft:"1rem", backgroundColor:"#00FF00"}}
                />
            </div>
          </div>
        </div>
        <div className={"dx-card "}>
          <Tabs
            width={"100%"}
            dataSource={initDataIdentityGeneralSetting?.divisionSystem === DivisionSystem.Multiple ? tabTitles : withoutDivisionTabTitles}
            selectedIndex={selectedIndex}
            onOptionChanged={onTabsSelectionChanged}
          />

          {selectedIndex === 0 &&
            <div className={"content-block"}>
                            <div className='row'>
                                <RadioGroup
                                    style={{marginTop:"-0.5rem", marginBottom:"-1.5rem"}}
                                    layout="horizontal"
                                    id="radio-group-with-selection"
                                    items={[
                                        { id: SignOnMethod.AzureSingleSignOn, text: t('microAzureSSO') },
                                        { id: SignOnMethod.TesIdentity, text: t('tesLocalUser') }
                                    ]}
                                    value={selectedData.signOnMethod}
                                    valueExpr="id" displayExpr="text" onValueChange={e => onValueChanged("signOnMethod", e)}
                                />
                            </div>

                            <ValidationGroup
                                ref={validationGroupRef}
                            >
                                {selectedData.signOnMethod === SignOnMethod.AzureSingleSignOn &&
                                    <Form
                                        colCount={12}>
                                        <SimpleItem colSpan={3} >
                                            <TextBox
                                                style={{marginLeft:"-0.75rem"}}
                                                label={t('email')}
                                                labelMode='static'
                                                value={selectedData.email}
                                                onValueChange={e => {onValueChanged("email", e)}}
                                            >
                                                <Validator>
                                                    <RequiredRule message={t('emailIsRequired')} />
                                                </Validator>

                                            </TextBox>
                                        </SimpleItem>

                                        {!emailAsUsername &&
                                            <SimpleItem colSpan={3} >
                                                <TextBox
                                                    label={t('username')}
                                                    labelMode='static'
                                                    value={selectedData.userName}
                                                    onValueChange={e => onValueChanged("userName", e)}
                                                >
                                                    <Validator>
                                                        <RequiredRule message={t('userNameIsRequired')} />
                                                    </Validator>
                                                </TextBox>
                                            </SimpleItem>
                                        }

                                        <SimpleItem colSpan={1} >
                                            <div >{t('useEmailAsUsername')}</div>
                                            <Switch
                                                style={{ marginTop: 5 }}
                                                value={emailAsUsername}
                                                onValueChange={e => setEmailAsUsername(e)}
                                            />
                                        </SimpleItem>
                                        
                                        <SimpleItem colSpan={1} >
                                            <div >{t('sendNotificationEmail')}</div>
                                            <Switch
                                                style={{ marginTop: 5 }}
                                                value={selectedData.sendNotificationEmail}
                                                onValueChange={e => onValueChanged("sendNotificationEmail", e)}
                                            />
                                        </SimpleItem>

                                        {selectedData.setUsernameManually &&
                                            <SimpleItem colSpan={4} >
                                                <TextBox
                                                    label={t('firstName')}
                                                    labelMode='static'
                                                    value={selectedData.firstName}
                                                    onValueChange={e => onValueChanged("firstName", e)}
                                                >
                                                    {/* <Validator>
                                                    <RequiredRule message={t('firstNameIsRequired')} />
                                                </Validator> */}
                                                </TextBox>
                                            </SimpleItem>

                                        }

                                        {selectedData.setUsernameManually &&
                                            <SimpleItem colSpan={4} >
                                                <TextBox
                                                    label={t('lastName')}
                                                    labelMode='static'
                                                    value={selectedData.lastName}
                                                    onValueChange={e => onValueChanged("lastName", e)}
                                                >
                                                    {/* <Validator>
                                                    <RequiredRule message={t('lastNameIsRequired')} />
                                                </Validator> */}
                                                </TextBox>
                                            </SimpleItem>

                                        }

                                        <SimpleItem colSpan={emailAsUsername ? 4 : 2}>
                                            <SelectBox
                                                // style={{marginLeft:"-1rem"}}
                                                placeholder=""
                                                label={t('client')}
                                                valueExpr="id"
                                                displayExpr="name"
                                                labelMode='static'
                                                value={selectedData.defaultCustomerId}
                                                onValueChange={e => onValueChanged("defaultCustomerId", e)}
                                                items={initDataClient}
                                                showClearButton={true}
                                                searchEnabled={true}
                                            >
                                                <Validator>
                                                    <RequiredRule message={t('clientIsRequired')} />
                                                </Validator>
                                            </SelectBox>
                                        </SimpleItem>

                                        {!selectedData.setUsernameManually && currentClientSetting?.divisionSystem === DivisionSystem.SingleOrAll ?
                                            <SimpleItem colSpan={emailAsUsername ? 3 : 2}>
                                                <SelectBox
                                                  // style={{marginLeft:"-1rem"}}
                                                  placeholder=""
                                                  label={t('division')}
                                                  valueExpr="id"
                                                  displayExpr="name"
                                                  labelMode='static'
                                                  value={selectedData.singleDivisionId}
                                                  onValueChange={e => onValueChanged("singleDivisionId", e)}
                                                  items={SortObjectByPropName(lstUserDivisionRole?.divisions, "name")}
                                                  showClearButton={true}
                                                  searchEnabled={true}
                                                >
                                                  <Validator>
                                                    <RequiredRule message={t('clientIsRequired')} />
                                                  </Validator>
                                                </SelectBox>
                                            </SimpleItem>
                                            :
                                            <SimpleItem colSpan={emailAsUsername ? 3 : 2}/>
                                        }

                                        {/* roles */}
                                        <SimpleItem colSpan={12}>
                                            <p style={{ fontSize: "22px", margin: "0" }}>{t("roles")}</p>
                                            <hr style={{ height: "1.5px", borderWidth: "0px", color: "#424242", backgroundColor: "#424242" }}></hr>
                                        </SimpleItem>
                                    </Form>
                                }

                                {selectedData.signOnMethod === SignOnMethod.TesIdentity &&
                                    <Form
                                        colCount={12}>
                                        <SimpleItem colSpan={emailAsUsername ? 4 : 3} >
                                            <TextBox
                                                style={{marginLeft:"-0.75rem"}}
                                                label={t('email')}
                                                labelMode='static'
                                                value={selectedData.email}
                                                onValueChange={e => onValueChanged("email", e)}
                                            >
                                                <Validator>
                                                    <RequiredRule message={t('emailIsRequired')} />
                                                </Validator>
                                            </TextBox>
                                        </SimpleItem>

                                        {!emailAsUsername &&
                                            <SimpleItem colSpan={3} >
                                                <TextBox
                                                    label={t('username')}
                                                    labelMode='static'
                                                    value={selectedData.userName}
                                                    onValueChange={e => onValueChanged("userName", e)}
                                                >
                                                    <Validator>
                                                        <RequiredRule message={t('userNameIsRequired')} />
                                                    </Validator>
                                                </TextBox>
                                            </SimpleItem>
                                        }

                                        <SimpleItem colSpan={1} >
                                            <div >{t('useEmailAsUsername')}</div>
                                            <Switch
                                                style={{ marginTop: 5 }}
                                                value={emailAsUsername}
                                                onValueChange={e => setEmailAsUsername(e)}
                                            />
                                        </SimpleItem>


                                        <SimpleItem colSpan={emailAsUsername ? 3 : 2} >
                                            <TextBox
                                            // style={{marginLeft:"-1rem"}}
                                                label={t('firstName')}
                                                labelMode='static'
                                                value={selectedData.firstName}
                                                onValueChange={e => onValueChanged("firstName", e)}
                                            >
                                                <Validator>
                                                    <RequiredRule message={t('firstNameIsRequired')} />
                                                </Validator>
                                            </TextBox>
                                        </SimpleItem>
                                        <SimpleItem colSpan={emailAsUsername ? 4 : 3} >
                                            <TextBox
                                                label={t('lastName')}
                                                labelMode='static'
                                                value={selectedData.lastName}
                                                onValueChange={e => onValueChanged("lastName", e)}
                                            >
                                                <Validator>
                                                    <RequiredRule message={t('lastNameIsRequired')} />
                                                </Validator>
                                            </TextBox>
                                        </SimpleItem>

                                        <SimpleItem colSpan={12}>
                                            <RadioGroup
                                                style={{marginTop:"-1rem", marginBottom:"-1rem"}}
                                                layout="horizontal"
                                                id="radio-group-with-selection"
                                                items={[
                                                    { id: PasswordMode.SendSetPasswordByEmail, text: t('sendSetPasswordByEmail') },
                                                    { id: PasswordMode.SendUsernamePasswordByEmail, text: t('sendUsernamePasswordByEmail') },
                                                    { id: PasswordMode.SetPasswordManually, text: t('setPasswordManually') }
                                                ]}
                                                defaultValue={PasswordMode.SetPasswordManually}
                                                value={selectedData.passwordMode}
                                                valueExpr="id" displayExpr="text" onValueChange={e => onValueChanged("passwordMode", e)} />
                                        </SimpleItem>

                                        {(selectedData.passwordMode === PasswordMode.SendUsernamePasswordByEmail || selectedData.passwordMode === PasswordMode.SetPasswordManually) &&
                                            <SimpleItem colSpan={4} >
                                                <TextBox
                                                    style={{marginLeft:"-0.75rem"}}
                                                    label={t('password')}
                                                    labelMode='static'
                                                    value={selectedData.password}
                                                    onValueChange={e => onValueChanged("password", e)}
                                                    className={design.passwordField}
                                                    mode={passMode}
                                                >
                                                    <Validator>
                                                        <RequiredRule message={t('passwordIsRequired')} />
                                                    </Validator>
                                                </TextBox>
                                            </SimpleItem>
                                        }
                                        {(selectedData.passwordMode === PasswordMode.SendUsernamePasswordByEmail || selectedData.passwordMode === PasswordMode.SetPasswordManually) &&
                                            <SimpleItem colSpan={1} >
                                                <i
                                                    className={passMode === "text" ? `${"fa-regular fa-eye-slash fa-2xl"} ${design.eyeIcon}` : `${"fa-regular fa-eye fa-2xl"} ${design.eyeIcon}`}
                                                    onClick={() => passMode === "text" ? setPassMode("password") : setPassMode("text")}
                                                />
                                            </SimpleItem>
                                        }
                                        {(selectedData.passwordMode === PasswordMode.SendUsernamePasswordByEmail || selectedData.passwordMode === PasswordMode.SetPasswordManually) &&
                                            <SimpleItem colSpan={1} >
                                                <i
                                                    className={`${"fa-solid fa-key fa-2xl"} ${design.keyIcon} ${design.tooltip}`}
                                                    style={{marginLeft:keyIconLeftMargin}}
                                                    onClick={e =>
                                                        {onValueChanged("password", MakeId(9) + MakeSpecialChar(1) + MakeInt(3));
                                                        setPassMode("text");
                                                        }
                                                    }
                                                >
                                                    <label className={design.tooltipText}>{t("generatePassword")}</label>
                                                </i>
                                            </SimpleItem>
                                        }
                                        {(selectedData.passwordMode === PasswordMode.SendUsernamePasswordByEmail || selectedData.passwordMode === PasswordMode.SetPasswordManually) &&
                                            <SimpleItem colSpan={1} >
                                                <i
                                                    className={`${"fa-solid fa-copy fa-2xl"} ${design.copyIcon} ${design.tooltip}`}
                                                    style={{marginLeft:copyIconLeftMargin}}
                                                    onClick={e => {
                                                        const passwordValue = selectedData.password;
                                                        copyToClipboard(passwordValue!);
                                                        notify(t("passwordCopied"), "success", 1500);
                                                    }}
                                                >
                                                    <label className={design.tooltipText}>{t("copyPassword")}</label>
                                                </i>
                                            </SimpleItem>
                                        }
                                        <SimpleItem colSpan={selectedData.passwordMode === PasswordMode.SendSetPasswordByEmail ? 4 : 2}>
                                            <SelectBox
                                                style={{ marginLeft:selectedData.passwordMode === PasswordMode.SendSetPasswordByEmail ? "-0.75rem" : ClientFieldLeftMargin}}
                                                placeholder=""
                                                label={t('client')}
                                                valueExpr="id"
                                                displayExpr="name"
                                                labelMode='static'
                                                value={selectedData.defaultCustomerId}
                                                onValueChange={e => onValueChanged("defaultCustomerId", e)}
                                                items={initDataClient}
                                                showClearButton={true}
                                                searchEnabled={true}
                                            >
                                                <Validator>
                                                    <RequiredRule message={t('clientIsRequired')} />
                                                </Validator>
                                            </SelectBox>
                                        </SimpleItem>
                                        {currentClientSetting?.divisionSystem === DivisionSystem.SingleOrAll ? 
                                        <SimpleItem colSpan={3}>
                                            <SelectBox
                                              // style={{marginLeft:"-1rem"}}
                                              placeholder=""
                                              label={t('division')}
                                              valueExpr="id"
                                              displayExpr="name"
                                              labelMode='static'
                                              value={selectedData.singleDivisionId}
                                              onValueChange={e => onValueChanged("singleDivisionId", e)}
                                              items={SortObjectByPropName(lstUserDivisionRole?.divisions, "name")}
                                              showClearButton={true}
                                              searchEnabled={true}
                                            >
                                              <Validator>
                                                <RequiredRule message={t('clientIsRequired')} />
                                              </Validator>
                                            </SelectBox>
                                        </SimpleItem> :
                                        <SimpleItem colSpan={3}/>
                                        }

                                        {selectedData.passwordMode === PasswordMode.SendSetPasswordByEmail && <SimpleItem colSpan={selectedData.passwordMode === PasswordMode.SendSetPasswordByEmail ? 5 : 2}/>}

                                        <SimpleItem colSpan={12}>
                                            <p style={{ fontSize: "22px", margin: "0" }}>{t("roles")}</p>
                                            <hr style={{ height: "1.5px", borderWidth: "0px", color: "#424242", backgroundColor: "#424242" }}></hr>
                                        </SimpleItem>
                                        {/* </GroupItemForm> */}
                                    </Form>
                                }
                                <DataGrid id="gridContainer"
                                    ref={dataGridRoleRef}
                                    dataSource={SortObjectByPropName(lstUserDivisionRole?.groups, "name")}
                                    rowAlternationEnabled={true}
                                    showBorders={true}
                                    keyExpr="id"
                                    hoverStateEnabled={true}
                                    onExporting={OnExporting}
                                    //onContentReady={e => {e.component.deselectAll()}}
                                    //onFilterValueChange={e => {dataGridRoleRef?.current?.instance.deselectAll()}}
                                    style={{marginBottom:"0.75rem"}}
                                >
                                    <Export enabled={true} allowExportSelectedData={true} />
                                    <Paging enabled={true} defaultPageSize={20} />
                                    <Pager
                                        showPageSizeSelector={true}
                                        allowedPageSizes={[10, 15, 20, 25, 50]}
                                        showNavigationButtons={true}
                                    />
                                    <Selection
                                        mode="multiple"
                                        selectAllMode={'allPages'}
                                        showCheckBoxesMode={'always'}
                                    />
                                    <FilterRow visible={true}
                                        applyFilter="auto" />
                                    <HeaderFilter visible={true} />
                                    <SearchPanel visible={true}
                                        width={285}
                                        placeholder={t("search...")} />
                                    <ColumnChooser width={350} height={400}
                                        enabled={true}
                                        mode="select"
                                        sortOrder="asc"
                                    >
                                        <Search enabled />
                                    </ColumnChooser>
                                    <Column dataField="name"
                                        caption={t("name")}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>
                                </DataGrid>
                            </ValidationGroup>
            </div>
          }

          {selectedIndex === 1 && initDataIdentityGeneralSetting?.divisionSystem === DivisionSystem.Multiple &&
                            <div className={"content-block"}>
                                <ValidationGroup
                                    ref={validationGroupRef}
                                >
                                    {selectedData.signOnMethod === SignOnMethod.AzureSingleSignOn &&
                                        <Form colCount={12}>

                                            {/* divisions */}
                                            <SimpleItem colSpan={12}>
                                                <p style={{ fontSize: "22px", margin: "1rem 0 0 0" }}>{t("divisions")}</p>
                                                <hr style={{ marginBottom:"-1.5rem", height: "1.5px", borderWidth: "0px", color: "#424242", backgroundColor: "#424242" }}></hr>
                                            </SimpleItem>
                                            <SimpleItem colSpan={12} >
                                                <DataGrid id="gridContainer"
                                                    ref={dataGridDivisionRef}
                                                    dataSource={SortObjectByPropName(lstUserDivisionRole?.divisions, "name")}
                                                    rowAlternationEnabled={true}
                                                    showBorders={true}
                                                    hoverStateEnabled={true}
                                                    allowColumnReordering={true}
                                                    allowColumnResizing={true}
                                                    columnAutoWidth={true}
                                                    onExporting={OnExporting}
                                                    onEditorPreparing={e => {
                                                        if ((e.parentType === "dataRow" || e.parentType === "filterRow") && e.dataField === "roles") {
                                                            e.editorName = "dxTagBox"
                                                            e.editorOptions.dataSource = initDataDivisionRole;
                                                            e.editorOptions.showSelectionControls = true;
                                                            e.editorOptions.displayExpr = "name";
                                                            e.editorOptions.valueExpr = "id";
                                                            e.editorOptions.value = e.value || [];
                                                            e.editorOptions.onValueChanged = function (args: any) {
                                                                e.setValue(args.value);
                                                            }
                                                        }
                                                    }}
                                                    //onContentReady={e => {e.component.deselectAll()}}
                                                    //onFilterValueChange={e => {dataGridDivisionRef?.current?.instance.deselectAll()}}
                                                >
                                                    <Export enabled={true} allowExportSelectedData={true} />
                                                    <SearchPanel visible={true}
                                                        width={285}
                                                        placeholder={t("search...")} />
    
                                                    <ColumnChooser width={350} height={400}
                                                        enabled={true}
                                                        mode="select"
                                                        sortOrder="asc"
                                                    >
                                                        <Search enabled />
                                                    </ColumnChooser>
                                                    <Paging enabled={true} defaultPageSize={20} />
                                                    <Pager
                                                        showPageSizeSelector={true}
                                                        allowedPageSizes={[10, 20, 50, 100]}
                                                        showNavigationButtons={true}
    
                                                    />
                                                    <Editing
                                                        mode="cell"
                                                        allowUpdating={true}
                                                        allowAdding={false}
                                                        allowDeleting={false} />
                                                    <Selection
                                                        mode="multiple"
                                                        selectAllMode={'allPages'}
                                                        showCheckBoxesMode={'always'}
                                                    />
    
                                                    <FilterRow visible={true}
                                                        applyFilter="auto" />
    
                                                    <HeaderFilter visible={true} />
    
    
                                                    <Column dataField="name"
                                                        width={150}
                                                        caption={t('name')}
                                                    >
                                                        <HeaderFilter>
                                                            <Search enabled />
                                                        </HeaderFilter>
                                                    </Column>
    
                                                    <Column dataField="city"
                                                        width={100}
                                                        caption={t('city')}>
                                                        <HeaderFilter>
                                                            <Search enabled />
                                                        </HeaderFilter>
                                                    </Column>
                                                    <Column
                                                        dataField="roles" caption={t('permissions')}
                                                        calculateDisplayValue={function (rowData: any) {
                                                            var values = rowData.roles;
                                                            var res = [];
                                                            if (values !== undefined) {
                                                                for (let i = 0; i < values.length; i++) {
                                                                    res.push(DivisionsPermissionTypes[values[i]]);
                                                                }
                                                            }
                                                            return res.join(",");
                                                        }}
                                                    ></Column>
                                                </DataGrid>
                                            </SimpleItem>
                                        </Form>
                                    }
    
                                    {selectedData.signOnMethod === SignOnMethod.TesIdentity &&
                                        <Form colCount={12}>
                                            
                                            {/* divisions */}
                                            <SimpleItem colSpan={12}>
                                                <p style={{ fontSize: "22px", margin: "0" }}>{t("divisions")}</p>
                                                <hr style={{ marginBottom:"-1.5rem", height: "1.5px", borderWidth: "0px", color: "#424242", backgroundColor: "#424242" }}></hr>
                                            </SimpleItem>
    
                                            <SimpleItem colSpan={12} >
    
                                                <DataGrid id="gridContainer"
                                                    ref={dataGridDivisionRef}
                                                    dataSource={SortObjectByPropName(lstUserDivisionRole?.divisions, "name")}
                                                    rowAlternationEnabled={true}
                                                    showBorders={true}
                                                    hoverStateEnabled={true}
                                                    allowColumnReordering={true}
                                                    allowColumnResizing={true}
                                                    columnAutoWidth={true}
                                                    onExporting={OnExporting}
                                                    onEditorPreparing={e => {
                                                        if ((e.parentType === "dataRow" || e.parentType === "filterRow") && e.dataField === "roles") {
                                                            e.editorName = "dxTagBox"
                                                            e.editorOptions.dataSource = initDataDivisionRole;
                                                            e.editorOptions.showSelectionControls = true;
                                                            e.editorOptions.displayExpr = "name";
                                                            e.editorOptions.valueExpr = "id";
                                                            e.editorOptions.value = e.value || [];
                                                            e.editorOptions.onValueChanged = function (args: any) {
                                                                e.setValue(args.value);
                                                            }
                                                        }
                                                    }}
                                                    //onContentReady={e => {e.component.deselectAll()}}
                                                    //onFilterValueChange={e => {dataGridDivisionRef?.current?.instance.deselectAll()}}
                                                >
                                                    <Export enabled={true} allowExportSelectedData={true} />
                                                    <SearchPanel visible={true}
                                                        width={285}
                                                        placeholder={t("search...")} />
    
                                                    <ColumnChooser width={350} height={400}
                                                        enabled={true}
                                                        mode="select"
                                                        sortOrder="asc"
                                                    >
                                                        <Search enabled />
                                                    </ColumnChooser>
                                                    <Paging enabled={true} defaultPageSize={20} />
                                                    <Pager
                                                        showPageSizeSelector={true}
                                                        allowedPageSizes={[10, 20, 50, 100]}
                                                        showNavigationButtons={true}
    
                                                    />
                                                    <Editing
                                                        mode="cell"
                                                        allowUpdating={true}
                                                        allowAdding={false}
                                                        allowDeleting={false} />
                                                    <Selection
                                                        mode="multiple"
                                                        selectAllMode={'allPages'}
                                                        showCheckBoxesMode={'always'}
                                                    />
    
                                                    <FilterRow visible={true}
                                                        applyFilter="auto" />
    
                                                    <HeaderFilter visible={true} />
    
    
                                                    <Column dataField="name"
                                                        width={150}
                                                        caption={t('name')}
                                                    >
                                                        <HeaderFilter>
                                                            <Search enabled />
                                                        </HeaderFilter>
                                                    </Column>
    
                                                    <Column dataField="city"
                                                        width={100}
                                                        caption={t('city')}>
                                                        <HeaderFilter>
                                                            <Search enabled />
                                                        </HeaderFilter>
                                                    </Column>
                                                    <Column
                                                        dataField="roles" caption={t('permissions')}
                                                        calculateDisplayValue={function (rowData: any) {
                                                            var values = rowData.roles;
                                                            var res = [];
                                                            if (values !== undefined) {
                                                                for (let i = 0; i < values.length; i++) {
                                                                    res.push(DivisionsPermissionTypes[values[i]]);
                                                                }
                                                            }
                                                            return res.join(",");
                                                        }}
                                                    ></Column>
                                                </DataGrid>
                                            </SimpleItem>
                                        </Form>
                                    }
                                </ValidationGroup>
                </div>
          }
        </div>
      </div>   
        </React.Fragment>
    </Permission>
  );
};

export default AddUser;