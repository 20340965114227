//#region imports
import React, { useEffect, useRef, useState } from 'react';
import { Form, SimpleItem } from 'devextreme-react/form';
import { SelectBox, TextBox, ScrollView } from 'devextreme-react';
import { useTranslation } from 'react-i18next';
import DateBox from 'devextreme-react/date-box';
import { ReportingPeriodDTO, StudyBaseDTO } from '../../../../../../types/trafficStudy/dtos/studyDto';
import { NameValue, RequestResponseResult } from '../../../../../../types/general/generalTypes';
import { Enum2Array } from '../../../../../../utils/enumTools';
import { Button } from 'devextreme-react/button';
import DataGrid, {
    Column, FilterRow, HeaderFilter, SearchPanel, ColumnChooser, Pager, Paging, Button as DevExpressButton, Lookup, Export, SortByGroupSummaryInfo, Summary, GroupItem, Search
} from 'devextreme-react/data-grid';
import { ValidationGroup } from 'devextreme-react/validation-group';
import { Validator, RequiredRule } from 'devextreme-react/validator';
import { ModalMode, ResponseCode } from '../../../../../../types/general/enums/generalEnums';
import { Popup } from 'devextreme-react/popup';
import { v4 as uuid } from 'uuid';
import { ReportingPeriodType } from '../../../../../../types/trafficStudy/enums/trafficStudyEnums';
import { GeneralSetting } from '../../../../../../types/setting/gridSettingTypes';
import { useAuth } from '../../../../../../contexts/auth';
import notify from 'devextreme/ui/notify';
import { RequestErrorHandling, TesGet } from '../../../../../../utils/rest';
import { TrafficApiUrl } from '../../../../../../environment/routeSettings';
import { OnExporting } from '../../../../../../utils/dataGridTools';
import { SortObjectByPropName } from '../../../../../../utils/arrayTools';
import GridTotalCalculator from '../../../../../../utils/girdTotalCalculator';
//#endregion imports

// props
interface IPros {
    initDataBase: StudyBaseDTO,
    reportingPeriodValidation: React.RefObject<ValidationGroup>;
    setInitDataBase: React.Dispatch<React.SetStateAction<any>>
    generalSettings: GeneralSetting | null;
    isLocked: boolean;
    setDataChanged: React.Dispatch<React.SetStateAction<any>>;
    validationRef: React.RefObject<ValidationGroup>;
}


const ReportingPeriod = (props: IPros) => {
    const dataGridRef = useRef<DataGrid<any, any>>(null);
    const [showModal, setShowModal] = useState(false);
    const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
    const [selectedData, setSelectedData] = useState<ReportingPeriodDTO>(new ReportingPeriodDTO());
    const [lstReportingPeriodType, setLstReportingPeriodType] = useState<NameValue[]>([]);
    const { t } = useTranslation();
    const { activeLoading } = useAuth();
    const compactViewModel: boolean =(JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";

    //#region functions
    useEffect(() => {
        async function fetchMyAPI() {
            try {
                if (activeLoading) activeLoading(true);
                setLstReportingPeriodType(Enum2Array(ReportingPeriodType));
                if (activeLoading) activeLoading(false);
            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        }
        fetchMyAPI()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    function onNew() {
        setSelectedData(new ReportingPeriodDTO());
        setModalMode(ModalMode.Add);
        setShowModal(true);
    }

    function onCloseModal() {
        setShowModal(false);
    }

    function onCancel() {
        props.setDataChanged(false)
        setShowModal(false)
    }



    function onValueChange(name: string, value: any) {
        setSelectedData({ ...selectedData, [name]: value });
        props.setDataChanged(true)
    }

    function onAdd() {
        props.setInitDataBase({
            ...props.initDataBase,
            reportingPeriods: [
                ...props.initDataBase.reportingPeriods,
                { ...selectedData, id: uuid() }
            ]
        });
        dataGridRef.current?.instance.refresh();
        onCloseModal();
    }

    function onEdit(d: any) {
        setModalMode(ModalMode.Edit);
        setSelectedData(d.row.data);
        setShowModal(true);
    }

    function onUpdate() {
        const reportingPeriods = props.initDataBase.reportingPeriods;
        const selectedDataIndex: number = reportingPeriods.findIndex((x: ReportingPeriodDTO) => x.id === selectedData.id);
        reportingPeriods[selectedDataIndex] = selectedData;
        props.setInitDataBase({
            ...props.initDataBase,
            reportingPeriods: reportingPeriods
        });
        dataGridRef.current?.instance.refresh();
        onCloseModal();
    }

    async function onReset() {
        try {
            if (activeLoading) activeLoading(true);
            const res = (await TesGet(
                TrafficApiUrl() + "/api/Study/GetReportingPeriod/" + props.initDataBase.customerId + "/" + props.initDataBase.studyType,
                true
            )) as RequestResponseResult<ReportingPeriodDTO[]>;
            if (activeLoading) activeLoading(false);
            if (res.responseCode === ResponseCode.OK) {
                props.setInitDataBase({
                    ...props.initDataBase,
                    reportingPeriods: res.results
                });
            }
            else {
                await RequestErrorHandling(res);;
            }
        } catch (ex) {
            if (activeLoading) activeLoading(false);
        }

    }

    //#endregion functions
    return (
        <div className={`studyDetails-reportingPeriod ${compactViewModel ? "compactStyle" : ""}`}>
        <React.Fragment>
            <div className='row' style={{ margin: "1rem" }}>
                <ValidationGroup
                    ref={props.validationRef}
                >
                    <div className={"dx-card"} id="iconsCard" style={{ margin: "0rem 1rem 2rem 1rem" }}>
                        <div className="row" style={{ margin: "0.5rem 0 -0.5rem 0" }}>
                            <div className="leftColumn">
                                <Button
                                    icon="fa-solid fa-calculator"
                                    style={{ marginRight: 10 }}
                                    hint={t("recalculate")}
                                    onClick={onReset}
                                    disabled={props.isLocked}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: 20, padding: 10 }} id="reportButtonContainer">
                        <div className='rightColumn'>
                            <Button
                                onClick={onNew}
                                icon="fa-solid fa-circle-plus"
                                text={t('reportPeriod')}
                                disabled={props.isLocked}
                            />
                        </div>
                    </div>
                    <DataGrid
                        id="reportingPeriodGridContainer"
                        ref={dataGridRef}
                        dataSource={props.initDataBase.reportingPeriods}
                        rowAlternationEnabled={true}
                        showBorders={true}
                        hoverStateEnabled={true}
                        remoteOperations={true}
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        columnAutoWidth={true}
                        style={{ margin: "0 1rem 1rem" }}
                        onExporting={OnExporting}
                    >
                        <Export enabled={true} allowExportSelectedData={true} />
                        <Paging enabled={true} defaultPageSize={100} />
                        <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={[100, 200, 300, 400, 500]}
                            showNavigationButtons={true}
                            showInfo={true}
                            visible={true}
                        />
                        <FilterRow visible={true}
                            applyFilter="auto" />

                        <HeaderFilter visible={true} />

                        <SearchPanel visible={true}
                            width={285}
                            placeholder={t("search...")} />
                        <ColumnChooser width={350} height={400}
                            enabled={true}
                            mode="select"
                            sortOrder="asc"
                        >
                            <Search enabled />
                        </ColumnChooser>
                        <SortByGroupSummaryInfo
                            summaryItem="Total Count"
                            sortOrder="desc"
                        />
                        <Summary>
                            <GroupItem
                                summaryType="count"
                                alignByColumn
                                name="Total Count"
                            />
                        </Summary>
                        <Column dataField="id" caption={t("id")} visible={false}>
                            <HeaderFilter>
                                <Search enabled />
                            </HeaderFilter>
                        </Column>
                        <Column
                            dataField="customerId"
                            caption={t("customerId")}
                            visible={false}
                        >
                            <HeaderFilter>
                                <Search enabled />
                            </HeaderFilter>
                        </Column>
                        <Column dataField="label" caption={t("label")} visible={true}>
                            <HeaderFilter>
                                <Search enabled />
                            </HeaderFilter>
                        </Column>
                        <Column
                            dataField="startTime"
                            caption={t("startTime")}
                            dataType="datetime"
                            visible={true}
                            format={props.generalSettings?.isAmPm ? "shortTime" : "HH:mm"}
                        >
                            <HeaderFilter>
                                <Search enabled />
                            </HeaderFilter>
                        </Column>
                        <Column
                            dataField="endTime"
                            caption={t("endTime")}
                            dataType="datetime"
                            visible={true}
                            format={props.generalSettings?.isAmPm ? "shortTime" : "HH:mm"}
                        >
                            <HeaderFilter>
                                <Search enabled />
                            </HeaderFilter>
                        </Column>
                        <Column dataField="type" caption={t("type")}>
                            <HeaderFilter>
                                <Search enabled />
                            </HeaderFilter>
                            <Lookup
                                dataSource={SortObjectByPropName(lstReportingPeriodType, "name")}
                                valueExpr="value"
                                displayExpr="name"
                            />
                        </Column>

                        <Column type="buttons" caption={t("actions")} width={110} visible={!props.isLocked} fixed={true} fixedPosition="right">
                            <DevExpressButton hint={t('edit')} onClick={onEdit} icon="fa-solid fa-pencil">

                            </DevExpressButton>
                            {/* <DevExpressButton hint={t('delete')} onClick={onDelete} icon="fa-solid fa-trash-can">

                    </DevExpressButton> */}
                        </Column>
                    </DataGrid>

                    {/* Popup for adding new Report Period */}
                    <Popup
                        width={"50%"}
                        height={"auto"}
                        visible={showModal}
                        resizeEnabled={true}
                        showTitle={true}
                        title={modalMode === ModalMode.Add ? t('add') : t('update')}
                        hideOnOutsideClick={false}
                        showCloseButton={true}
                        onHiding={() => onCloseModal()}
                    >
                        <ScrollView width='100%' height='100%'>
                            <ValidationGroup ref={props.reportingPeriodValidation}>
                                <Form colCount={2} className='popupFields'>
                                    <SimpleItem colSpan={1}>
                                        <TextBox
                                            label={t("label")}
                                            labelMode="static"
                                            value={selectedData.label}
                                            onValueChange={(e) => onValueChange("label", e)}
                                            className="modalInput"
                                        >
                                            <Validator>
                                                <RequiredRule message={t("fieldIsRequired")} />
                                            </Validator>
                                        </TextBox>
                                    </SimpleItem>
                                    <SimpleItem>
                                        <DateBox
                                            type="time"
                                            label={t("startTime")}
                                            labelMode="static"
                                            value={selectedData.startTime}
                                            onValueChange={(e) => onValueChange("startTime", e)}
                                            pickerType="rollers"
                                            placeholder={props.generalSettings?.timeFormat}
                                            displayFormat={props.generalSettings?.timeFormat}
                                            useMaskBehavior={true}
                                            openOnFieldClick={true}
                                            showClearButton={true}
                                        >
                                            <Validator>
                                                <RequiredRule message={t("fieldIsRequired")} />
                                            </Validator>
                                        </DateBox>
                                    </SimpleItem>
                                    <SimpleItem>
                                        <DateBox
                                            type="time"
                                            label={t("endTime")}
                                            labelMode="static"
                                            value={selectedData.endTime}
                                            onValueChange={(e) => onValueChange("endTime", e)}
                                            pickerType="rollers"
                                            placeholder={props.generalSettings?.timeFormat}
                                            displayFormat={props.generalSettings?.timeFormat}
                                            useMaskBehavior={true}
                                            openOnFieldClick={true}
                                            showClearButton={true}
                                        >
                                            <Validator>
                                                <RequiredRule message={t("fieldIsRequired")} />
                                            </Validator>
                                        </DateBox>
                                    </SimpleItem>
                                    <SimpleItem colSpan={1}>
                                        <SelectBox
                                            placeholder=""
                                            label={t("type")}
                                            labelMode="static"
                                            value={selectedData.type}
                                            onValueChange={(e) => onValueChange("type", e)}
                                            items={SortObjectByPropName(lstReportingPeriodType, "name")}
                                            valueExpr="value"
                                            displayExpr="name"
                                            showClearButton={true}
                                            searchEnabled={true}
                                        />
                                    </SimpleItem>
                                </Form>
                            </ValidationGroup>
                            <div>
                                <div className='rightColumn'>
                                    <Button
                                        className='tes-modal-btn-cancel'
                                        style={{ marginRight: 20, marginTop: '10px' }}
                                        onClick={() => onCancel()}
                                        text={t('cancel')}
                                    />
                                    {modalMode === ModalMode.Add ?
                                        <Button
                                            style={{ marginTop: '10px' }}
                                            className='tes-modal-btn-add'
                                            onClick={onAdd}
                                            text={t('add')}
                                        />
                                        :
                                        <Button
                                            style={{ marginTop: '10px' }}
                                            className='tes-modal-btn-add'
                                            onClick={onUpdate}
                                            text={t('update')}
                                        />
                                    }
                                </div>
                            </div>

                        </ScrollView>
                    </Popup>
                </ValidationGroup>
            </div>
        </React.Fragment>
        </div>
    );

}
export default ReportingPeriod;