import { Button } from 'devextreme-react/button';
import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import notify from 'devextreme/ui/notify';
import { IUser, VMChangePassword, VMUserProfileDetails } from '../../../../../types/identity/dto/identityDTO';
import { RequestErrorHandling, TesPost } from '../../../../../utils/rest';
import { ResponseCode } from '../../../../../types/general/enums/generalEnums';
import { useAuth } from '../../../../../contexts/auth';
import { RequestResponseResult } from '../../../../../types/general/generalTypes';
import { Form, SimpleItem, GroupItem as GroupItemForm } from 'devextreme-react/form';
import { TextBox } from 'devextreme-react/text-box';
import { ValidationGroup } from 'devextreme-react/validation-group';
import {
    Validator,
    RequiredRule,
    CompareRule
} from 'devextreme-react/validator';
import { AuthApiUrl } from '../../../../../environment/routeSettings';
import { SignOnMethod } from '../../../../../types/identity/identityTypes';
import { TMSPermissions } from '../../../../../constants/Permissions';
import { MakeId, MakeInt, MakeSpecialChar } from '../../../../../utils/stringTools';
import design from './users.module.scss';



// props
interface IPros {
    initData: VMUserProfileDetails,
    user: IUser | null
}

const Password = (props: IPros) => {
    const { activeLoading } = useAuth();
    const [password, setPassword] = useState<VMChangePassword>({
        username: props.initData.userName,
        password: '',
        confirmPassword: ''
    });
    const [passMode, setPassMode] = useState<'email' | 'password' | 'search' | 'tel' | 'text' | 'url'>('password');
    const [autoFill, setAutoFill] = useState<boolean>(false);
    const validationGroupRef = useRef<ValidationGroup>(null);
    const textBoxRef = useRef<TextBox>(null);


    useEffect(() => {
    }, [props.initData]);

    const copyTextToClipboard = () => {
        if (textBoxRef.current) {
            const textToCopy = textBoxRef.current.instance.option("value") || ''; // Use empty string as default
            navigator.clipboard.writeText(textToCopy);
            notify(t("textCopiedToClipboard"), "success", 2000);
        }
    };
    
    async function onSave() {
        try {
            var validationRes = validationGroupRef.current?.instance.validate();
            if (validationRes?.isValid) {
                const regex = new RegExp((props.initData?.generalSetting && props.initData?.generalSetting?.passwordExpression)
                    ? props.initData?.generalSetting?.passwordExpression
                    : '/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/ : /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/'
                )

                if (!(regex.test(password?.password ?? ""))) {
                    notify(t("passwordIsNotComplex"), "error", 5000);
                    return;
                }

                var postObj = new VMChangePassword();
                postObj.username = props.initData.userName;
                postObj.password = password.password;
                if (activeLoading) activeLoading(true);
                const res = (await (TesPost(AuthApiUrl() + "/api/User/ChangePasswordUserProfile", postObj, true))) as RequestResponseResult<null>
                if (res.responseCode === ResponseCode.OK) {
                    if (activeLoading) activeLoading(false);
                    notify(t("dataSuccessfullyUpdated"), "success", 5000);
                } else if (res.responseCode === ResponseCode.CustomerNotPermitted) {
                    if (activeLoading) activeLoading(false);
                    notify(t("customerNotPermitted"), "error", 5000);
                } else if (res.responseCode === ResponseCode.PasswordIsNotComplex) {
                    if (activeLoading) activeLoading(false);
                    notify(t("passwordIsNotComplex"), "error", 5000);
                } else if (res.responseCode === ResponseCode.UsernamePasswordNotValid) {
                    if (activeLoading) activeLoading(false);
                    notify(t("usernamePasswordNotValid"), "error", 5000);
                }
                if (activeLoading) activeLoading(false);
                await RequestErrorHandling(res);;
            }
        } catch (ex) {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred" + ex), "error", 5000);
        }
    }

    function onValueChanged(name: string, value: any) {
        if (name === 'password') {
            if (!autoFill) {
                setPassword(prevState => ({ ...prevState, password: value }));
            } else {
                setPassword(prevState => ({ ...prevState, password: value, confirmPassword: value }));
            }
        } else if (name === 'confirmPassword') {
            setPassword(prevState => ({ ...prevState, confirmPassword: value }));
        }
    }    
    
    function passwordComparison() {
        return password.password;
    }

    return (
        <React.Fragment>
            {console.log(props.initData?.generalSetting?.passwordExpression)}
            <div className='row' style={{ marginTop: 20, padding: 10 }}>
                <Form
                    colCount={2}>
                    <GroupItemForm caption={t("resetPassword")}
                        name={t("resetPassword")}
                        visible={true}
                        colSpan={1}
                    >
                        <ValidationGroup
                            ref={validationGroupRef}
                        >
                            <Form
                                colCount={7}>
                                <SimpleItem  colSpan={5}>
                                    <TextBox
                                        id="passFieldInUserDetails"
                                        ref={textBoxRef}
                                        label={t('password')}
                                        labelMode='static'
                                        value={password.password}
                                        onValueChange={e => onValueChanged("password", e)}
                                        className={design.passwordField}
                                        mode={passMode}
                                        disabled={(props.initData.signOnMethod === SignOnMethod.AzureSingleSignOn && !props.user?.permissions?.includes(TMSPermissions.TMS_Admin))}
                                    >
                                        <i
                                            className={passMode === "text" ? `${"fa-regular fa-eye-slash fa-xl"} ${design.eyeIcon}` : `${"fa-regular fa-eye fa-xl"} ${design.eyeIcon}`}
                                            onClick={() => passMode === "text" ? setPassMode("password") : setPassMode("text")}
                                        />
                                        <Validator>
                                            <RequiredRule message={t('passwordIsRequired')} />
                                        </Validator>
                                    </TextBox>
                                </SimpleItem>
                                <SimpleItem colSpan={1} >
                                    <i
                                        className={`${"fa-solid fa-key fa-2xl"} ${design.keyIcon} ${design.tooltip}`}
                                        onClick={e => {
                                            const generatedPassword = MakeId(10) + MakeSpecialChar(1) + MakeInt(5);
                                            setAutoFill(true);
                                            onValueChanged("password", generatedPassword);
                                            setAutoFill(false);
                                            onValueChanged("confirmPassword", generatedPassword);
                                        }}                                                                               
                                        >
                                        <label className={design.tooltipText}>{t("generatePassword")}</label>
                                    </i>
                                </SimpleItem>
                                <SimpleItem colSpan={1}>
                                    <i
                                        className={`${"fa-solid fa-copy fa-2xl"} ${design.keyIcon} ${design.tooltip}`}
                                        style={{marginLeft:"-4vw"}}
                                        onClick={e => copyTextToClipboard()}                                                                               
                                    >
                                        <label className={design.tooltipCopyText}>{t("copy")}</label>
                                    </i> 
                                </SimpleItem>
                                <SimpleItem colSpan={5}>
                                    <div className="userDetailsConfirmationPassField">
                                        <TextBox
                                            id="passField"
                                            label={t('confirmPassword')}
                                            labelMode='static'
                                            value={password.confirmPassword}
                                            onValueChange={e => onValueChanged("confirmPassword", e)}
                                            className={design.passwordField}
                                            mode={passMode}
                                            disabled={(props.initData.signOnMethod === SignOnMethod.AzureSingleSignOn && !props.user?.permissions?.includes(TMSPermissions.TMS_Admin))}
                                        >
                                            <i
                                                className={passMode === "text" ? `${"fa-regular fa-eye-slash fa-xl"} ${design.eyeIcon}` : `${"fa-regular fa-eye fa-xl"} ${design.eyeIcon}`}
                                                onClick={() => passMode === "text" ? setPassMode("password") : setPassMode("text")}
                                            />
                                            <Validator>
                                                <RequiredRule message={t('passwordIsRequired')} />
                                                <CompareRule message={t('passwordAndConfirmPasswordDoNotMatch')} comparisonTarget={passwordComparison} />
                                            </Validator>
                                        </TextBox>
                                    </div>
                                </SimpleItem>
                            </Form>
                        </ValidationGroup>
                    </GroupItemForm>
                </Form>

            </div>
            <div className='row' style={{ marginTop: 20, padding: 10 }}>
                <div className='rightColumn'>
                    <Button
                        className='tes-modal-btn-add'
                        onClick={onSave}
                        text={t('save')}
                    />
                </div>
            </div>
        </React.Fragment>
    );


}
export default Password;