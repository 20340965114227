/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useEffect, useState, useCallback } from "react";
import { loadModules } from "esri-loader";
import { TesMapModule } from "../../types/general/enums/generalEnums";
import { FieldType } from "../../types/field/enums/fieldEnums";
import { Button } from "devextreme-react/button";
import { LocationType } from "../../types/infrastructure/enums/infrastructureEnums";
import "./generalGisMap.scss";
import { useTranslation } from 'react-i18next';


// hooks allow us to create a map component as a function
export function GeneralGisMapNew({
  initDataGis,
  lstRoadSegmentGeoIds,
  lstIntersectionGeoIds,
  lstLatLng,
  tesModule,
  filterByGeoId,
  filterByLatLong,
  go2Collision,
  go2Intersection,
  go2RoadSegment,
  go2SignSupport,
  selectLocation,
  handleLocationChangeDirectly,
  addLocationFromMap,
  isLocked,
  lstRanking
}) {
  // create a ref to element to be used as the map's container
  const mapEl = useRef(null);
  const { t } = useTranslation();

  const _view = useRef(null);
  const _selectionIsEnable = (tesModule === TesMapModule.Collision || tesModule === TesMapModule.Infrastructure || tesModule === TesMapModule.AreaDetails
    || tesModule === TesMapModule.Sign || tesModule === TesMapModule.Support || tesModule === TesMapModule.PatrolArea || tesModule === TesMapModule.Study || tesModule === TesMapModule.AADT);

  const _moveMapIsEnable = (tesModule === TesMapModule.CollisionDetails || tesModule === TesMapModule.InfrastructureDetails);
  const _intersectionLayer = useRef(null);
  const _roadSegmentLayer = useRef(null);
  const _map = useRef(null);
  const _sketchViewModel = useRef(null);
  const _polygonGraphicsLayer = useRef(null);
  const [viewIsLoaded, setViewIsLoaded] = useState(false);
  const [extentIsFull, setExtentIsFull] = useState(false);


  const [callForRemoveAllHighLight, setCallForRemoveAllHighLight] = useState(0);


  const [lstRoadSegmentHighlighted, setLstRoadSegmentHighlighted] = useState([]);
  const [lstIntersectionHighlighted, setIntersectionHighlighted] = useState([]);
  const [selectedArea, setSelectedArea] = useState({});
  const [selectedData, setSelectedData] = useState([]);


  const [lstLocalSelectedRoadSegment, setLstLocalSelectedRoadSegment] = useState([]);
  const [lstLocalSelectedIntersection, setLstLocalSelectedIntersection] = useState([]);

  const [showOverlayText, setShowOverlayText] = useState(false)
  //const [measurementMode, setMeasurementMode] = useState(false);
  var selectedHighlighted = null;
  var selectedLongitude = 0;
  var selectedLatitude = 0;
  var selectedXCoord = 0;
  var selectedYCoord = 0;
  var measurementMode = false
  var roadSegmentUId = "";
  var intersectionUId = "";

  // use a side effect to create the map after react has rendered the DOM
  useEffect(
    () => {
      // define the view here so it can be referenced in the clean up function
      // first lazy-load the esri classes
      loadModules(
        [
          "esri/views/MapView",
          "esri/layers/FeatureLayer",
          "esri/Map",
          "esri/widgets/Sketch/SketchViewModel",
          "esri/layers/GraphicsLayer",
          "esri/geometry/support/webMercatorUtils",
          "esri/core/reactiveUtils",
          "esri/widgets/LayerList",
          "esri/widgets/Expand",
          "esri/widgets/Print",
          "esri/widgets/Measurement",
        ],
        {
          css: true,
        }
      ).then(
        ([
          //  esriConfig,
          MapView,
          FeatureLayer,
          Map,
          SketchViewModel,
          GraphicsLayer,
          webMercatorUtils,
          reactiveUtils,
          LayerList,
          Expand,
          Print,
          Measurement
        ]) => {
          if (initDataGis === null || _view.current !== null) return;
          //Load the intersection layer
          var intersectionsLayer = new FeatureLayer({
            apiKey: initDataGis.arcGisApiKey,
            title: "Intersection",
            outFields: [initDataGis.intersectionGeoIdName],
            url: initDataGis.intersectionFileAddress,
            copyright: "TNS",
            minScale: 0, // Always visible
            maxScale: 0, // Always visible,
            popupTemplate: {
              actions: getIntersectionPopupAction(),
              content: [
                {
                  type: "fields",
                  fieldInfos: [
                    {
                      fieldName: initDataGis.intersectionGeoIdName,
                      label: "Geo ID",
                    },
                  ],
                },
              ],
            },
            renderer: {
              type: "simple",
              symbol: {
                type: "simple-marker",
                color: "#FFFF00",
                size: 5,
                outline: {
                    color: [0, 0, 0],
                    width: 1,
                }
            }
          }
          });
          _intersectionLayer.current = intersectionsLayer;
          intersectionUId = intersectionsLayer.uid;

          // setIntersectionLayer(intersectionLayer);
          //Load the road segment layer
          var roadSegmentLayer = new FeatureLayer({
            url: initDataGis.midblockFileAddress,
            apiKey: initDataGis.arcGisApiKey,
            minScale: 0, // Always visible
            maxScale: 0, // Always visible,
            title: "Road Segment",
            copyright: "TNS",
            outFields: [initDataGis.midblockGeoIdName],
            popupTemplate: {
              // title: "{FULLNAME}",
              lastEditInfoEnabled: false,
              actions: getRoadSegmentPopupAction(),
              content: [
                {
                  type: "fields",
                  fieldInfos: [
                    {
                      fieldName: initDataGis.midblockGeoIdName,
                      label: "Geo ID",
                    },
                  ],
                },
              ],
            },
            renderer: {
              type: "simple",
              symbol: {
                  type: 'simple-line', // Use a simple fill symbol
                  color: [0, 0, 0], // Set the color to black (RGB values)
                  size: 1
                  // outline: {
                  //   color: [0, 0, 0], // Set the outline color to black (RGB values)
                  //   width: 1 // Set the outline width
                  // }
              }
          }
          });
          _roadSegmentLayer.current = roadSegmentLayer;
          roadSegmentUId = roadSegmentLayer.uid;

          //Setup Graphic Layer fro Drawing
          if (_selectionIsEnable) {
            var polygonGraphicsLayer = new GraphicsLayer({
              title: "Selected Area",
            });
            _polygonGraphicsLayer.current = polygonGraphicsLayer;
          }
          //Load customer Layers
          let lstLayers = [];
          initDataGis.gisLayers.forEach((layer) => {
            lstLayers.push(
              new FeatureLayer({
                url: layer.url,
                title: layer.name,
                popupTemplate: {
                  lastEditInfoEnabled: false,
                  // content: [
                  //   {
                  //     type: "fields",
                  //     fieldInfos: [
                  //       {
                  //         fieldName: initDataGis.midblockGeoIdName,
                  //         label: "GeoID",
                  //       },
                  //     ],
                  //   },
                  // ],
                },
              })
            );
          });

          //Load map and layers
          var map = new Map({
            basemap: "osm",
            layers: [intersectionsLayer, roadSegmentLayer, ...lstLayers],
          });
          _map.current = map;

          if (_selectionIsEnable) {
            map.add(polygonGraphicsLayer);
          }
          // and we show that map in a container
          var view = new MapView({
            map: map,
            center: [initDataGis.latitude, initDataGis.longitude],
            // use the ref as a container
            container: mapEl.current,

          });


          view.when(() => {
            console.log("MapView loaded successfully");

            Promise.all([
                intersectionsLayer.queryExtent(),
                roadSegmentLayer.queryExtent()
            ]).then((extents) => {
                const combinedExtent = extents.reduce((acc, extent) => {
                    return acc ? acc.union(extent.extent) : extent.extent;
                }, null);

                if (combinedExtent) {
                    view.goTo(combinedExtent.expand(1.1)); // Expand to add some padding
                }
            }).catch((error) => {
                console.error("Error zooming to layers: ", error);
            });
        }).catch((error) => {
            console.error("Error loading MapView: ", error);
        });


          view.whenLayerView(roadSegmentLayer).then(() => {
            view.goTo({ target: roadSegmentLayer.fullExtent.expand(1) })
        });
        
          const measurementWidget = new Measurement();
          measurementWidget.view = view;
          view.ui.add(measurementWidget, "bottom-right");
          const distanceButton = document.getElementById("distance");
          const clearButton = document.getElementById('clear-distance');

          if (distanceButton) {
            distanceButton.addEventListener("click", function () {
              measurementMode = true;
              measurementWidget.activeTool = "distance";
            });
            clearButton.addEventListener("click", function () {
              measurementWidget.clear();
              measurementMode = false;

            });
          }



          view.popup.dockEnabled = true;

          if (_moveMapIsEnable) {
            view.ui.add("go-to-location", "top-left");

            view.ui.add("go-to-coordinate", "top-left");
          }

          if (_selectionIsEnable) {
            // add the select by polygon button the view
            view.ui.add("select-by-polygon", "top-left");
            const selectButton = document.getElementById("select-by-polygon");
            // click event for the button
            selectButton.addEventListener("click", function () {
              view.popup.close();
              // ready to draw a polygon
              _sketchViewModel.current.create("polygon");
            });
          }

          if (_selectionIsEnable) {

            view.ui.add("clear-polygon", "top-left");
            const removeButton = document.getElementById("clear-polygon");

            // click event for clear the button
            removeButton.addEventListener("click", function () {
              clearUpSelection();
            });
            // create a new sketch view model set its layer
            _sketchViewModel.current = new SketchViewModel({
              view: view,
              layer: _polygonGraphicsLayer.current,
              pointSymbol: {
                type: "simple-marker",
                color: [255, 255, 255, 0],
                size: "1px",
                outline: {
                  color: "gray",
                  width: 0,
                },
              },
            });
          }

          view.on("click", function (evt) {
            var screenPoint = evt.screenPoint;
            // the hitTest() checks to see if any graphics in the view
            // intersect the given screen point
            view.hitTest(screenPoint).then(getGraphics);
            if (tesModule === TesMapModule.CollisionDetails || tesModule === TesMapModule.StudyDetails) {
              selectedLongitude = evt.mapPoint.longitude;
              selectedLatitude = evt.mapPoint.latitude;
              selectedXCoord = evt.mapPoint.x;
              selectedYCoord = evt.mapPoint.y;
            }
          });
          if (tesModule === TesMapModule.CollisionDetails || tesModule === TesMapModule.StudyDetails) {
            view.on("double-click", ["Control"], function (event) {
              view.popup.close();
              view.graphics.removeAll();
              handleLocationChangeDirectly({
                latitude: event.mapPoint.latitude,
                longitude: event.mapPoint.longitude,
                xCoord: event.mapPoint.x,
                yCoord: event.mapPoint.y,
              });
              createGraphic(
                event.mapPoint.latitude,
                event.mapPoint.longitude,
                null
              );
              event.stopPropagation();
            });
          }



          //Actions for Graphic Popups
          view.when(function () {
            var popup = view.popup;
            popup.viewModel.on("trigger-action", function (event) {
              if (event.action.id === "new-collision-intersection") {
                // var attributes = popup.viewModel.selectedFeature.attributes;
                //addCollisionFromMap(attributes[initDataGis.intersectionGeoIdName], 1);
              }
              if (event.action.id === "new-collision-midblock") {
                //  let attributes = popup.viewModel.selectedFeature.attributes;
                //addCollisionFromMap(attributes[initDataGis.midblockGeoIdName], 2);
              }
              if (event.action.id === "go-to-collision") {
                var content = popup.viewModel.content;
                go2Collision(
                  content[0].fieldInfos.find((x) => x.fieldName === "id").label
                );
              }
              if (event.action.id === "go-to-midblock") {
                let attributes = popup.viewModel.selectedFeature.attributes;
                go2RoadSegment(attributes[initDataGis.midblockGeoIdName]);
              }
              if (event.action.id === "go-to-intersection") {
                let attributes = popup.viewModel.selectedFeature.attributes;
                go2Intersection(attributes[initDataGis.intersectionGeoIdName]);
              }
              if (event.action.id === "show-all-data-of-geoId") {
                let attributes = popup.viewModel.selectedFeature.attributes;
                //Intersection
                if (popup.viewModel.selectedFeature.geometry.paths === undefined) {
                  filterByGeoId([{ geoId: attributes[initDataGis.intersectionGeoIdName], locationType: LocationType.Intersection }]);
                } else {
                  filterByGeoId([{ geoId: attributes[initDataGis.midblockGeoIdName], locationType: LocationType.Midblock }]);
                }
              }
              if (event.action.id === "go-to-signSupport") {
                let content = popup.viewModel.content;
                go2SignSupport(
                  content[0].fieldInfos.find((x) => x.fieldName === "id").label
                );
              }
              if (event.action.id === "select-intersection") {
                // if (isLocked === true) {
                //   notify(t("unlockTheRecordFirst"), "error", 5000);
                //   return;
                // }
                let attributes = popup.viewModel.selectedFeature.attributes;
                selectLocation(
                  attributes[initDataGis.intersectionGeoIdName],
                  LocationType.Intersection,
                  {
                    latitude: selectedLatitude,
                    longitude: selectedLongitude,
                    xCoord: selectedXCoord,
                    yCoord: selectedYCoord,
                  }
                );
                //Remove All Highlight
                if (selectedHighlighted !== null) {
                  selectedHighlighted.remove();
                }
                setCallForRemoveAllHighLight(getRandomInt(11, 30))
                view?.whenLayerView(_intersectionLayer.current).then(function (layerView) {
                  reactiveUtils
                    .whenOnce(() => !layerView.updating)
                    .then(() => {
                      selectedHighlighted = layerView.highlight(popup.viewModel.selectedFeature);
                    })
                })
                setLstLocalSelectedIntersection([attributes[initDataGis.intersectionGeoIdName]])
                setLstLocalSelectedRoadSegment([])
                view.popup.close();
              }
              if (event.action.id === "select-roadSegment") {
                // if (isLocked === true) {
                //   notify(t("unlockTheRecordFirst"), "error", 5000);
                //   return;
                // }
                let attributes = popup.viewModel.selectedFeature.attributes;
                selectLocation(
                  attributes[initDataGis.midblockGeoIdName],
                  LocationType.Midblock,
                  {
                    latitude: selectedLatitude,
                    longitude: selectedLongitude,
                    xCoord: selectedXCoord,
                    yCoord: selectedYCoord,
                  }
                );
                //Remove All Highlight
                if (selectedHighlighted !== null) {
                  selectedHighlighted.remove();
                }
                setCallForRemoveAllHighLight(getRandomInt(11, 30))
                view?.whenLayerView(_roadSegmentLayer.current).then(function (layerView) {
                  reactiveUtils
                    .whenOnce(() => !layerView.updating)
                    .then(() => {
                      selectedHighlighted = layerView.highlight(popup.viewModel.selectedFeature);
                    })
                })
                lstIntersectionGeoIds = []
                setLstLocalSelectedRoadSegment([attributes[initDataGis.midblockGeoIdName]])
                setLstLocalSelectedIntersection([])
                view.popup.close();
              }
            });
          });


          view.when(() => {
            //Layer Management tools

            const layerList = new Expand({
              content: new LayerList({
                view: view
              }),
              view: view,
              expanded: false
            });


            //Export tools

            const printTools = new Expand({
              content: new Print({
                view: view,
                // specify your own print service
                printServiceUrl:
                  "https://utility.arcgisonline.com/arcgis/rest/services/Utilities/PrintingTools/GPServer/Export%20Web%20Map%20Task",
              }),
              view: view,
              expanded: false
            });


            // Add widget to the top right corner of the view
            view.ui.add(layerList, "top-right");
            view.ui.add(printTools, "top-right");
          });

          view.when(disableZooming);

          _view.current = view;
          if (_selectionIsEnable) {
            _sketchViewModel.current.on(
              "create",
              function (event) {
                if (event.state === "complete") {
                  // this polygon will be used to query features that intersect it
                  polygonGraphicsLayer.remove(event.graphic);
                  selectFeatures(event.graphic.geometry);
                }
              },
              []
            );
          }
          setViewIsLoaded(true);




          function getGraphics(response) {
            // the topmost graphic from the click location
            // and display select attribute values from the
            // graphic to the user
            if (response.results[0]) {
              var graphic = response.results[0].graphic;
              var attributes = graphic.attributes;
              if (tesModule === TesMapModule.Collision || tesModule === TesMapModule.Infrastructure || tesModule === TesMapModule.Study || tesModule === TesMapModule.AADT) {
                if (attributes.id) {
                  setTimeout(() => {
                    view.popup.close();
                  }, 100);
                  setTimeout(() => {
                    view.popup.open({
                      // open a popup to show some of the results
                      location: response.results[0].mapPoint,
                      title: "Collision Data",
                      actions: [
                        {
                          id: "go-to-collision",
                          image:
                            "https://staticfile.tes.ca/gisMap/car-collision.png",
                          title: "Show Collision Detail",
                        },
                      ],
                      content: [
                        {
                          type: "fields",
                          fieldInfos: [
                            {
                              fieldName: "id",
                              label: attributes.id,
                            },
                          ],
                        },
                      ],
                    });
                  }, 1000);
                }
              }
              if (
                tesModule === TesMapModule.Sign ||
                tesModule === TesMapModule.Support
              ) {
                if (attributes.id) {
                  setTimeout(() => {
                    view.popup.close();
                  }, 100);
                  setTimeout(() => {
                    view.popup.open({
                      // open a popup to show some of the results
                      location: response.results[0].mapPoint,
                      title: "Sign/Support Data",
                      actions: [
                        {
                          id: "go-to-signSupport",
                          image: "https://staticfile.tes.ca/gisMap/sign.png",
                          title:
                            tesModule === TesMapModule.Support
                              ? "Show Support Details"
                              : tesModule === TesMapModule.Sign
                                ? "Show Sign Details"
                                : "",
                        },
                      ],
                      content: [
                        {
                          type: "fields",
                          fieldInfos: [
                            {
                              fieldName: "id",
                              label: attributes.id,
                            },
                          ],
                        },
                      ],
                    });
                  }, 1000);
                }
              }
            }
          }


          //Functions
          /****************************************************
           * Selects features from the csv layer that intersect
           * a polygon that user drew using sketch view model
           ****************************************************/
          function selectFeatures(geometry) {
            findMinMaxSelectedAreas(geometry.rings[0]);
            // create a query and set its geometry parameter to the
            // polygon that was drawn on the view
            const query = {
              geometry: geometry,
              outFields: ["*"],
              spatialRelationship: "contains",
              returnGeometry: true,
            };
            const finalData = [];
            // query graphics from the layer view. Geometry set for the query
            // can be polygon for point features and only intersecting geometries are returned

            //Intersection Layer Query
            view
              ?.whenLayerView(_intersectionLayer.current)
              .then(function (layerView) {
                reactiveUtils
                  .whenOnce(() => !layerView.updating)
                  .then(() => {
                    layerView
                      .queryFeatures(query)
                      .then(function (results) {
                        const graphics = results.features;
                        if (graphics.length > 0 && finalData.length === 0) {
                          // zoom to the extent of the polygon with factor 2
                          view
                            .goTo(geometry.extent.expand(2))
                            .catch(function (error) {
                              if (error.name !== "AbortError") {
                                console.error(error);
                              }
                            });
                        }
                        if (results.features.length === 0) {
                          layerView.featureEffect = null;
                        } else {
                          layerView.featureEffect = {
                            filter: {
                              geometry: query.geometry,
                              spatialRelationship: "contains",
                              distance: query.distance,
                              units: query.units,
                            },
                            excludedEffect:
                              "blur(5px) grayscale(90%) opacity(40%)",
                            includedEffect: " contrast(200%) saturate(200%)",
                          };
                        }
                        // get the attributes to add to selected Data
                        const data = graphics.map(function (feature, i) {
                          return (
                            Object.keys(feature.attributes)
                              .filter(function (key) {
                                return (
                                  [initDataGis.intersectionGeoIdName].indexOf(
                                    key
                                  ) !== -1
                                );
                              })
                              // need to create key value pairs from the feature
                              // attributes so that info can added in selected data
                              .reduce(function (obj, key) {
                                obj[key] = feature.attributes[key];
                                return obj;
                              }, {})
                          );
                        });
                        finalData.push(...data.map(x => ({ geoId: x[initDataGis.intersectionGeoIdName], locationType: LocationType.Intersection })));

                      })
                      .catch();
                  });
              });

            //Road Segment Layer Query
            view
              ?.whenLayerView(_roadSegmentLayer.current)
              .then(function (layerView) {
                reactiveUtils
                  .whenOnce(() => !layerView.updating)
                  .then(() => {
                    layerView
                      .queryFeatures(query)
                      .then(function (results) {
                        const graphics = results.features;
                        if (graphics.length > 0 && finalData.length === 0) {
                          // zoom to the extent of the polygon with factor 2
                          view
                            .goTo(geometry.extent.expand(2))
                            .catch(function (error) {
                              if (error.name !== "AbortError") {
                                console.error(error);
                              }
                            });
                        }
                        if (results.features.length === 0) {
                          layerView.featureEffect = {
                            includedEffect:
                              "blur(5px) grayscale(90%) opacity(40%)",
                          };
                        } else {
                          layerView.featureEffect = {
                            filter: {
                              geometry: query.geometry,
                              spatialRelationship: "contains",
                              distance: query.distance,
                              units: query.units,
                            },
                            excludedEffect:
                              "blur(2px) saturate(200%) opacity(40%)",
                          };
                        }
                        // get the attributes to add to selected Data
                        const data = graphics.map(function (feature, i) {
                          return (
                            Object.keys(feature.attributes)
                              .filter(function (key) {
                                return (
                                  [initDataGis.midblockGeoIdName].indexOf(
                                    key
                                  ) !== -1
                                );
                              })
                              // need to create key value pairs from the feature
                              // attributes so that info can added in selected data
                              .reduce(function (obj, key) {
                                obj[key] = feature.attributes[key];
                                return obj;
                              }, {})
                          );
                        });
                        finalData.push(...data.map(x => ({ geoId: x[initDataGis.midblockGeoIdName], locationType: LocationType.Midblock })));
                        setSelectedData(finalData);
                      })
                      .catch();
                  });
              });
          }

          function findMinMaxSelectedAreas(lstPoints) {
            lstPoints = lstPoints.map((x) => {
                return webMercatorUtils.xyToLngLat(x[0], x[1]);
            });
            const lstX = lstPoints.flatMap((x) => x[0]);
            const lstY = lstPoints.flatMap((x) => x[1]);
            var xMax = Math.max.apply(null, lstX);
            var xMin = Math.min.apply(null, lstX);
            var yMax = Math.max.apply(null, lstY);
            var yMin = Math.min.apply(null, lstY);
            setSelectedArea({
                longitudeMax: xMax,
                longitudeMin: xMin,
                latitudeMax: yMax,
                latitudeMin: yMin,
            });
        }


          function clearUpSelection() {
            view.popup.close();
            setSelectedData([])
            view
              ?.whenLayerView(_intersectionLayer.current)
              .then(function (layerView) {
                reactiveUtils
                  .whenOnce(() => !layerView.updating)
                  .then(() => {
                    layerView.featureEffect = null;
                  });
              });
            view
              ?.whenLayerView(_roadSegmentLayer.current)
              .then(function (layerView) {
                reactiveUtils
                  .whenOnce(() => !layerView.updating)
                  .then(() => {
                    layerView.featureEffect = null;
                  });
              });
          }




        }
      );
      return () => {
        //  clean up the map view
        // if (!!_view.current) {
        //   _view.current.destroy();
        //   _view.current = null;
        // }
      };
    },
    // only re-load the map if the Gis data has changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initDataGis]
  );

  //Call find RoadSegments in initial call and change of location from props
  useEffect(() => {
    setLstLocalSelectedRoadSegment(lstRoadSegmentGeoIds)
    findRoadSegments(lstRoadSegmentGeoIds, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewIsLoaded, lstRoadSegmentGeoIds]);

  //Query Road Segment layer and highlight them
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const findRoadSegments = useCallback((lstGeoIds, forceGotoLoc) => {
    // define the view here so it can be referenced in the clean up function
    loadModules(["esri/core/reactiveUtils"], {
      css: true,
    }).then(([reactiveUtils]) => {
      //Remove All Highlight
      if (lstRoadSegmentHighlighted.length > 0) {
        lstRoadSegmentHighlighted.forEach(function (highlight) {
          highlight.remove();
        });
        setLstRoadSegmentHighlighted([])
      }
      if ((!viewIsLoaded) || lstGeoIds.length === 0) return;

      const layer = _roadSegmentLayer.current;
      const view = _view.current;

      //It's important to query run on all features
      layer.load().then(() => {
        // Set the view extent to the data extent
        view.extent = layer.fullExtent;
        setExtentIsFull(true);
      });

      var strRoadSegmentQuery = "";
      if (lstGeoIds) {
        lstGeoIds.forEach((s) => {
          strRoadSegmentQuery +=
            initDataGis.midblockGeoIdType === FieldType.String
              ? initDataGis.midblockGeoIdName + "='" + s + "' OR "
              : initDataGis.midblockGeoIdName + "=" + s + " OR ";
        });
        strRoadSegmentQuery = strRoadSegmentQuery.substring(
          0,
          strRoadSegmentQuery.length - 4
        );
      }
      if (strRoadSegmentQuery === "") {
        return;
      }
      const query = {
        outFields: ["*"],
        where: strRoadSegmentQuery,
        returnGeometry: true,
      };

      // queries when view and layer has been loaded
      view?.whenLayerView(layer).then(function (layerView) {
        reactiveUtils
          .whenOnce(() => !layerView.updating)
          .then(() => {
            layerView
              .queryFeatures(query)
              .then(function (results) {
                const graphics = results.features;
                const highlight = layerView.highlight(graphics);
                setLstRoadSegmentHighlighted([...lstRoadSegmentHighlighted, highlight])
                setExtentIsFull(false);
                if (((lstLatLng === null || lstLatLng?.length === 0 || lstLatLng[0]?.latitude === undefined || lstLatLng[0]?.latitude === null) && graphics.length > 0 && lstRoadSegmentGeoIds.length === 1 && lstIntersectionGeoIds.length === 0) || forceGotoLoc) {
                  view.goTo(
                    graphics[0].geometry,
                    {
                      animate: true,
                      duration: 2000,
                      easing: "ease-out"
                    }
                  )
                }
              })
              .catch();
          });
      });
    });
  });

  //Call findIntersection in initial call and change of location from props
  useEffect(() => {
    setLstLocalSelectedIntersection(lstIntersectionGeoIds)
    findIntersections(lstIntersectionGeoIds, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewIsLoaded, lstIntersectionGeoIds]);


  //Query Intersection layer and highlight them
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const findIntersections = useCallback((lstGeoIds, forceGotoLoc) => {
    // define the view here so it can be referenced in the clean up function
    loadModules(["esri/core/reactiveUtils"], {
      css: true,
    }).then(([reactiveUtils]) => {
      //Remove All Highlight
      if (lstIntersectionHighlighted.length > 0) {
        lstIntersectionHighlighted.forEach(function (highlight) {
          highlight.remove();
        });
        setIntersectionHighlighted([]);
      }
      if (!viewIsLoaded || lstGeoIds.length === 0) return;
      const layer = _intersectionLayer.current;
      const view = _view.current;
      //It's important to query run on all features
      layer.load().then(() => {
        // Set the view extent to the data extent
        view.extent = layer.fullExtent;
        setExtentIsFull(true);
      });
      var strIntersectionQuery = "";
      if (lstGeoIds) {
        lstGeoIds.forEach((s) => {
          strIntersectionQuery +=
            initDataGis.intersectionGeoIdType === FieldType.String
              ? initDataGis.intersectionGeoIdName + "='" + s + "' OR "
              : initDataGis.intersectionGeoIdName + "=" + s + " OR ";
        });
        strIntersectionQuery = strIntersectionQuery.substring(
          0,
          strIntersectionQuery.length - 4
        );
      }
      if (strIntersectionQuery === "") {
        return;
      }
      const query = {
        outFields: ["*"],
        where: strIntersectionQuery,
        returnGeometry: true,
      };
      // console.log("strIntersectionQuery" + strIntersectionQuery)

      // queries when view and layer has been loaded
      view?.whenLayerView(layer).then(function (layerView) {
        reactiveUtils
          .whenOnce(() => !layerView.updating)
          .then(() => {
            layerView
              .queryFeatures(query)
              .then(function (results) {
                const graphics = results.features;
                var highlight = layerView.highlight(graphics);
                setIntersectionHighlighted([
                  ...lstIntersectionHighlighted,
                  highlight,
                ]);
                if (((lstLatLng === null || lstLatLng?.length === 0 || lstLatLng[0]?.latitude === undefined || lstLatLng[0]?.latitude === null) && graphics.length > 0 && lstIntersectionGeoIds.length === 1 && lstRoadSegmentGeoIds.length === 0) || forceGotoLoc === true) {
                  view.goTo(
                    graphics[0].geometry,
                    {
                      animate: true,
                      duration: 2000,
                      easing: "ease-out"
                    }
                  )
                //  view.zoom = 13;
                }
              })
              .catch();
          });
      });
    });
  });






  //Add Points on the map base on Lan/Lng
  useEffect(() => {
    if (viewIsLoaded) {
      _view.current.graphics.removeAll();

    }
    // console.log("lstLatLng " + JSON.stringify(lstLatLng))

    if (lstLatLng.length > 0 && viewIsLoaded) {
      lstLatLng.forEach((loc) => {
        if (loc.latitude !== 0.0 && loc.longitude !== 0.0) {
          createGraphic(loc.latitude, loc.longitude, loc.id);
        }
      });
    }
    // if (lstLatLng.length === 1 && viewIsLoaded && lstLatLng[0].latitude !== 0 && extentIsFull === true) {
    //   _view.current.goTo({
    //     center: [lstLatLng[0].longitude, lstLatLng[0].latitude],
    //     zoom: 15
    //   });
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lstLatLng, viewIsLoaded]);

  //Move the map if there is just one Lat/Lng
  useEffect(() => {
    // if ( viewIsLoaded) {
    //   _view.current.graphics.removeAll();
    // }


    if (lstLatLng.length === 1 && viewIsLoaded && lstLatLng[0].latitude !== 0) {
      _view.current.goTo({
        center: [lstLatLng[0].longitude, lstLatLng[0].latitude],
        zoom: 15
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lstLatLng, viewIsLoaded, extentIsFull]);


  //Remove Highlight when we select the inf on the map
  useEffect(() => {
    if (callForRemoveAllHighLight > 10) {
      if (selectedHighlighted !== null) {
        selectedHighlighted.remove();
      }
      if (lstRoadSegmentHighlighted.length > 0) {
        lstRoadSegmentHighlighted.forEach(function (highlight) {
          highlight.remove();
        });
        setLstRoadSegmentHighlighted([])
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callForRemoveAllHighLight]);


  useEffect(() => {
    if (lstRanking == null) return;
    if (lstRanking !== null && lstRanking !== undefined && lstRanking?.length > 0) {
      showRanking(lstRanking)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lstRanking, viewIsLoaded]);

  // Add Graphic Point to map
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const createGraphic = useCallback((lat, long, id) => {
    // define the view here so it can be referenced in the clean up function
    loadModules(["esri/Graphic"], {
      css: true,
    }).then(([Graphic]) => {
      // First create a point geometry
      var point = {
        type: "point", // autocasts as new Point()
        longitude: long,
        latitude: lat,
      };

      // Create a symbol for drawing the point
      var markerSymbol = {
        type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
        color: "#12492f",
        size: 8,
      };

      var att = {
        id: id,
      };

      // Create a graphic and add the geometry and symbol to it
      var pointGraphic = new Graphic({
        geometry: point,
        symbol: markerSymbol,
        attributes: att,
      });

      // Add the graphics to the view's graphics layer
      _view.current.graphics.add(pointGraphic);
    });
  });




  const showRanking = useCallback((lstRanking) => {
    // define the view here so it can be referenced in the clean up function
    loadModules(["esri/core/reactiveUtils", "esri/layers/FeatureLayer", "esri/layers/support/AggregateField", "esri/Graphic", "esri/geometry/Point", "esri/symbols/SimpleMarkerSymbol", "esri/renderers/UniqueValueRenderer",
      "esri/geometry/support/webMercatorUtils", "esri/widgets/Legend"], {
      css: true,
    }).then(([reactiveUtils, FeatureLayer, AggregateField, Graphic, Point, SimpleMarkerSymbol, UniqueValueRenderer, webMercatorUtils, Legend]) => {
      if (!viewIsLoaded || lstRanking.length === 0) return;

      var lstGraphics = []
      const intersectionLayer = _intersectionLayer.current;
      var strIntersectionQuery = initDataGis.intersectionGeoIdName + " In (";
      if (lstRanking) {
        lstRanking.filter(x => x.locationType === LocationType.Intersection).forEach((s) => {
          strIntersectionQuery +=
            initDataGis.intersectionGeoIdType === FieldType.String
              ? "'" + s.geoId + "',"
              : s.geoId + ",";
        });
        strIntersectionQuery = strIntersectionQuery.substring(
          0,
          strIntersectionQuery.length - 1
        );
        strIntersectionQuery += ")"
      }


      if (lstRanking.filter(x => x.locationType === LocationType.Intersection).length == 0) {
        strIntersectionQuery = "";
      }
      const intersectionQuery = {
        outFields: ["*"],
        where: strIntersectionQuery,
        returnGeometry: true,
      };

      // queries when view and layer has been loaded
      intersectionLayer.queryFeatures(intersectionQuery).then(function (intersectionResults) {
        const graphics = intersectionResults.features;
        if (strIntersectionQuery !== "") {

          for (let index = 0; index < graphics.length; index++) {
            var rankObj = lstRanking.find(x => x.geoId == graphics[index].attributes[initDataGis.intersectionGeoIdName]);
            graphics[index].attributes["geoId"] = graphics[index].attributes[initDataGis.intersectionGeoIdName].toString()
            graphics[index].attributes["rank"] = rankObj?.rank
            Object.keys(rankObj.properties).forEach(name => {
              graphics[index].attributes[name] = rankObj.properties[name];
            });
          }
          lstGraphics = [...lstGraphics, ...graphics]
        }
        const roadsegmentLayer = _roadSegmentLayer.current;
        var strRoadSegmentQuery = initDataGis.midblockGeoIdName + " In (";
        if (lstRanking) {
          lstRanking.filter(x => x.locationType === LocationType.Midblock).forEach((s) => {
            strRoadSegmentQuery +=
              initDataGis.midblockGeoIdType === FieldType.String
                ? "'" + s.geoId + "',"
                : s.geoId + ",";
          });

          strRoadSegmentQuery = strRoadSegmentQuery.substring(
            0,
            strRoadSegmentQuery.length - 1
          );
          strRoadSegmentQuery += ")"

          if (strRoadSegmentQuery === "") {
            return;
          }
        }

        if (lstRanking.filter(x => x.locationType === LocationType.Midblock).length == 0) {
          strRoadSegmentQuery = "";
        }
        const roadSegmentQuery = {
          outFields: ["*"],
          where: strRoadSegmentQuery,
          returnGeometry: true,
        };
        if (strRoadSegmentQuery != "") {
          if (lstRanking && lstRanking.properties) {
            console.log("roadSegment" + lstRanking.properties);
            // Now you can safely use lstRanking.properties
          } else {
            console.error('lstRanking or lstRanking.properties is not defined');
            // Handle the absence of properties as needed
          }
          // queries when view and layer has been loaded
          roadsegmentLayer.queryFeatures(roadSegmentQuery).then(function (RoadSegmentResults) {
            var graphics = RoadSegmentResults.features;
            var lstNewRoadSegmentGraphics = graphics.filter(x => x.geometry.paths.length > 0).map(function (place) {
              var paths = place.geometry.paths[0];
              const res = getAveragePaths(paths);
              var lineCenter = new Point(res[0], res[1], _map.current.spatialReference);
              var lt = webMercatorUtils.xyToLngLat(lineCenter.x, lineCenter.y)

              return new Graphic({

                attributes: {
                  geoId: place.attributes[initDataGis.midblockGeoIdName].toString(),
                  rank: lstRanking.find(x => x.geoId == place.attributes[initDataGis.midblockGeoIdName])?.rank,

                },
                geometry: {
                  type: 'point',
                  latitude: lt[1],
                  longitude: lt[0]
                }
              });
            }
            );

            lstGraphics = [...lstGraphics, ...lstNewRoadSegmentGraphics]
          })
            .catch(x => {
              // setShowLoading(false)
              console.log(x)
            });
        }
        const rankClass = {
          labelExpressionInfo: {
            expression: "$feature.rank"
          },
          labelPlacement: "above-right",
        };
        rankClass.symbol = createTextSymbol("#424242");


        var rankLayer = new FeatureLayer({
          id: "RankLayer",
          source: lstGraphics,
          objectIdField: "ObjectId",
          title: "Rank",
          outFields: ["*"],
          fields: [
            {
              alias: "GeoID",
              defaultValue: null,
              editable: true,
              length: -1,
              name: "geoId",
              nullable: true,
              type: "string"

            },
            {
              alias: "Rank",
              defaultValue: null,
              editable: true,
              length: -1,
              name: "rank",
              nullable: true,
              type: "double"
            },
            ...(lstRanking[0]?.properties
              ? Object.keys(lstRanking[0].properties).map(name => ({
                alias: name,
                defaultValue: null,
                editable: true,
                length: -1,
                name: name,
                nullable: true,
                type: "string"
              }))
              : []),

          ],

          renderer: {
            type: "class-breaks",
            field: "rank",
            classBreakInfos: [
              {
                minValue: 1,
                maxValue: 10,
                symbol: {
                  type: "simple-marker",
                  color: "#FF0000",
                  width: "2px",
                },
                label: "1-10"
              },
              {
                minValue: 11,
                maxValue: 20,
                symbol: {
                  type: "simple-marker",
                  color: "#FF4500",
                  width: "2px",
                },
                label: "10-20"
              },
              {
                minValue: 21,
                maxValue: 30,
                symbol: {
                  type: "simple-marker",
                  color: "#FF8C00",
                  width: "2px",
                },
                label: "20-30"
              },
              {
                minValue: 31,
                maxValue: 40,
                symbol: {
                  type: "simple-marker",
                  color: "#FFD700",
                  width: "2px",
                },
                label: "30-40"
              },
              {
                minValue: 41,
                maxValue: 50,
                symbol: {
                  type: "simple-marker",
                  color: "#FFFF00",
                  width: "2px",
                },
                label: "40-50"
              },
              {
                minValue: 51,
                maxValue: 60,
                symbol: {
                  type: "simple-marker",
                  color: "#00FF00",
                  width: "2px",
                },
                label: "50-60"
              },
              {
                minValue: 61,
                maxValue: 70,
                symbol: {
                  type: "simple-marker",
                  color: "#ADD8E6",
                  width: "2px",
                },
                label: "60-70"
              },
              {
                minValue: 71,
                maxValue: 80,
                symbol: {
                  type: "simple-marker",
                  color: "#1E90FF",
                  width: "2px",
                },
                label: "70-80"
              },
              {
                minValue: 81,
                maxValue: 90,
                symbol: {
                  type: "simple-marker",
                  color: "#4169E1",
                  width: "2px",
                },
                label: "80-90"
              },
              {
                minValue: 91,
                maxValue: 100,
                symbol: {
                  type: "simple-marker",
                  color: "#0000FF",
                  width: "2px",
                },
                label: "90-100"
              },
              {
                minValue: 101,
                symbol: {
                  type: "simple-marker",
                  color: "#B0C4DE",
                  width: "2px",
                },
                label: " > 100"
              },
            ],
            defaultSymbol: {
              type: "simple-marker",
              size: 5,
              color: "gray" // Default symbol for features outside the specified ranges
            }

          },

          labelingInfo: [
            rankClass
          ],
          // popupTemplates can still be viewed on
          popupTemplate: {
            title: "Rank",
            content: [
              {
                type: "fields",
                fieldInfos: [
                  {
                    fieldName: "geoId",
                    label: "Geo ID",
                  },
                  {
                    fieldName: "rank",
                    label: "Rank",
                  },
                  ...(lstRanking[0]?.properties
                    ? Object.keys(lstRanking[0].properties).map(name => ({
                      fieldName: name,
                      label: name,
                    }))
                    : []),
                ],
              },
            ],
          }
        });

        _map.current.add(rankLayer);

        //setShowLoading(false)
      })
        .catch(x => {
          //setShowLoading(false)
          console.log(x)
        });
    })


  });



  function createTextSymbol(color) {
    return {
      type: "text", // autocasts as new TextSymbol()
      font: {
        size: 14,
        weight: "bold"
      },
      color: "#424242",
      haloColor: "white",
      haloSize: 1
    };
  }

  function go2SelectedLocation() {
    // console.log("lstLocalSelectedIntersection" + JSON.stringify(lstLocalSelectedIntersection))
    // console.log("lstLocalSelectedRoadSegment" + JSON.stringify(lstLocalSelectedRoadSegment))
    if (lstLocalSelectedIntersection.length === 1) {
      findIntersections(lstLocalSelectedIntersection, true)
    } else if (lstLocalSelectedRoadSegment.length === 1) {
      findRoadSegments(lstLocalSelectedRoadSegment, true)
    }
  }

  function go2FirstLatLng() {
    _view.current.goTo({
      center: [lstLatLng[0].longitude, lstLatLng[0].latitude],
      zoom: 15
    });
  }

  function getIntersectionPopupAction() {
    var lstIntersectionsActions = [];
    if (tesModule === TesMapModule.Collision) {
      lstIntersectionsActions = [
        {
          id: "show-all-data-of-geoId",
          image: "https://staticfile.tes.ca/gisMap/intersectionType.png",
          title: "Show all collisions",
        },
        {
          id: "new-collision-intersection",
          image: "https://staticfile.tes.ca/gisMap/plus.png",
          title: "Add new collision",
        },
      ];
    }
    if (tesModule === TesMapModule.Study) {
      lstIntersectionsActions = [
        {
          id: "show-all-data-of-geoId",
          image: "https://staticfile.tes.ca/gisMap/intersectionType.png",
          title: "Show all studies",
        },

      ];
    }

    if (tesModule === TesMapModule.AADT) {
      lstIntersectionsActions = [
        {
          id: "show-all-data-of-geoId",
          image: "https://staticfile.tes.ca/gisMap/intersectionType.png",
          title: "Show all AADTs",
        },

      ];
    }

    if (
      tesModule === TesMapModule.CollisionDetails ||
      tesModule === TesMapModule.StudyDetails
    ) {
      lstIntersectionsActions = [
        {
          id: "select-intersection",
          image: "https://staticfile.tes.ca/gisMap/select.png",
          title: "Select This Infrastructure",
        },
      ];
    }

    if (
      tesModule === TesMapModule.CollisionOverviewDashboard
    ) {
      lstIntersectionsActions = [
        {
          id: "go-to-intersection",
          image: "https://staticfile.tes.ca/gisMap/intersectionType.png",
          title: "Show intersection details",
        },
      ];
    }

    if (
      tesModule === TesMapModule.Infrastructure ||
      tesModule === TesMapModule.InfrastructureDetails
    ) {
      lstIntersectionsActions = [
        {
          id: "go-to-intersection",
          image: "https://staticfile.tes.ca/gisMap/intersectionType.png",
          title: "Show intersection details",
        },
      ];
    }

    if (tesModule === TesMapModule.Sign || tesModule === TesMapModule.Support) {
      lstIntersectionsActions = [
        {
          id: "show-all-data-of-geoId",
          image: "https://staticfile.tes.ca/gisMap/intersectionType.png",
          title:
            tesModule === TesMapModule.Support
              ? t("showSupports")
              : tesModule === TesMapModule.Sign
                ? t("showSigns")
                : "",
        },
      ];
    }
    return lstIntersectionsActions;
  }

  function getRoadSegmentPopupAction() {
    var lstRoadSegmentActions = [];
    if (
      tesModule === TesMapModule.Collision ||
      tesModule === TesMapModule.Infrastructure
    ) {
      lstRoadSegmentActions = [
        {
          id: "show-all-data-of-geoId",
          image: "https://staticfile.tes.ca/gisMap/midBlock.png",
          title: "Show all collisions",
        },
        {
          id: "new-collision-midblock",
          image: "https://staticfile.tes.ca/gisMap/plus.png",
          title: "Add new collision",
        },
      ];
    }

    if (
      tesModule === TesMapModule.Study
    ) {
      lstRoadSegmentActions = [
        {
          id: "show-all-data-of-geoId",
          image: "https://staticfile.tes.ca/gisMap/midBlock.png",
          title: "Show all Studies",
        },
      ];
    }
    if (
      tesModule === TesMapModule.AADT
    ) {
      lstRoadSegmentActions = [
        {
          id: "show-all-data-of-geoId",
          image: "https://staticfile.tes.ca/gisMap/midBlock.png",
          title: "Show all AADTs",
        },
      ];
    }

    if (
      tesModule === TesMapModule.CollisionDetails ||
      tesModule === TesMapModule.StudyDetails
    ) {
      lstRoadSegmentActions = [
        {
          id: "select-roadSegment",
          image: "https://staticfile.tes.ca/gisMap/select.png",
          title: "Select This Infrastructure",
        },
      ];
    }
    if (
      tesModule === TesMapModule.CollisionOverviewDashboard
    ) {
      lstRoadSegmentActions = [
        {
          id: "go-to-midblock",
          image: "https://staticfile.tes.ca/gisMap/midBlock.png",
          title: "Show Road Segment details",
        },
      ];
    }

    if (
      tesModule === TesMapModule.Infrastructure ||
      tesModule === TesMapModule.InfrastructureDetails
    ) {
      lstRoadSegmentActions = [
        {
          id: "go-to-midblock",
          image: "https://staticfile.tes.ca/gisMap/midBlock.png",
          title: "Show Road Segment details",
        },
      ];
    }

    if (tesModule === TesMapModule.Sign || tesModule === TesMapModule.Support) {
      lstRoadSegmentActions = [
        {
          id: "show-all-data-of-geoId",
          image: "https://staticfile.tes.ca/gisMap/midBlock.png",
          title:
            tesModule === TesMapModule.Support
              ? t("showSupports")
              : tesModule === TesMapModule.Sign
                ? t("showSigns")
                : "",
        },
      ];
    }
    return lstRoadSegmentActions;
  }

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
  }

  function disableZooming(view) {
    view.popup.dockEnabled = true;

    // Removes the zoom action on the popup
    view.popup.actions = [];

    // stops propagation of default behavior when an event fires
    function stopEvtPropagation(event) {
      event.stopPropagation();
    }

    function showZomeText(event) {
      event.stopPropagation();
      setShowOverlayText(true)
      setTimeout(() => {
        setShowOverlayText(false)
      }, 4000);
    }

    function disableZomeText(event) {
      setShowOverlayText(false)

    }


    // exlude the zoom widget from the default UI
    //view.ui.components = ["attribution"];

    // disable mouse wheel scroll zooming on the view
    var mouseWheelEvt = view.on("mouse-wheel", showZomeText);
    view.on("click", disableZomeText);



    // disable zooming via double-click on the view
    view.on("double-click", function (event) {
      // Check if measurement is in progress
      // Your code to execute when measurement is not in progress
      if (measurementMode === false) {
        stopEvtPropagation(event);
      }

    });
    // view.on("double-click", stopEvtPropagation);


    // disable zooming out via double-click + Control on the view
    // view.on("double-click", ["Control"], stopEvtPropagation);

    // disables pinch-zoom and panning on the view
    //view.on("drag", stopEvtPropagation);

    // disable the view's zoom box to prevent the Shift + drag
    // and Shift + Control + drag zoom gestures.
    // view.on("drag", ["Shift"], stopEvtPropagation);
    // view.on("drag", ["Shift", "Control"], stopEvtPropagation);

    // prevents zooming with the + and - keys
    view.on("key-down", function (event) {
      var prohibitedKeys = ["+", "-", "Shift", "_", "="];
      var keyPressed = event.key;
      if (prohibitedKeys.indexOf(keyPressed) !== -1) {
        event.stopPropagation();
      }
      if (event.key === 'Control') {
        if (mouseWheelEvt) {
          setShowOverlayText(false)
          mouseWheelEvt.remove();
          mouseWheelEvt = null;
        }
      } else {
        if (!mouseWheelEvt) {
          mouseWheelEvt = view.on("mouse-wheel", showZomeText);
        }
      }
    });

    view.on("key-up", function (event) {
      if (!mouseWheelEvt) {
        mouseWheelEvt = view.on("mouse-wheel", showZomeText);
      }
    });

    return view;
  }
  function layerExist(layerId) {
    var layer = _map.current.layers?.find(x => x.id === layerId)
    if (layer != null) {
      return true
    } else {
      return false
    }
  }

  function handleShowAllGeoIds() {
    filterByGeoId(selectedData);
  };
  const handleShowAllLat = () => {
    filterByLatLong(selectedArea);
  };
  function addLocations() {
    addLocationFromMap(selectedData);
  };

  function onMouseOver(e) {
    var view = _view.current;
    view.focus();
  }

  function getAveragePaths(paths) {
    var sumX = 0;
    var sumY = 0;
    for (let i = 0; i < paths.length; i++) {
      sumX = sumX + (paths[i])[0]
      sumY = sumY + (paths[i])[1]
    }
    const res = [sumX / paths.length, sumY / paths.length]
    return res;
  }
  return (
    <div className="generalGISMapNew">
      <div className="mainContainer">
        <div>
          {tesModule === TesMapModule.Collision && (
            <Button
              className="tes-modal-btn-add"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleShowAllGeoIds}
              style={{ width: "10rem", margin: "-1rem 0.5rem 0.5rem 0" }}
            // disabled={selectedData.length === 0}
            >
              {t("showAllCollisions")}
            </Button>
          )}
          {tesModule === TesMapModule.Infrastructure && (
            <Button
              className="tes-modal-btn-add"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleShowAllGeoIds}
              style={{ width: "10rem", margin: "-1rem 0.5rem 0.5rem 0" }}
            // disabled={selectedData.length === 0}
            >
              {t("showAllLocations")}
            </Button>
          )}
          {tesModule === TesMapModule.Study && (
            <Button
              className="tes-modal-btn-add"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleShowAllGeoIds}
              style={{ width: "10rem", margin: "-1rem 0.5rem 0.5rem 0" }}
            // disabled={selectedData.length === 0}
            >
              {t("showAllStudies")}
            </Button>
          )}
          {tesModule === TesMapModule.AADT && (
            <Button
              className="tes-modal-btn-add"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleShowAllGeoIds}
              style={{ width: "10rem", margin: "-1rem 0.5rem 0.5rem 0" }}
            // disabled={selectedData.length === 0}
            >
              {t("showAllAadts")}
            </Button>
          )}
          {tesModule === TesMapModule.AreaDetails && (
            <Button
              className="tes-modal-btn-add"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={addLocations}
              style={{ width: "10rem", margin: "-1rem 0.5rem 0.5rem 0" }}
            // disabled={selectedData.length === 0}
            >
              {t("addLocations")}
            </Button>
          )}
          {tesModule === TesMapModule.PatrolArea && (
            <Button
              className="tes-modal-btn-add"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={addLocations}
              style={{ width: "10rem", margin: "-1rem 0.5rem 0.5rem 0" }}
            >
              Add Locations
            </Button>
          )}
          {(tesModule === TesMapModule.Sign ||
            tesModule === TesMapModule.Support) && (
              <Button
                className="tes-modal-btn-add"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleShowAllLat}
                style={{ width: "10rem", margin: "-1rem 0.5rem 0.5rem 0" }}
              // disabled={selectedData.length === 0}
              >
                {tesModule === TesMapModule.Sign
                  ? t("showSigns")
                  : t("showSupports")}
              </Button>
            )}
        </div>

      </div>

      {/* {selectedData.length > 0 && ( */}
      {/* <div className="row justify-content-end conditional"> */}

      {/* </div> */}
      {/* )} */}
      <div className={showOverlayText ? "parent" : "parent2"}>
        {showOverlayText && <div className="text_box">{t("useCtrl+ScrollToZoomTheMap")}</div>}

        <div ref={mapEl} onMouseOver={onMouseOver} className={showOverlayText ? "map_box" : "map_box2"}>
          {_moveMapIsEnable === true &&
            <div
              id="go-to-location"
              className="esri-widget esri-widget--button esri-widget esri-interactive"
              title="Go to Selected Location"
              onClick={() => go2SelectedLocation()}
            >
              <span className="esri-icon-maps"></span>
            </div>
          }
          {_moveMapIsEnable === true &&

            <div
              id="go-to-coordinate"
              className="esri-widget esri-widget--button esri-widget esri-interactive"
              title="Go to Selected Coordinate"
              onClick={() => go2FirstLatLng()}
            >
              <span className="esri-icon-north-navigation-filled"></span>
            </div>
          }
          {_moveMapIsEnable === true &&

            <div
              id="distance"
              className="esri-widget esri-widget--button esri-widget esri-interactive esri-ui-top-right esri-ui-corner ToolsSquareShadow"
              style={{ marginTop: "6.1rem", marginRight: "0.9rem" }}
              title="Distance Measurement Tool"
            >
              <span className="esri-icon-measure-line"></span>
            </div>
          }
          {_moveMapIsEnable === true &&

            <div
              id="clear-distance"
              className="esri-widget esri-widget--button esri-widget esri-interactive esri-ui-top-right esri-ui-corner ToolsSquareShadow"
              style={{ marginTop: "8.15rem", marginRight: "0.9rem" }}
              title="Clear Measurement Tool"
            >
              <span className="esri-icon-trash"></span>
            </div>
          }
          {_selectionIsEnable === true &&
            <div
              id="select-by-polygon"
              className="esri-widget esri-widget--button esri-widget esri-interactive"
              title="Select features by polygon"
            >
              <span className="esri-icon-checkbox-unchecked"></span>
            </div>
          }
          {_selectionIsEnable === true &&

            <div
              id="clear-polygon"
              className="esri-widget esri-widget--button esri-widget esri-interactive"
              title="Clear Selection"
            >
              <span className="esri-icon-close-circled"></span>
            </div>
          }



          <div id="info" className="esri-widget"></div>
        </div>
      </div>
    </div>
  );
}
