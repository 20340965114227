//imports
import React, { useEffect, useRef, useState } from "react";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  ColumnChooser,
  Selection,
  Pager,
  Paging,
  TotalItem,
  Summary,
  Grouping,
  GroupPanel,
  FilterBuilderPopup,
  FilterPanel,
  GroupItem,
  Button as DevExpressButton,
  Export,
  Lookup,
  SortByGroupSummaryInfo,
  Search,
} from "devextreme-react/data-grid";
import { Popup } from "devextreme-react/popup";
import { Button } from "devextreme-react/button";
import { TesPut, TesGet, TesPost, RequestErrorHandling } from "../../../../../utils/rest";
import "./location.scss";
import { useTranslation } from "react-i18next";
import notify from "devextreme/ui/notify";
import { custom } from "devextreme/ui/dialog";
import { useAuth } from "../../../../../contexts/auth";
import { TesField } from "../../../../../types/field/fieldType";
import { INameId, LazyLoadingRequest, RequestResponseResult } from "../../../../../types/general/generalTypes";
import CustomStore from "devextreme/data/custom_store";
import { Location } from "../../../../../types/infrastructure/infrastructureTypes";
import CollapsibleCard from "../../../../../components/collapsibleCard/collapsibleCard";
import Permission from "../../../../../components/permission/permision";
import { InfrastructurePermissions } from "../../../../../constants/Permissions";
import { FieldApiUrl, InfrastructureApiUrl } from "../../../../../environment/routeSettings";
import { AreaDTO, VMLocation } from "../../../../../types/infrastructure/dto/locationdDto";
import { LocationType } from "../../../../../types/infrastructure/enums/infrastructureEnums";
import { ScrollView } from "devextreme-react";
import GeneralGisMapManager from "../../../../../components/gisMap/generalGisMapManager";
import { ResponseCode, TesMapModule } from "../../../../../types/general/enums/generalEnums";
import { TableFieldRequestDTO } from "../../../../../types/field/dto/fieldDTO";
import { FieldCategoryType, FieldType } from "../../../../../types/field/enums/fieldEnums";
import { GridActualDateCalculator } from "../../../../../utils/dateTimeTools";
import { useClientSetting } from "../../../../../contexts/clientSetting";
import { useScreenSize } from "../../../../../utils/media-query";
import { OnExporting } from "../../../../../utils/dataGridTools";
import { SortObjectByPropName } from "../../../../../utils/arrayTools";
import ListSelectionLimit from "../../../../../constants/Limits";

const dataSourceIntersection = new CustomStore({
  key: "_id",
  load: async (loadOption) => {
    return await TesPost(
      `${InfrastructureApiUrl()
      }/api/Locations/GetLocations`,
      {
        customerId: localStorage.getItem("selectedCustomerId"),
        divisionId: localStorage.getItem("selectedDivisionId"),
        loadOptions: loadOption,
        filter: {type: LocationType.Intersection}
      } as LazyLoadingRequest,
      true
    );
  },
});

const dataSourceRoadSegment = new CustomStore({
  key: "_id",
  load: async (loadOption) => {
    return await TesPost(
      `${InfrastructureApiUrl()
      }/api/Locations/GetLocations`,
      {
        customerId: localStorage.getItem("selectedCustomerId"),
        divisionId: localStorage.getItem("selectedDivisionId"),
        loadOptions: loadOption,
        filter: {type: LocationType.Midblock}
      } as LazyLoadingRequest,
      true
    );
  },
});

// props
interface IProps {
  initDataSiteList: AreaDTO;
  setInitDataSiteList: React.Dispatch<React.SetStateAction<AreaDTO>>;
  areaId: string;
  updateIntersection: (lstIntersections: Location[] | undefined) => void;
  updateRoadSegment: (lstRoadSegments: Location[] | undefined) => void;
  intersections: Location[];
  roadSegments: Location[];
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  isLocked: boolean;
}

const Locations = (props: IProps) => {
  const dataGridIntersectionsRef = useRef<DataGrid<any, any>>(null);
  const dataGridCurrentIntersectionsRef = useRef<DataGrid<any, any>>(null);
  const dataGridCurrentRoadSegmentsRef = useRef<DataGrid<any, any>>(null);
  const dataGridRoadSegmentRef = useRef<DataGrid<any, any>>(null);
  const [showModalIntersection, setShowModalIntersection] = useState(false);
  const [showModalRoadSegment, setShowModalRoadSegment] = useState(false);
 // const [initDataFieldsValue, setInitDataFieldsValue] = useState<INameId[]>([]);
  const [initDataIntersectionFields, setInitDataIntersectionFields] = useState<TesField[]>([]);
  const [initDataRoadSegmentFields, setInitDataRoadSegmentFields] = useState<TesField[]>([]);
  const [showMap, setShowMap] = useState(false);
  const [showAddMap, setShowAddMap] = useState(false);
  const { generalSetting } = useClientSetting();
  const { activeLoading } = useAuth();
  const { isXLarge, is2xLarge } = useScreenSize();
  // const [filePickerGridHeight, setFilePickerGridHeight] = useState<number> (360)
  const [roadGridHeight, setRoadGridHeight] = useState<number>(400)
  const [roadPopupHeight, setRoadPopupHeight] = useState<string>("80%")
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";


  useEffect(() => {
    if (isXLarge) { setRoadGridHeight(550) }
    else if (is2xLarge) { setRoadGridHeight(820); setRoadPopupHeight("50%") }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        getInitialDataIntersectionFields();
        getInitialDataRoadSegmentFields();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Get initData

  function list2Value(dv: any, d: TesField) {
    const i = d.fieldValues.findIndex((x) => x.id === dv[d.name]);
    if (i !== -1) {
      return d.fieldValues[i].name;
    }
    return "";
  }

  // //functions for intersections in modal
  // async function getInitialDataFieldValue() {
  //   setInitDataFieldsValue(
  //     await TesGet(
  //       FieldApiUrl() +
  //       "/api/CodeValues/GetAllCustomerCodeValue/" +
  //       localStorage.getItem("selectedCustomerId"),
  //       true
  //     )
  //   );
  // }

  async function getInitialDataIntersectionFields() {
    var postOb: TableFieldRequestDTO = {
      customerId: localStorage.getItem('selectedCustomerId')!,
      categoryTypes: [FieldCategoryType.InfrastructureIntersectionGeneral, FieldCategoryType.InfrastructureIntersectionDetails, FieldCategoryType.InfrastructureIntersectionApproachDetails, FieldCategoryType.InfrastructureIntersectionOperation]
    }
    var res = await TesPost(FieldApiUrl() + "/api/TesFields/GetWebTesFieldsByPermissionByCategory", postOb, true) as TesField[];
    res = res.sort(x => x.gridViewIndex);
    setInitDataIntersectionFields(res);
  }

  function onCloseModalIntersection() {
    setShowModalIntersection(false);
  }

  function onAddLocations(locationType: number) {

    var selectedValues;
    if (locationType === LocationType.Intersection) {
      selectedValues = dataGridIntersectionsRef.current?.instance.getSelectedRowsData();
    } else {
      selectedValues = dataGridRoadSegmentRef.current?.instance.getSelectedRowsData();
    }

    if (selectedValues !== undefined) {
      if ((selectedValues?.length ?? 0) + dataGridCurrentIntersectionsRef.current?.instance.getDataSource()?.totalCount()! + dataGridCurrentRoadSegmentsRef.current?.instance.getDataSource()?.totalCount()! > ListSelectionLimit) {
        notify(t("selectingMoreThan1000ItemsPreventsYouFromAddingThisListToTheGrid"), "error", 5000);
        // onCloseModalIntersection();
        // onCloseModalRoadSegment();
        // dataGridIntersectionsRef.current?.instance.deselectAll();
        // dataGridRoadSegmentRef.current?.instance.deselectAll();
      }

      var selectedLocations = selectedValues
        .filter(
          (x: any) => !props.intersections?.map((a) => a.id).includes(x._id)
        )
        .map((x: any) => {
          var l = new VMLocation();
          l.id = x._id;
          l.description = x.Description;
          l.geoId = x.GeoId;
          l.locationType = x.LocationType;
          l.customerId = x.CustomerId;
          return l;
        });
      props.setInitDataSiteList({
        ...props.initDataSiteList, locations: [...props.initDataSiteList.locations,
        ...selectedLocations
        ]
      })
      props.setDataChanged(true)
    }
    onCloseModalIntersection();
    onCloseModalRoadSegment();
    dataGridIntersectionsRef.current?.instance.deselectAll();
    dataGridRoadSegmentRef.current?.instance.deselectAll();


  }

  //functions for roadsegment in the modal
  async function getInitialDataRoadSegmentFields() {
    var postOb: TableFieldRequestDTO = {
      customerId: localStorage.getItem('selectedCustomerId')!,
      categoryTypes: [FieldCategoryType.InfrastructureMidBlockGeneral, FieldCategoryType.InfrastructureMidBlockDetails, FieldCategoryType.InfrastructureMidBlockCrossSection, FieldCategoryType.InfrastructureMidBlockOperation, FieldCategoryType.InfrastructureMidBlockRoot]
    }
    var res = await TesPost(FieldApiUrl() + "/api/TesFields/GetWebTesFieldsByPermissionByCategory", postOb, true) as TesField[];
    res = res.sort(x => x.gridViewIndex);
    setInitDataRoadSegmentFields(res);

  }

  function onCloseModalRoadSegment() {
    setShowModalRoadSegment(false);
  }

  //delete functions
  async function onDeleteIntersection(d: any) {
    let myDialog = custom({
      title: t("deleteData"),
      messageHtml: t("areYouSureWantToDelete?"),
      buttons: [
        {
          text: t("yes"),
          onClick: async (e) => {
            try {
              props.setInitDataSiteList({
                ...props.initDataSiteList,
                locations: props.initDataSiteList.locations.filter((x) => x.id !== d.row.data.id || x.locationType === LocationType.Midblock)
              })

              notify(t("dataSuccessfullyDeleted"), "success", 5000);
            } catch {
              notify(t("someErrorOccurred"), "error", 5000);
            }

            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("no"),
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    myDialog.show();
  }

  async function onDeleteRoadSegment(d: any) {
    let myDialog = custom({
      title: t("deleteData"),
      messageHtml: t("areYouSureWantToDelete?"),
      buttons: [
        {
          text: t("yes"),
          onClick: async (e) => {
            try {
              props.setInitDataSiteList({
                ...props.initDataSiteList,
                locations: props.initDataSiteList.locations.filter((x) => x.id !== d.row.data.id || x.locationType === LocationType.Intersection)
              })



              notify(t("dataSuccessfullyDeleted"), "success", 5000);
            } catch {
              notify(t("someErrorOccurred"), "error", 5000);
            }

            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("no"),
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    myDialog.show();
  }


  async function addLocationFromMap(lstData: VMLocation[]) {
    if (dataGridCurrentIntersectionsRef.current?.instance.getDataSource()?.totalCount()! +
      dataGridCurrentRoadSegmentsRef.current?.instance.getDataSource()?.totalCount()! + lstData.length > 1000) {
      notify(t("selectCorrectNumberOfGrid"), "error", 5000);
      return
    }

    var res = await TesPut(
      `${InfrastructureApiUrl()
      }/api/Locations/GetLocationsByGeoIds/${localStorage.getItem("selectedCustomerId")}`,
      lstData,
      true
    ) as RequestResponseResult <VMLocation[]>;
    if (res.responseCode === ResponseCode.OK) {
      props.setInitDataSiteList({
        ...props.initDataSiteList, locations: [...props.initDataSiteList.locations,
        ...res.results
        ]
      })
    } else {
      await RequestErrorHandling(res);
    }
  }

  const { t } = useTranslation();

  return (
    <Permission
      allowed={[InfrastructurePermissions.Infrastructure_D_Area]}
      hasFeedBackElement={true}
    >
      <div className={`siteListDetails-location ${compactViewModel ? "compactStyle" : ""}`}>
        <React.Fragment>
          <div className={"dx-card responsive-paddings"}>
            <div className="row">
              <div className="rightColumn" style={{ visibility: props.isLocked ? "hidden" : "visible" }}>
                <Button
                  style={{ margin: 5 }}
                  onClick={() => setShowModalIntersection(true)}
                  icon="fa-solid fa-circle-plus"
                  text={t("intersection")}
                />
                <Button
                  style={{ marginRight: 5 }}
                  onClick={() => setShowModalRoadSegment(true)}
                  icon="fa-solid fa-circle-plus"
                  text={t("roadsegment")}
                />
                <Button
                  onClick={() => setShowAddMap(true)}
                  icon="fa-solid fa-circle-plus"
                  text={t("fromMap")}
                />
              </div>
              <div className="leftColumn">
                <Button
                  onClick={() => setShowMap(true)}
                  icon="fa-solid fa-map-location-dot"
                  style={{ marginRight: 10 }}
                  hint={t("showMap")}
                />
              </div>
            </div>
            {/* intesection datagrid */}
            <CollapsibleCard title={t("intersections")}>
              <DataGrid
                id="gridContainer"
                ref={dataGridCurrentIntersectionsRef}
                dataSource={props.initDataSiteList.locations.filter((x: VMLocation) => x.locationType === LocationType.Intersection)}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                onExporting={OnExporting}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <SortByGroupSummaryInfo
                  summaryItem="Total Count"
                  sortOrder="desc"
                />
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                  <Search enabled />
                </ColumnChooser>
                <Column alignment="left" dataField="geoId" caption={t("geoId")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="description" caption={t("description")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>

                <Column type="buttons" caption={t("actions")} width={110} fixed={true} fixedPosition="right">
                  <DevExpressButton
                    hint={t("delete")}
                    onClick={onDeleteIntersection}
                    icon="fa-solid fa-trash-can"
                  ></DevExpressButton>
                </Column>
              </DataGrid>
            </CollapsibleCard>
            {/* Roadsegment datagrid */}
            <CollapsibleCard title={t("roadSegments")}>
              <DataGrid
                id="gridContainer"
                ref={dataGridCurrentRoadSegmentsRef}
                dataSource={props.initDataSiteList.locations.filter((x: VMLocation) => x.locationType === LocationType.Midblock)}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                onExporting={OnExporting}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <SortByGroupSummaryInfo
                  summaryItem="Total Count"
                  sortOrder="desc"
                />
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                  <Search enabled />
                </ColumnChooser>
                <Column alignment="left" dataField="geoId" caption={t("geoId")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="description" caption={t("description")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>

                <Column type="buttons" caption={t("actions")} width={110} fixed={true} fixedPosition="right">
                  <DevExpressButton
                    hint={t("delete")}
                    onClick={onDeleteRoadSegment}
                    icon="fa-solid fa-trash-can"
                  ></DevExpressButton>
                </Column>
              </DataGrid>
            </CollapsibleCard>
          </div>
          {/* intersection modal */}
          <Popup
            width={"65%"}
            height={"auto"}
            visible={showModalIntersection}
            resizeEnabled={true}
            showTitle={true}
            title={t("add")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => onCloseModalIntersection()}
          >
            <div style={{ height: "90%" }}>
              <DataGrid
                id="gridContainer"
                ref={dataGridIntersectionsRef}
                dataSource={dataSourceIntersection}
                rowAlternationEnabled={true}
                showBorders={true}
                style={{ height: "100%" }}
                keyExpr="id"
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                onExporting={OnExporting}
              //onContentReady={e => {e.component.deselectAll()}}
              //onFilterValueChange={e => {dataGridIntersectionsRef?.current?.instance.deselectAll()}}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <Selection
                  mode="multiple"
                  selectAllMode={"allPages"}
                  showCheckBoxesMode={"always"}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                  <Search enabled />
                </ColumnChooser>
                <SortByGroupSummaryInfo
                  summaryItem="Total Count"
                  sortOrder="desc"
                />
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Column
                  alignment="left"
                  dataField="GeoId"
                  allowSorting={false}
                  caption={t("geoId")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="Description"
                  allowSorting={false}
                  caption={t("description")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>


                {initDataIntersectionFields?.map((d) => {
                  if (d.fieldType === FieldType.List) {
                    return (
                      <Column
                        dataField={d.name}
                        allowSorting={false}
                        caption={d.labelText}
                        allowFiltering={true}
                      >
                        <Search enabled />
                        <Lookup
                          dataSource={SortObjectByPropName(d.fieldValues, "name")}
                          valueExpr="id"
                          displayExpr="name"
                        />
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.Number) {
                    return (
                      <Column
                        dataField={d.name}
                        allowSorting={false}
                        caption={d.labelText}
                        dataType="number"
                        alignment="left"
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.Date) {
                    return (
                      <Column
                        dataField={d.name}
                        calculateCellValue={(e: any) => GridActualDateCalculator(e, d, generalSetting)}
                        caption={d.labelText}
                        format={generalSetting?.dateFormat}
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.Time) {
                    return (
                      <Column
                        calculateCellValue={(e: any) => GridActualDateCalculator(e, d, generalSetting)}
                        dataField={d.name}
                        caption={d.labelText}
                        format={generalSetting?.timeFormat}
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.DateTime) {
                    return (
                      <Column
                        dataField={d.name}
                        calculateCellValue={(e: any) => GridActualDateCalculator(e, d, generalSetting)}
                        caption={d.labelText}
                        format={generalSetting?.dateTimeFormat}
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else {
                    return (
                      <Column
                        dataField={d.name}
                        allowSorting={false}
                        caption={d.labelText}
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  }
                })}
              </DataGrid>
            </div>

            <div style={{ marginTop: 20 }}>
              <div className="rightColumn">
                <Button
                  className="tes-modal-btn-cancel"
                  style={{ marginRight: 20 }}
                  onClick={() => onCloseModalIntersection()}
                  text={t("cancel")}
                />
                <Button
                  className="tes-modal-btn-add"
                  onClick={() => onAddLocations(LocationType.Intersection)}
                  text={t("add")}
                />
              </div>
            </div>
          </Popup>
          {/* RoadSegment Modal */}
          <Popup
            width={"65%"}
            height={"auto"}
            visible={showModalRoadSegment}
            resizeEnabled={true}
            showTitle={true}
            title={t("add")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => onCloseModalRoadSegment()}
          >
            <div style={{ height: "90%" }}>
              <DataGrid
                id="gridContainer"
                ref={dataGridRoadSegmentRef}
                dataSource={dataSourceRoadSegment}
                rowAlternationEnabled={true}
                showBorders={true}
                style={{ height: "100%" }}
                keyExpr="id"
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                height={roadGridHeight}
                onExporting={OnExporting}
              //onContentReady={e => {e.component.deselectAll()}}
              //onFilterValueChange={e => {dataGridRoadSegmentRef?.current?.instance.deselectAll()}}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <Selection
                  mode="multiple"
                  selectAllMode={"allPages"}
                  showCheckBoxesMode={"always"}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                  <Search enabled />
                </ColumnChooser>
                <SortByGroupSummaryInfo
                  summaryItem="Total Count"
                  sortOrder="desc"
                />
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Column
                  alignment="left"
                  dataField="GeoId"
                  allowSorting={false}
                  caption={t("geoId")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="Description"
                  allowSorting={false}
                  caption={t("description")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>



                {initDataRoadSegmentFields?.map((d) => {
                  if (d.fieldType === FieldType.List) {
                    return (
                      <Column
                        dataField={d.name}
                        allowSorting={false}
                        caption={d.labelText}
                        allowFiltering={true}
                      >
                        <Search enabled />
                        <Lookup
                          dataSource={SortObjectByPropName(d.fieldValues, "name")}
                          valueExpr="id"
                          displayExpr="name"
                        />
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.Number) {
                    return (
                      <Column
                        dataField={d.name}
                        allowSorting={false}
                        caption={d.labelText}
                        dataType="number"
                        alignment="left"
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.Date) {
                    return (
                      <Column
                        dataField={d.name}
                        calculateCellValue={(e: any) => GridActualDateCalculator(e, d, generalSetting)}
                        caption={d.labelText}
                        format={generalSetting?.dateFormat}
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.Time) {
                    return (
                      <Column
                        calculateCellValue={(e: any) => GridActualDateCalculator(e, d, generalSetting)}
                        dataField={d.name}
                        caption={d.labelText}
                        format={generalSetting?.timeFormat}
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else if (d.fieldType === FieldType.DateTime) {
                    return (
                      <Column
                        dataField={d.name}
                        calculateCellValue={(e: any) => GridActualDateCalculator(e, d, generalSetting)}
                        caption={d.labelText}
                        format={generalSetting?.dateTimeFormat}
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  } else {
                    return (
                      <Column
                        dataField={d.name}
                        allowSorting={false}
                        caption={d.labelText}
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    );
                  }
                })}
              </DataGrid>
            </div>

            <div style={{ marginTop: 20 }}>
              <div className="rightColumn">
                <Button
                  className="tes-modal-btn-cancel"
                  style={{ marginRight: 20 }}
                  onClick={() => onCloseModalRoadSegment()}
                  text={t("cancel")}
                />
                <Button
                  className="tes-modal-btn-add"
                  onClick={() => onAddLocations(LocationType.Midblock)}
                  text={t("add")}
                />
              </div>
            </div>
          </Popup>


          {/*Add From Map Popup */}
          <Popup
            width={"50%"}
            height={"auto"}
            // maxHeight={"90%"}
            visible={showAddMap}
            resizeEnabled={true}
            showTitle={true}
            title={t("map")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => setShowAddMap(false)}
          >
            <GeneralGisMapManager
              tesModule={TesMapModule.AreaDetails}
              lstIntersectionGeoIdIn={(props.initDataSiteList?.locations?.filter(x => x.locationType === LocationType.Intersection).map(x => x.geoId!))!}
              setLstRoadSegmentGeoIdIn={(props.initDataSiteList?.locations?.filter(x => x.locationType === LocationType.Midblock).map(x => x.geoId!))!}
              showGis={showAddMap}
              setShowMap={setShowAddMap}
              locId="LocationId"
              addLocationFromMap={addLocationFromMap}
            />
          </Popup>


          {/*on Map Popup */}
          <Popup
            width={"50%"}
            height={"auto"}
            // maxHeight={"90%"}
            visible={showMap}
            resizeEnabled={true}
            showTitle={true}
            title={t("map")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => setShowMap(false)}
          >
            <GeneralGisMapManager
              tesModule={TesMapModule.AreaDetailsView}
              lstIntersectionGeoIdIn={(props.initDataSiteList?.locations?.filter(x => x.locationType === LocationType.Intersection).map(x => x.geoId!))!}
              setLstRoadSegmentGeoIdIn={(props.initDataSiteList?.locations?.filter(x => x.locationType === LocationType.Midblock).map(x => x.geoId!))!}
              showGis={showMap}
              setShowMap={setShowMap}
              locId="LocationId"
              addLocationFromMap={addLocationFromMap}
            />
          </Popup>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default Locations;
