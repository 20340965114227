import { DateBox, Form, NumberBox, SelectBox, TagBox, TextBox } from "devextreme-react";
import { Button } from "devextreme-react/button";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { MapSetting } from "../../../../../types/infrastructure/infrastructureTypes";
import { RequestErrorHandling, TesGet, TesPost } from "../../../../../utils/rest";
import notify from "devextreme/ui/notify";
import { useAuth } from "../../../../../contexts/auth";
import { AuthApiUrl, InfrastructureApiUrl } from "../../../../../environment/routeSettings";
import { SimpleItem } from "devextreme-react/form";
import { NameValue, RequestResponseResult } from "../../../../../types/general/generalTypes";
import { Enum2Array } from "../../../../../utils/enumTools";
import { FieldType } from "../../../../../types/field/enums/fieldEnums";
import { SortObjectByPropName } from "../../../../../utils/arrayTools";
import { useClientSetting } from "../../../../../contexts/clientSetting";
import { VMFindUser } from "../../../../../types/identity/dto/identityDTO";
import { ResponseCode } from "../../../../../types/general/enums/generalEnums";

interface IProps {
  clientId: string | undefined
}
const Infrastructure = (props: IProps) => {
  const { activeLoading } = useAuth();
  const [initData, setInitData] = useState<MapSetting>(new MapSetting());
  const [lstFieldType, setLstFieldType] = useState<NameValue[]>([]);
  const [initDataUsers, setInitDataUser] = useState<VMFindUser[]>([]);
  const { generalSetting } = useClientSetting();

  const onChangeDetailsFields = (key: string, value: any) => {
    setInitData({ ...initData, [key]: value });
  };

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        setLstFieldType(Enum2Array(FieldType));
        getInitData();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);




  async function getInitDataUsers() {
    try {
      var res = (await TesGet(AuthApiUrl() + "/api/user/findUser", true)) as VMFindUser[];
      setInitDataUser(res);
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  async function onSave() {
    const postObj = {
      ...initData,
      customerId: props.clientId
    }
    const res = await TesPost(
      InfrastructureApiUrl() + "/api/settings/updateSetting",
      postObj,
      true
    ) as RequestResponseResult<any>;
    if (res.responseCode === ResponseCode.OK) {
      try {
        if (activeLoading) activeLoading(true);
        await getInitData();
        if (activeLoading) activeLoading(false);
        notify(t("dataSuccessfullyAdded"), "success", 5000);
      } catch {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred"), "error", 5000);
      }
    } else {
      await RequestErrorHandling(res);
    }
  }
  
  async function getInitData() {
    const res = await TesGet(
      InfrastructureApiUrl() +
      "/api/Settings/GetInfrastructureSettings/" +
      props.clientId,
      true
    ) as RequestResponseResult<MapSetting>
    if (res.responseCode === ResponseCode.OK) {
      setInitData(res.results);
    }  else {
      await RequestErrorHandling(res);
    }
  }

  return (
    <React.Fragment>
      <div className="row" style={{ marginTop: 20, padding: 10 }}>
        <Form colCount={2} className='popupFields'>
          <SimpleItem colSpan={1}>
            <TextBox
              label={t("intersectionFileAddress")}
              labelMode="static"
              value={initData?.intersectionFileAddress}
              onValueChange={(e) =>
                onChangeDetailsFields("intersectionFileAddress", e)
              }
              style={{ marginBottom: "1rem" }}
            />
          </SimpleItem>
          <SimpleItem colSpan={1}>
            <TextBox
              label={t("roadSegmentFileAddress")}
              labelMode="static"
              value={initData?.midblockFileAddress}
              onValueChange={(e) =>
                onChangeDetailsFields("midblockFileAddress", e)
              }
            />
          </SimpleItem>
          </Form>
          <Form colCount={4}>
          <SimpleItem colSpan={1}>
            <TextBox
              label={t("intersectionGeoIdName")}
              labelMode="static"
              value={initData?.intersectionGeoIdName}
              onValueChange={(e) =>
                onChangeDetailsFields("intersectionGeoIdName", e)
              }
              style={{ marginBottom: "1rem" }}
            />
          </SimpleItem>
          <SimpleItem colSpan={1}>
            <SelectBox
              placeholder=""
              label={t('intersectionGeoIdType')}
              valueExpr="value"
              displayExpr="name"
              labelMode='static'
              value={initData?.intersectionGeoIdType}
              onValueChange={(e) => onChangeDetailsFields("intersectionGeoIdType", e)}
              className="modalInput"
              items={SortObjectByPropName(lstFieldType, "name")}
              showClearButton={true}
              searchEnabled={true}
            />
          </SimpleItem>
        
          <SimpleItem colSpan={1}>
            <TextBox
              label={t("roadSegmentGeoIdName")}
              labelMode="static"
              value={initData?.midblockGeoIdName}
              onValueChange={(e) =>
                onChangeDetailsFields("midblockGeoIdName", e)
              }
            />
          </SimpleItem>
          <SimpleItem colSpan={1}>
            <SelectBox
              placeholder=""
              label={t('midblockGeoIdType')}
              valueExpr="value"
              displayExpr="name"
              labelMode='static'
              value={initData?.midblockGeoIdType}
              onValueChange={(e) => onChangeDetailsFields("midblockGeoIdType", e)}
              className="modalInput"
              items={SortObjectByPropName(lstFieldType, "name")}
              showClearButton={true}
              searchEnabled={true}
            />
          </SimpleItem>
          <SimpleItem colSpan={1}>
            <NumberBox
              step={0}
              label={t("latitude")}
              labelMode="static"
              value={initData?.latitude}
              showSpinButtons={true}
              showClearButton={true}
              onValueChange={(e) => onChangeDetailsFields("latitude", e)}
            />
          </SimpleItem>
          <SimpleItem colSpan={1}>
            <NumberBox
              step={0}
              label={t("longitude")}
              labelMode="static"
              value={initData?.longitude}
              showSpinButtons={true}
              showClearButton={true}
              onValueChange={(e) => onChangeDetailsFields("longitude", e)}
            />
          </SimpleItem>
          <SimpleItem colSpan={1}>
            <TextBox
              label={t("arcGisApiKey")}
              labelMode="static"
              value={initData?.arcGisApiKey}
              onValueChange={(e) =>
                onChangeDetailsFields("arcGisApiKey", e)
              }
            />
          </SimpleItem>
          <SimpleItem colSpan={1}>
            <NumberBox
              label={t("wkidNumber")}
              labelMode="static"
              value={initData?.wkidNumber}
              onValueChange={(e) => onChangeDetailsFields("wkidNumber", e)
              }
            />
          </SimpleItem>

          <SimpleItem colSpan={1}>
            <DateBox
              type="date"
              label={t("expiryDate")}
              value={initData?.apiKeyExpiryDT}
              onValueChange={(e) =>
                onChangeDetailsFields("apiKeyExpiryDT", e)
              } pickerType="calendar"
              placeholder={generalSetting?.dateTimeFormat}
              displayFormat={generalSetting?.dateTimeFormat}
              useMaskBehavior={true}
              openOnFieldClick={true}
              showClearButton={true}
            >
            </DateBox>
          </SimpleItem>
          <SimpleItem colSpan={1}>
            <TagBox
              dataSource={SortObjectByPropName(initDataUsers, "userName")}
              valueExpr="email"
              value={initData?.expiryEmailNotification}
              labelMode="static"
              label={t('emails')}
              displayExpr="userName"
              onValueChange={(e) =>
                onChangeDetailsFields("expiryEmailNotification", e)
              } searchEnabled={true} />
          </SimpleItem>
        </Form>

        <div className="rightColumn">
          <Button
            className="tes-modal-btn-add saveButton"
            onClick={onSave}
            text={t("save")}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
export default Infrastructure;
