import React, { useState, useRef, useEffect } from "react";
import { RequestErrorHandling, TesGet, TesPost, TesPut } from '../../utils/rest';
import { TesField } from '../../types/field/fieldType';
import CustomStore from 'devextreme/data/custom_store';
import { INameId, LazyLoadingRequest, RequestResponseResult } from "../../types/general/generalTypes";
import TextBox from 'devextreme-react/text-box';
import { Popup } from 'devextreme-react/popup';
import DataGrid, {
    Column, FilterRow, HeaderFilter, SearchPanel, ColumnChooser, Pager, Paging, Lookup, Export, Search,
} from 'devextreme-react/data-grid';
import { t } from "i18next";
import { useAuth } from "../../contexts/auth";
import notify from "devextreme/ui/notify";
import { FieldApiUrl, InfrastructureApiUrl } from "../../environment/routeSettings";
import { TableFieldRequestDTO } from "../../types/field/dto/fieldDTO";
import { FieldCategoryType, FieldLocation2Show, FieldType } from "../../types/field/enums/fieldEnums";
import { useClientSetting } from "../../contexts/clientSetting";
import { VMLocation } from "../../types/infrastructure/dto/locationdDto";
import { useNavigate } from "react-router-dom";
import { GridActualDateCalculator } from "../../utils/dateTimeTools";
import { LocationType } from "../../types/infrastructure/enums/infrastructureEnums";
import { EnumFlag2Array } from "../../utils/enumTools";
import { OnExporting } from "../../utils/dataGridTools";
import { SortObjectByPropName } from "../../utils/arrayTools";
import { ResponseCode } from "../../types/general/enums/generalEnums";

interface IProps {
    label: string;
    value: string;
    name: string;
    onChangeFields: (name: string, value: any, isUpperCase: boolean) => void;
    isLocked: boolean;
}

const dataSource = new CustomStore({
    load: async (loadOption) => {
        return (await TesPost(`${InfrastructureApiUrl()}/api/Locations/GetLocations`,
            {
                customerId: localStorage.getItem("selectedCustomerId"),
                divisionId: localStorage.getItem("defaultDivisionId"),
                loadOptions: loadOption,
                filter: { type: LocationType.Intersection }
            } as LazyLoadingRequest,
            true));
    }
});

const IntersectionValuePicker = (props: IProps) => {
    const [initDataFields, setInitDataFields] = useState<TesField[]>();
    const [initDataFieldsValue, setInitDataFieldsValue] = useState<INameId[]>();
    const [name, setName] = useState('');
    const [showIntersectionPicker, setIntersectionPicker] = useState(false);
    const dataGridRef = useRef<DataGrid<any, any>>(null);

    const [currentLocation, setCurrentLocation] = useState<VMLocation>();
    const { activeLoading } = useAuth();
    const history = useNavigate();
    const { generalSetting } = useClientSetting();

    useEffect(() => {
        async function fetchMyAPI() {
            try {
                if (activeLoading) activeLoading(true);
                await getStreetName(props.value);
                if (activeLoading) activeLoading(false);
            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        }
        fetchMyAPI()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);


    async function getInitialDataFields() {
        var postOb: TableFieldRequestDTO = {
            customerId: localStorage.getItem('selectedCustomerId')!,
            categoryTypes: [FieldCategoryType.InfrastructureIntersectionGeneral, FieldCategoryType.InfrastructureIntersectionDetails, FieldCategoryType.InfrastructureIntersectionApproachDetails, FieldCategoryType.InfrastructureIntersectionOperation]
        }
        var res = await TesPost(FieldApiUrl() + "/api/TesFields/GetWebTesFieldsByPermissionByCategory", postOb, true) as TesField[];
        res = res.sort(x => x.gridViewIndex);
        setInitDataFields(res);
    }

    async function getStreetName(id: string) {
        if (id === undefined || id === null || id === "00000000-0000-0000-0000-000000000000") return;
        const res = await TesPost(InfrastructureApiUrl() + "/api/Locations/GetLocationById",{id: id}, true) as RequestResponseResult<VMLocation>;
        if (res.responseCode === ResponseCode.OK) {
            if (res !== null) {
                if (res.results.locationType === LocationType.Intersection) {
                    setName(`(Intersection - ${res.results.geoId}) ${res.results.description}`)
                } else if (res.results.locationType === LocationType.Midblock) {
                    setName(`(Road Segment - ${res.results.geoId}) ${res.results.description}`)
                }
            }
            setCurrentLocation(res);
        } else {
            await RequestErrorHandling(res);
        }
    }






    // async function getInitialDataFieldValue() {
    //     setInitDataFieldsValue(await TesGet(FieldApiUrl() + "/api/CodeValues/GetAllCustomerCodeValue/" + localStorage.getItem('selectedCustomerId'), true));
    // }



    function onRowClick(selectedRowData: any) {
        setIntersectionPicker(false);
        if (selectedRowData.data.LocationType === LocationType.Intersection) {
            setName(`(Intersection - ${selectedRowData.data?.GeoId}) ${selectedRowData.data?.Description}`)
        }
        props.onChangeFields(props.name, selectedRowData.data?._id, false)
        setCurrentLocation({ id: selectedRowData.data?._id });
    }

    async function onOpenPopUp() {
        if (!props.isLocked) {
            setIntersectionPicker(true);
            await getInitialDataFields();
            // await getInitialDataFieldValue();
        }

    }

    function onLocationDetails() {
        if (currentLocation !== null && currentLocation?.id !== undefined) {
            history('/infrastructure/intersectionDetails/' + currentLocation?.id);
        }
    }

    return (
        <React.Fragment>
            <label onClick={() => onOpenPopUp()}>
                <TextBox
                    // style={{ marginBottom: 20 }}
                    label={props.label}
                    labelMode='static'
                    value={name}
                    disabled={props.isLocked}
                    showClearButton={true}
                    buttons={[
                        {
                            name: 'search',
                            location: 'after',
                            options: {
                                icon: 'fas fa-location-dot',
                                stylingMode: 'text',
                                onClick: (x => { onLocationDetails() })
                            }
                        }
                    ]}
                />
            </label>
            <Popup
                width={"60%"}
                visible={showIntersectionPicker}
                resizeEnabled={true}
                showTitle={true}
                title={t('intersectionPicker')}
                hideOnOutsideClick={false}
                showCloseButton={true}
                onHiding={() => setIntersectionPicker(false)}
            >
                <div style={{ height: "100%" }}>
                    {showIntersectionPicker === true &&
                        <DataGrid
                            ref={dataGridRef}
                            dataSource={dataSource}
                            style={{ height: '100%' }}
                            rowAlternationEnabled={true}
                            showBorders={true}
                            selection={{ mode: 'single' }}
                            onRowClick={onRowClick}
                            hoverStateEnabled={true}
                            remoteOperations={true}
                            allowColumnReordering={true}
                            allowColumnResizing={true}
                            columnAutoWidth={true}
                            onExporting={OnExporting}
                        >
                            <Export enabled={true} allowExportSelectedData={true} />
                            <Paging enabled={true} defaultPageSize={100} />
                            <Pager
                                showPageSizeSelector={true}
                                allowedPageSizes={[100, 200, 300, 400, 500]}
                                showNavigationButtons={true}
                                showInfo={true}
                                visible={true}
                            />
                            <FilterRow visible={true}
                                applyFilter="auto" />

                            <HeaderFilter visible={true} />

                            <SearchPanel visible={true}
                                width={285}
                                placeholder={t("search...")} />

                            <ColumnChooser width={350} height={400}
                                enabled={true}
                                mode="select"
                                sortOrder="asc"
                            >
                                <Search enabled />
                            </ColumnChooser>

                            <Column alignment="left" dataField="GeoId"
                                caption={t("geoId")}>
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column dataField="Description"
                                caption={t("description")}>
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>





                            {initDataFields && initDataFields?.map((d) => {
                                if (d.fieldType === FieldType.List) {
                                    return (
                                        <Column
                                            dataField={d.name}
                                            caption={d.labelText}
                                            allowFiltering={true}
                                            visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                                        >
                                            <Search enabled />
                                            <Lookup
                                                dataSource={SortObjectByPropName(d.fieldValues, "name")}
                                                valueExpr="id"
                                                displayExpr="name"
                                            />
                                            <HeaderFilter>
                                                <Search enabled />
                                            </HeaderFilter>
                                        </Column>
                                    );
                                } else if (d.fieldType === FieldType.Number) {
                                    return (
                                        <Column
                                            dataField={d.name}
                                            caption={d.labelText}
                                            dataType="number"
                                            alignment="left"
                                            visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                                        >
                                            <HeaderFilter>
                                                <Search enabled />
                                            </HeaderFilter>
                                        </Column>
                                    );
                                } else if (d.fieldType === FieldType.Date) {
                                    return (
                                        <Column
                                            dataField={d.name}
                                            caption={d.labelText}
                                            dataType="date"
                                            calculateCellValue={(e: any) => GridActualDateCalculator(e, d, generalSetting)}
                                            format={generalSetting?.dateFormat}
                                            visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                                        >
                                            <HeaderFilter>
                                                <Search enabled />
                                            </HeaderFilter>
                                        </Column>
                                    );
                                } else if (d.fieldType === FieldType.Time) {
                                    return (
                                        <Column
                                            dataField={d.name}
                                            caption={d.labelText}
                                            dataType="datetime"
                                            calculateCellValue={(e: any) => GridActualDateCalculator(e, d, generalSetting)}
                                            format={generalSetting?.timeFormat}
                                            visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                                        >
                                            <HeaderFilter>
                                                <Search enabled />
                                            </HeaderFilter>
                                        </Column>
                                    );
                                } else if (d.fieldType === FieldType.DateTime) {
                                    return (
                                        <Column
                                            dataField={d.name}
                                            caption={d.labelText}
                                            dataType="datetime"
                                            calculateCellValue={(e: any) => GridActualDateCalculator(e, d, generalSetting)}
                                            format={generalSetting?.dateTimeFormat}
                                            visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                                        >
                                            <HeaderFilter>
                                                <Search enabled />
                                            </HeaderFilter>
                                        </Column>
                                    );
                                } else if (d.fieldType === FieldType.Boolean) {
                                    return (
                                        <Column
                                            dataField={d.name}
                                            caption={d.labelText}
                                            dataType="boolean"
                                            visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                                        >
                                            <HeaderFilter>
                                                <Search enabled />
                                            </HeaderFilter>
                                        </Column>
                                    );
                                } else {
                                    return (
                                        <Column
                                            dataField={d.name}
                                            caption={d.labelText}
                                            allowHeaderFiltering={false}
                                            visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                                        >
                                            <Search enabled />
                                            <HeaderFilter>
                                                <Search enabled />
                                            </HeaderFilter>

                                        </Column>
                                    );
                                }
                            })}
                        </DataGrid>
                    }
                </div>

            </Popup>


        </React.Fragment>
    );
}
export default IntersectionValuePicker;